// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lc {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  color: var(--ion-text-color);
  padding-left: 10px;
  padding-right: 10px;
  transition: 0.9s cubic-bezier(0.39, 0.575, 0.565, 1);
  width: 100%;
  z-index: 101;
  /* margin-bottom: 15px; */
  border: 2px solid;
  border-radius: 10px;
  font-size: 12px;
  min-width: 150px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/location/location-changer/location-changer.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,4BAAA;EACA,kBAAA;EACA,mBAAA;EACA,oDAAA;EAEA,WAAA;EACA,YAAA;EACA,yBAAA;EACA,iBAAA;EACA,mBAAA;EACA,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".lc {\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n    font-weight: bold;\n    color: var(--ion-text-color);\n    padding-left: 10px;\n    padding-right: 10px;\n    transition: 0.9s cubic-bezier(0.39, 0.575, 0.565, 1);\n    //position: -webkit-sticky;\n    width: 100%;\n    z-index: 101;\n    /* margin-bottom: 15px; */\n    border: 2px solid;\n    border-radius: 10px;\n    font-size: 12px;\n    min-width: 150px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
