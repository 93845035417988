import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';

@Directive({
  selector: '[elementVisible]'
})
export class ElementVisibleDirective {

  @Input() tag: string;

  @Output() public elementVisible: EventEmitter<any> = new EventEmitter();

  private _intersectionObserver?: IntersectionObserver;

  constructor(
    private _element: ElementRef
  ) { }


  public ngAfterViewInit() {
    let root = document.getElementById("menucontent")
    this._intersectionObserver = new IntersectionObserver(entries => {
    
      this.checkForIntersection(entries);
    }, { root: root, rootMargin: '-100px' });

    this._intersectionObserver.observe(<Element>(this._element.nativeElement));
  }

  private checkForIntersection = (entries: Array<IntersectionObserverEntry>) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      //if (this.checkIfIntersecting(entry)) {
        let el = this._element.nativeElement
        //console.log(entries,  this.tag)
        if (entry.isIntersecting) {
          this.elementVisible.emit(true);
        }
      //}
    });
  }

  private checkIfIntersecting(entry: IntersectionObserverEntry) {
    return (<any>entry).isIntersecting && entry.target === this._element.nativeElement;
  }

}
