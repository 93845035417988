import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnInit {


  @Output() rate = new EventEmitter<any>();

  items = []

  constructor() {
    this.items.push({ id: 1, value: 0 })
    this.items.push({ id: 2, value: 0 })
    this.items.push({ id: 3, value: 0 })
    this.items.push({ id: 4, value: 0 })
    this.items.push({ id: 5, value: 0 })
  }

  ngOnInit() { }

  select(r) {
    this.items.map(o => o.value = 0)
    this.items.filter(i => i.id <= r.id).map(v => v.value = 1)


    setTimeout(() => {

      let els = document.getElementsByClassName("selected-rating")

    }, 100);


    let ratings = { ratings: [
      { id: "service", value: 2 },
      { id: "hygiene", value: 2 },
      { id: "flavor", value: 2 }],
      feedback: 'we are like it :)'
    }



    this.rate.emit(this.items.filter(i => i.id <= r.id).length)
  }

}
