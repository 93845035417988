import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { Folio } from 'src/app/services/dto/orwi-folio';
import { FbService } from 'src/app/services/events/fb.service';
import { FolioService } from 'src/app/services/folio/folio.service';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';
import { UIQuery } from 'src/app/state/ui/ui.query';
import { UIStore } from 'src/app/state/ui/ui.store';

@Component({
  selector: 'orwi-attach-folio',
  templateUrl: './attach-folio.component.html',
  styleUrls: ['./attach-folio.component.scss'],
})
export class AttachFolioComponent implements OnInit {

  @Input() height = "auto"

  showFolio = true
  showBecomeMember = false

  constructor(
    private router: Router,
    private fb: FbService, private nav: NavController,
    private glb: GlobalService, public uiQuery: UIQuery,
    private transloco: TranslocoService, private uiStore: UIStore,
    private fs: FolioService, private ss: StoreService, private us: UserService) { }


  ngOnInit() {


  }


  getPoint() {
    console.log("this.ss.store.orwiPayPoint", this.ss.store.orwiPayPoint, this.fs.getFolioTotal("all"))
    let fl = this.uiStore.getValue().attachFolio
    return Math.round(this.fs.getFolioTotal("all", true, true, true, fl) * this.ss.store.orwiPayPoint)
  }




  yes() {
    this.fs.receiveFolioFromPOS(this.ss.store.id, this.ss.tableName).then(o => {
      let user = this.us.user.isVirtual ? "virtual user" : "registered user"
      this.us.savelog(`🟢 Folio assigned by ${user}, table: ${this.ss.tableName} store:  ${this.ss.store.name}`)

      this.fb.assignFolio(this.ss.store.name)


      if (!o) {
        this.glb.toast(this.transloco.translate("this check is no longer available."), "", "bottom", "danger")
      }

      if (this.us.user.isVirtual) {
        //this.showFolio = false
        //this.showBecomeMember = true
        this.becomeMember()


      } else {

        this.closeAntimate().then(o => {
          this.uiStore.update({ attachFolio: null })
        })
      }
    })
  }

  no() {
    let user = this.us.user.isVirtual ? "virtual user" : "registered user"
    this.us.savelog(`🔴 Folio rejected by ${user}, table: ${this.ss.tableName} store:  ${this.ss.store.name}`)

    this.fb.rejectFolio(this.ss.store.name)
    this.closeAntimate().then(o => {
      this.uiStore.update({ attachFolio: null, rejectedAttachFolioId: this.ss.tableName })
    })
  }


  closeAntimate() {
    return new Promise((resolve, reject) => {
      let comp = document.getElementById('attach-folio')
      if (!comp) return
      comp.classList.add("animate__bounceOutDown")
      setTimeout(() => {
        comp.classList.remove("animate__bounceOutDown")
        resolve(true)
      }, 500);
    })
  }


  closeButtonClick() {
    //this.uiStore.update({ orwiContactClose: true })
  }

  show() {
    this.us.savelog("Show Attach Folio, table:" + this.ss.tableName + " store:" + this.ss.store.name + " point:" + this.getPoint())
    setTimeout(() => {

      let comp = document.getElementById('attach-folio')


      if (comp) {
        comp.style.opacity = "1"
        comp.classList.add("animate__bounceInUp")

        setTimeout(() => {
          comp.classList.remove("animate__bounceInUp")
        }, 1000);
      }




    }, 100);



  }


  becomeMember() {

    this.us.savelog("Redirect Login / Register " + this.ss.store.name)

    let url = this.router.url
    this.closeAntimate().then(o => {
      this.uiStore.update({ attachFolio: null })

      this.us.checkActiveUserWO(
        (allreadyLoggedIn) => {
          this.nav.navigateForward(url, {
            replaceUrl: !allreadyLoggedIn
          })
        })
    })


  }



  close() {
    this.us.savelog("🔴 Reject Login/Register Offer")
    this.closeAntimate().then(o => {
      this.uiStore.update({ attachFolio: null })
    })
  }

}


