import { Directive, Input, ElementRef, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[high-light]'
})
export class HighligthDirective {

  @Input() searchedWords: string;
  @Input() text: string;
  @Input() classToApply: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.searchedWords || !this.searchedWords.length || !this.classToApply) {
      this.renderer.setProperty(this.el.nativeElement, 'innerHTML', this.text);
      return;
    }

    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      this.getFormattedText()
    );
  }

  getFormattedText() {
    if (this.text == null) {
      return this.text
    }
    var searchMask = this.searchedWords;
    var regEx = new RegExp(searchMask, "ig");
    let n = this.text.search(new RegExp(searchMask, "ig"))
    let search = this.text.substr(n,searchMask.length)


    var replaceMask = `<span style='color:red'>${search}</span>`
    let ret = ""
    if (this.text) {
      ret = this.text.replace(regEx,replaceMask)
    }
    return ret
  }

}
