import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';
import * as moment from 'moment';
import { Store } from 'src/app/services/dto/orwi-store';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'orwi-store-title',
  templateUrl: './store-title.component.html',
  styleUrls: ['./store-title.component.scss'],
})
export class StoreTitleComponent implements OnInit {

  @Input() store: Store

  @Output()
  public domChange = new EventEmitter();
  private changes: MutationObserver;

  constructor(public ss: StoreService, public us: UserService, private ig: IdGeneratorService) { }

  ngOnInit() {



  }

  ionViewDidEnter() {

  }

  safeArea
  safeAreaStr
  ngAfterViewInit() {

    var style = getComputedStyle(document.body);
    this.safeAreaStr = style.getPropertyValue('--ion-safe-area-top')
    //this.safeAreaStr = "44px"
    this.safeArea = parseInt(this.safeAreaStr.replace("px", ""))




    const stickyElm = document.querySelector('#lps')
    //const back = document.getElementById('back-div')
    //const backCover = document.getElementById('back-div-cover')

    //backCover.style.paddingTop = this.safeAreaStr
    //document.getElementById('lps').style.paddingTop = this.safeAreaStr

    if (this.safeArea > 0) {
      //backCover.style.height = (this.safeArea) + "px"
      //document.getElementById('lps').style.height = (this.safeArea + 50) + "px"
    }
    //backCover.style.paddingTop = this.safeAreaStr
    //document.getElementById('lps').style.paddingTop =  this.safeAreaStr



    const observer = new IntersectionObserver(this.callback, {
      rootMargin: '-50px 0px 0px 0px',
      threshold: [0.5],
    });


    //observer.observe(stickyElm)

  }

  callback(e) {
    if (e.length > 1) return
    let stickyElm = document.getElementById('lps')
    let back = document.getElementById('back-div')
    let title = document.getElementById('store-title-title')
    let bonus = document.getElementById('store-title-bonus')
    let bonusDesc = document.getElementById('store-title-bonus-desc')
    let desc = document.getElementById('store-title-desc')

    const backCover = document.getElementById('back-div-cover')

    if (!stickyElm) return
    if (e[0].isIntersecting == false) {

      stickyElm.style.marginLeft = "0px"
      stickyElm.style.paddingLeft = "50px"

      back.style.borderRadius = "0px"
      backCover.style.backgroundColor = "var(--ion-background-color)"
      back.style.backgroundColor = "var(--ion-background-color)"
      stickyElm.style.height = (50 + this.safeArea) + "px"
      title.style.fontSize = "16px"
      bonus.style.fontSize = "16px"
      bonusDesc.style.fontSize = "9px"
      desc.style.fontSize = "9px"

    } else {
      stickyElm.style.paddingLeft = "0px"
      stickyElm.style.marginLeft = "15px"
      back.style.borderRadius = "50%"
      back.style.backgroundColor = "#2727274a"
      title.style.fontSize = "20px"
      bonus.style.fontSize = "20px"
      bonusDesc.style.fontSize = "12px"
      desc.style.fontSize = "12px"
      backCover.style.backgroundColor = "transparent"
      stickyElm.style.height = (50 + this.safeArea) +  "px"

    }
  }


  getStorePoint() {
    return this.us.getStorePoint(this.store.id,this.store.parentId)
  }

  getWorkingHours() {
    let ms = moment().weekday()
    return this.store.workingHours[ms]
  }

}
