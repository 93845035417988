import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'orwi-store-range',
  templateUrl: './store-range.component.html',
  styleUrls: ['./store-range.component.scss'],
})
export class StoreRangeComponent implements OnInit {
  @Input() range: number
  constructor() { }

  ngOnInit() {}

}
