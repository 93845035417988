import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import * as MFS from 'src/assets/masterpass/mfs-client.min.js';
import * as jq from 'jquery';
import { GlobalService } from 'src/app/services/global.service';
import { MasterpassService } from '../../services/masterpass.service';
import { MasterpassEventsService } from '../../services/masterpass-events.service';
declare var MFS: any;
let that: any;

@Component({
  selector: 'otp-validation',
  templateUrl: './otp-validation.component.html',
  styleUrls: ['./otp-validation.component.scss'],
})
export class OtpValidationComponent implements OnInit {

 
  smsValidSeconds = 60 * 3
  loading = false
  timerId
  reSend = false
  paymentValidation = false

  @Input() comp = ""
  @Input() visible = false
  @Input() message = ""
  @Input() title = ""
  @Input() otpCode = ""
  @Input() eventType: "payment" | 'register' | 'link' = "register"

  @Output() success = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<any>();

  constructor(public glb: GlobalService, private masterpassService: MasterpassService, private oe: MasterpassEventsService) {


    this.oe.masterpassOtpEvents.subscribe(o => {

      if (o == "validate-otp" && this.eventType == "register") {
        this.paymentValidation = false
        this.visible = true
        clearInterval(this.timerId)
        this.smsValidSeconds = 60 * 3
        this.loading = false
        this.glb.closeLoading()
        this.startInterval()
      } else if (o == "validate-otp-payment" && this.eventType == "payment") {
        this.paymentValidation = true
        this.visible = true
        clearInterval(this.timerId)
        this.smsValidSeconds = 60 * 3
        this.loading = false
        this.glb.closeLoading()
        this.startInterval()
      } else if (o == "validate-otp-link" && this.eventType == "link") {
        this.paymentValidation = false
        this.visible = true
        clearInterval(this.timerId)
        this.smsValidSeconds = 60 * 3
        this.loading = false
        this.glb.closeLoading()
        this.startInterval()
      }



    })



  }

  ngOnInit() {

  }

  shakeOtpDiv() {
    let el = document.getElementById("qrDiv")
    document.getElementById("qrDiv").classList.remove("animate__slideInUp")
    document.getElementById("qrDiv").classList.add("animate__shake")
    setTimeout(() => {
      document.getElementById("qrDiv").classList.remove("animate__shake")
    }, 3000);
  }

  closeOtpDiv() {
    let el = document.getElementById("qrDiv")
    document.getElementById("qrDiv").classList.remove("animate__slideInUp")
    document.getElementById("qrDiv").classList.add("animate__slideOutDown")
    document.getElementById("backDropDiv").classList.add("animate__fadeOut")
    setTimeout(() => {
      this.visible = false
      this.cancel.emit("")
    }, 400);
  }

  verifyOtp() {
    that = this
    this.loading = true;
    if (this.otpCode == "") {
      this.loading = false
      this.errorToast("Tek Kullanımlık Şifrenizi Girmediniz!")
      this.shakeOtpDiv()
      return
    }
    (function ($) {
      $(document).ready(function () {
        $('#otp-form').find('input[name="validationCode"]').val(that.otpCode) //token input a Set Edilir.  
        MFS.validateTransaction($('#otp-form'), mfsResponseHandler);
        return false;
        function mfsResponseHandler(status, response) {
          that.otpResponseHandler(status, response)
        }
      });
    })(jq);
  }

  resendOtp() {

    this.smsValidSeconds = 60 * 3;


    (function ($) {
      $(document).ready(function () {
        var token = MFS.getLastToken();
        var lang = "tur"
        MFS.resendOtp(token, lang, mfsResponseHandler);
        return false;
        function mfsResponseHandler(status, response) {
          if (response.responseCode == "0000" || response.responseCode == "") { // Register Success
            that.shakeOtpDiv()
            that.errorToast(response.responseDescription)
          } else {
            that.startInterval()
          }
        }
      });
    })(jq);
  }

  verify() {
    this.verifyOtp()
  }

  cancelOtp() {
    this.closeOtpDiv()
  }

  getRemainTime() {
    /* let min_int = parseInt((this.smsValidSeconds / 60).toFixed(0))
    let sec_int = this.smsValidSeconds - min_int
    let min = min_int.toString()
    let sec = sec_int.toString()
    min = min.length == 2 ? min : "0" + min
    sec = sec.length == 2 ? sec : '0' + sec */
    let ps = new Date(this.smsValidSeconds * 1000).toISOString().substr(14, 5);
    return ps
  }

  otpResponseHandler(status, response) {
    this.loading = false
    this.glb.consolelog("sendOTP Respone  =>", response)
    if (response.responseCode == "0000" || response.responseCode == "") { // Register Success

      this.closeOtpDiv()
      this.success.emit({ status: true, response: response, payment: this.paymentValidation })
      this.masterpassService.status = "registered"
      this.otpCode = ""
      this.loading = false

      if (this.eventType == "link") {
        this.masterpassService.checkMasterPass()
      }


    } else {
      if (response.responseCode == "5001" || response.responseCode == "5008") {
        this.closeOtpDiv()
        this.message = response.responseDescription
        this.otpCode = ""
        this.loading = false
        setTimeout(() => {
          if (this.eventType == "link") {
            this.oe.masterpassOtpEvents.next("validate-otp-link")
          } else if (this.eventType == "register") {
            this.oe.masterpassOtpEvents.next("validate-otp")
          } else if (this.eventType == "payment") {
            this.oe.masterpassOtpEvents.next("validate-otp-payment")
          }
        }, 800);
      } else {
        this.errorToast(response.responseDescription)
        this.otpCode = ""
        this.shakeOtpDiv()
        this.loading = false
      }
    }
  }

  errorToast(msg: String) {
    this.glb.toast("MasterPass", msg, "bottom", "danger", 3500).then(o => {
      setTimeout(() => {
        this.glb.closeLoading()
      }, 4000);
    })
  }

  startInterval() {
    this.smsValidSeconds = 60 * 3
    this.timerId = setInterval(() => {
      this.smsValidSeconds--
      if (this.smsValidSeconds == 0) {
        clearInterval(this.timerId)
        this.reSend = true
      }
    }, 1000);
  }

  ngOnDestroy() {
    this.glb.consolelog("destroy")
    clearInterval(this.timerId)
    this.smsValidSeconds = 60 * 3
  }

  ngAfterViewInit() {
    that = this
    this.glb.consolelog("hello")
    this.startInterval()
    this.loading = false
  }


}
