import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'masterpass-purchase-form',
  templateUrl: './masterpass-purchase-form.component.html',
  styleUrls: ['./masterpass-purchase-form.component.scss'],
})
export class MasterpassPurchaseFormComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
