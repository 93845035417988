import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { HttpClientModule } from "@angular/common/http";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { TranslocoRootModule } from "./transloco/transloco-root.module";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { BackButtonDisableModule } from "angular-disable-browser-back-button";
import { AkitaNgDevtools } from "@datorama/akita-ngdevtools";
import { SocketIoConfig, SocketIoModule } from "ngx-socket-io";
import { environment } from "../environments/environment";
// const config: SocketIoConfig = {
//   url: "https://service-test.orwi.app:50151",
//   options: {},
// };
const config: SocketIoConfig = { url: 'https://gandalf.orwi.app:50151', options: {} };

import { register } from 'swiper/element/bundle';
import { BelturIntroModule } from "./modules/beltur-intro/beltur-intro.module";
import { OrganizationFormPageModule } from "./modules/beltur-organization/components/organization-form/organization-form.module";
import { AboutBelturComponent } from "./pages/about-beltur/about-beltur.component";
// register Swiper custom elements
register();
@NgModule({
    declarations: [AppComponent, AboutBelturComponent],
    imports: [
        BackButtonDisableModule.forRoot(),
        BrowserModule,
        IonicModule.forRoot(),
        HttpClientModule,
        TranslocoRootModule,
        FontAwesomeModule,
        SocketIoModule.forRoot(config),
        environment.production ? [] : AkitaNgDevtools.forRoot(),
        AppRoutingModule,
        BelturIntroModule,
        OrganizationFormPageModule
    ],
    providers: [
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
