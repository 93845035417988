import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { FeedService } from 'src/app/services/home/feed.service';
import { Plugins } from "@capacitor/core";
const { Keyboard } = Plugins;
import { of } from "rxjs";
import {
  debounceTime,
  map,
  distinctUntilChanged,
  filter
} from "rxjs/operators";
import { fromEvent } from 'rxjs';

@Component({
  selector: 'home-place-search',
  templateUrl: './place-search.component.html',
  styleUrls: ['./place-search.component.scss'],
})
export class PlaceSearchComponent implements OnInit {

  value = ""

  @ViewChild('storeSearchInput') storeSearchInput: ElementRef;

  constructor(private nav: NavController, private fs: FeedService) {




  }

  @Output() searchTextChanged = new EventEmitter<string>()

  ngOnInit() {

    let el = document.getElementById("storeSearchInput")

   }

  filter() {
    this.nav.navigateForward("filters")
  }


  ngAfterViewInit() {
    
    // fromEvent(this.storeSearchInput.nativeElement, 'keyup').pipe(

    //   // get value
    //   map((event: any) => {
    //     return event.target.value;
    //   })
    //   // if character length greater then 2
    //   , filter(res => (res.length > 2 || res.length == 0))

    //   // Time in milliseconds between key events
    //   , debounceTime(1000)

    //   // If previous query is diffent from current   
    //   , distinctUntilChanged()

    //   // subscription for response
    // ).subscribe((text: string) => {


    //   this.fs.searchText = text
    //   this.searchTextChanged.emit(text)

    // });






  }

  change(e) {

  }

  clearInput() {
    if (this.value == "") return
    this.value = this.fs.searchText = ""
    this.searchTextChanged.emit("")
  }



  handleInput(event) {

    const query = event.target.value.toLowerCase();
    this.fs.searchText = query
    this.searchTextChanged.emit(query)


    if (query.length == 0) {
      //this.menuStore.update({ searchedProducts: [], searchKeyword: "" });
      this.fs.searchText = ""
      this.searchTextChanged.emit("")
      return;
    }
 


  }

  key(e) {
    if (e.key == "Enter") {
      Keyboard.hide()
    }
  }

}
