import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'orwi-back',
  templateUrl: './orwi-back.component.html',
  styleUrls: ['./orwi-back.component.scss'],
})
export class OrwiBackComponent implements OnInit {

  @Input() withBackground = false
  @Input() handleButton = false
  @Input() color = "action"
  @Output() buttonClick = new EventEmitter()


  constructor(private nav: NavController) { }

  ngOnInit() { }

  back() {
    if (this.handleButton) {
      this.buttonClick.emit()
    } else {
      this.nav.pop()
    }

  }

}
