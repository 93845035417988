import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController, Platform } from '@ionic/angular';
import { Product, ProductGroup } from 'src/app/services/dto/orwi-product';
import { FbService } from 'src/app/services/events/fb.service';
import { FolioService } from 'src/app/services/folio/folio.service';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';
import { MenuQuery } from 'src/app/state/menu.query';
import { MenuStore } from 'src/app/state/menu.store';
import { ActionSheetController } from '@ionic/angular';
import { TranslocoService } from '@ngneat/transloco';
import { schedulerPayload } from 'src/app/services/dto/orwi-folio';
import { Subscription } from 'rxjs';
import { QrScannerEventsService } from 'src/app/services/events/qr-scanner-events.service';



@Component({
  selector: 'orwi-product-gallery',
  templateUrl: './product-gallery.component.html',
  styleUrls: ['./product-gallery.component.scss'],
})
export class ProductGalleryComponent implements OnInit {

  qrEventSubscription: Subscription
  @Input() type: 'order' | 'menu' = "menu"
  @Input() filteredProducts: Product[] = []
  @Output() addItem = new EventEmitter()
  products: Product[] = []
  objSelectedGroup: ProductGroup
  searchQuery = ""
  giftSelectActive = false

  constructor(
    private menuStore: MenuStore, private chRef: ChangeDetectorRef,
    private plt: Platform,
    private fs: FolioService, private que: QrScannerEventsService, public menuQuery: MenuQuery, private nav: NavController,
    public ss: StoreService, public us: UserService, public glb: GlobalService, private fb: FbService, public actionSheet: ActionSheetController, private transloco: TranslocoService) {


      menuQuery.products$.subscribe(o => {
        console.log(o)
      })

  }

  ngOnInit() {

    this.menuQuery.subGroups$.subscribe(o => {
      setTimeout(() => {
        // this.chRef.detectChanges()
      }, 500);

    })

    this.menuQuery.groups$.subscribe(o => {
      setTimeout(() => {
        // this.chRef.detectChanges()
      }, 500);
    })


    this.menuQuery.giftSelected$.subscribe(o => {
      if (o) {
        this.giftSelected()
      } else {
        this.giftSelectActive = false
      }

    })



  }

  groupSelected(item: ProductGroup) {

    this.giftSelectActive = false
    this.products = this.ss.store.products.filter(o => o.group == item.id)
    this.products.map(o => o["couponCode"] = "")
    this.filterServiceType()
    this.glb.consolelog("Product Gallery -> FilteredProducts", this.filteredProducts)
    this.objSelectedGroup = item

    //this.menuStore.update({ products: this.filteredProducts })
    this.fadeInGallery()



  }

  subGroupSelected(item: ProductGroup) {
    this.giftSelectActive = false
    this.products = this.ss.store.products.filter(o => o.group == item.id)
    this.products.map(o => o["couponCode"] = "")
    this.filterServiceType()
    this.glb.consolelog("Product Gallery -> FilteredProducts", this.filteredProducts)
    //setTimeout(() => {
    //this.menuStore.update({ products: this.filteredProducts })
    this.fadeInGallery()
    //}, 500);


  }

  filterServiceType() {
    if (this.ss.action == "delivery") {
      this.filteredProducts = this.filteredProducts.filter(o => o.isShowDelivery == true)
    } else {
      this.filteredProducts = this.filteredProducts.filter(o => o.isShowInStore == true)
    }
  }

  giftSelected() {

    this.giftSelectActive = true
    this.filteredProducts = []
    let coupons = this.us.user.coupons.filter(o => o.couponType == 'free-product' && o.loaded
      && (o.storeId == this.ss.store.id || o.storeId == this.ss.store.parentId))
    let pids = []

    for (const iterator of coupons) {
      for (const prd of iterator.products) {
        pids.push(prd)
        let pc = this.ss.store.products.find(x => x.id == prd)
        if (pc) {
          pc["couponCode"] = iterator.id
          this.filteredProducts.push(pc)
        }
      }
    }

    setTimeout(() => {

      this.menuStore.update({ products: this.filteredProducts })
      this.fadeInGallery()

    }, 100);


  }


  loadImage(e, p) {

    if (p.imageUse) {

      setTimeout(() => {
        if (e) {
          p.image = this.ss.fetchProductImage(p.id, 'large')
          p.printer = "1"
        }
      }, 10);

    }

  }


  _addItem(e: Product) {



    this.fb.select_item(e.name, this.menuQuery.getValue().selectedGroup.name, this.menuQuery.getValue().selectedGroup.id, this.ss.store.name)
    let groupSoldOut = this.menuQuery.getValue().selectedGroup?.soldOut && e.group == this.menuQuery.getValue().selectedGroup?.id
    let subGrpupSoldOut = this.menuQuery.getValue().selectedSubGroup?.soldOut && e.group == this.menuQuery.getValue().selectedSubGroup?.id
    let usedCoupon = this.isUsedCoupon(e)

    if (e.soldOut || groupSoldOut || subGrpupSoldOut || usedCoupon && this.giftSelectActive) {
      this.glb.shakeInput("item-" + e.id)
      return
    } else {
      this.addItem.emit(e)
      return;
      if (this.ss.action == 'only-menu') {
        this.presentActionSheet()
        return
      } else {
        this.addItem.emit(e)
      }

    }

  }


  fadeInGallery() {
    document.getElementsByClassName("gallery-menu")[0].classList.add("animate__fadeIn")
    setTimeout(() => {
      document.getElementsByClassName("gallery-menu")[0].classList.remove("animate__fadeIn")
    }, 500);
  }

  isUsedCoupon(item: Product) {
    let ix = this.fs.folio.rows.findIndex(o => o.couponCode == item.couponCode)
    return (ix !== -1)
  }

  getPrice(item: Product) {
    if (this.giftSelectActive) return 0
    if (this.ss.action == "delivery") {
      return item.dlPrice || item.price
    } else {
      return item.price
    }
  }


  async presentActionSheet() {
    this.actionSheet.create({
      header: this.ss.store.name,
      cssClass: 'my-custom-class',
      buttons: this.fillActionButtons()
    }).then(res => {
      res.present();
    });

  }

  fillActionButtons() {
    let buttonList = []

    if (this.ss.store.properties.timedTakeAway == true) {
      buttonList.push({
        text: this.transloco.translate('Takeaway'),
        icon: 'assets/images/store-home/takeaway.svg',
        handler: () => {
          this.actionButtonClick('timed-take-away')
        }
      })
    }
    if (this.ss.store.properties.timedDineIn == true) {
      buttonList.push({
        text: this.transloco.translate('Quick Table'),
        icon: 'assets/images/store-home/speedy-table.svg',
        handler: () => {
          this.actionButtonClick('timedDineIn')
        }
      })
    }

    if (this.ss.store.properties.delivery == true) {
      buttonList.push({
        text: this.transloco.translate('Delivery'),
        icon: 'assets/images/store-home/delivery.svg',
        handler: () => {
          this.actionButtonClick('delivery')
        }
      })
    }

    if (this.ss.store.properties.order == true) {
      buttonList.push({
        text: this.transloco.translate('Are you in the place?'),
        icon: 'assets/images/store-home/in-store.svg',
        handler: () => {
          this.actionButtonClick('table')
        }
      })
    }

    if (this.ss.store.properties.reservation == true) {
      buttonList.push({
        text: this.transloco.translate('reservation'),
        icon: 'assets/images/store-home/reservation.svg',
        handler: () => {
          this.actionButtonClick('reservation')
        }
      })
    }


    buttonList.push(
      {
        text: this.transloco.translate('Cancel'),
        icon: 'close',
        role: 'cancel',
        handler: () => {
          console.log('Cancel clicked');
        }
      }
    )




    return buttonList
  }


  actionButtonClick(e) {

    switch (e) {
      case 'delivery':
        this.us.checkActiveUser(
          (allreadyLoggedIn) => {
            this.nav.navigateForward("address-list", {
              replaceUrl: !allreadyLoggedIn
            })
          })
        break;
      case 'timedDineIn':
        this.us.checkActiveUser(
          (allreadyLoggedIn) => {
            let payload: schedulerPayload = {
              status: "timed-table",
              point: this.ss.store.orwiPayPoint,
              name: this.ss.store.name,
              storeId: this.ss.store.id,
              type: "timed-table",
            }
            this.nav.navigateForward(["/scheduler", payload], { replaceUrl: !allreadyLoggedIn })
          }
        )
        break;

      case 'table':
        setTimeout(() => {
          if (this.plt.is("capacitor")) {
            this.nav.navigateForward("scan-qr")
          } else {
            this.nav.navigateForward("scan-qr-web")
          }
        }, 600);

        if (this.qrEventSubscription) this.qrEventSubscription.unsubscribe; this.qrEventSubscription = undefined
        this.qrEventSubscription = this.que.qrScannerEvent.subscribe(o => {
          this.glb.consolelog("qrScannerEvent", "qr-button")
          if (o.status == "success") {
            this.ss.storeCheckIn(o.qrcode)
            this.qrEventSubscription.unsubscribe()
          } else {
            this.qrEventSubscription.unsubscribe()
          }
        })
        break;
      case 'reservation':
        this.nav.navigateForward("reservation-home")
        break;
      case 'timed-take-away':
        this.us.checkActiveUser(
          (allreadyLoggedIn) => {
            let payload: schedulerPayload = {
              status: "timed-check-in",
              point: this.ss.store.orwiPayPoint,
              name: this.ss.store.name,
              storeId: this.ss.store.id,
              type: "timed-take-away",
            }
            this.nav.navigateForward(["/scheduler", payload], { replaceUrl: !allreadyLoggedIn })
          }

        )
        break;
      default:
        break;
    }



    // if (e == "delivery") {
    //   this.us.checkActiveUser(
    //     (allreadyLoggedIn) => {
    //       this.nav.navigateForward("address-list", {
    //         replaceUrl: !allreadyLoggedIn
    //       })
    //     })
    // }
    // else if (e == 'timedDineIn') {
    //   this.us.checkActiveUser(
    //     (allreadyLoggedIn) => {
    //       let payload: schedulerPayload = {
    //         status: "timed-table",
    //         point: this.ss.store.orwiPayPoint,
    //         name: this.ss.store.name,
    //         storeId: this.ss.store.id,
    //         type: "timed-table",
    //       }
    //       this.nav.navigateForward(["/scheduler", payload], { replaceUrl: !allreadyLoggedIn })
    //     }

    //   )
    // }
    // else if (e == 'table') {
    //   setTimeout(() => {

    //     if (this.plt.is("capacitor")) {
    //       this.nav.navigateForward("scan-qr")
    //     } else {
    //       this.nav.navigateForward("scan-qr-web")
    //     }
    //   }, 600);

    //   if (this.qrEventSubscription) this.qrEventSubscription.unsubscribe; this.qrEventSubscription = undefined
    //   this.qrEventSubscription = this.que.qrScannerEvent.subscribe(o => {
    //     this.glb.consolelog("qrScannerEvent", "qr-button")
    //     if (o.status == "success") {
    //       this.ss.storeCheckIn(o.qrcode)
    //       this.qrEventSubscription.unsubscribe()
    //     } else {
    //       this.qrEventSubscription.unsubscribe()
    //     }
    //   })
    // }
    // else if (e == 'reservation') {
    //   this.nav.navigateForward("reservation-home")
    // }

    // else if (e == 'timed-take-away') {
    //   this.us.checkActiveUser(

    //     (allreadyLoggedIn) => {
    //       let payload: schedulerPayload = {
    //         status: "timed-check-in",
    //         point: this.ss.store.orwiPayPoint,
    //         name: this.ss.store.name,
    //         storeId: this.ss.store.id,
    //         type: "timed-take-away",
    //       }
    //       this.nav.navigateForward(["/scheduler", payload], { replaceUrl: !allreadyLoggedIn })
    //     }

    //   )
    // }
  }



  myGifts() {
    this.nav.navigateForward("my-gifts")
  }

}
