// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bonus {
  font-size: 20px;
  font-weight: bold;
  color: #09ce59;
  transition: all 0.1s;
}

.desc {
  font-size: 12px;
  transition: all 0.1s;
}

.title {
  font-weight: bold;
  font-size: 20px;
  font-family: "Inter";
  transition: all 0.1s;
}

.store-title {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  padding-right: 15px;
  margin-left: 15px;
  align-items: center;
  position: sticky;
  z-index: 200;
  background-color: var(--ion-background-color);
  transition: all 0.2s;
  width: 100%;
  min-height: 50px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home/store-title/store-title.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,iBAAA;EACA,cAAA;EACA,oBAAA;AACF;;AAEA;EACE,eAAA;EACA,oBAAA;AACF;;AAEA;EACE,iBAAA;EACA,eAAA;EACA,oBAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;EACA,8BAAA;EACA,gBAAA;EACA,mBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EAEA,YAAA;EACA,6CAAA;EACA,oBAAA;EACA,WAAA;EACA,gBAAA;AAAF","sourcesContent":[".bonus {\n  font-size: 20px;\n  font-weight: bold;\n  color: #09ce59;\n  transition: all 0.1s;\n}\n\n.desc {\n  font-size: 12px;\n  transition: all 0.1s;\n}\n\n.title {\n  font-weight: bold;\n  font-size: 20px;\n  font-family: 'Inter';\n  transition: all 0.1s;\n}\n\n.store-title {\n  display: flex;\n  justify-content: space-between;\n  margin-top: 10px;\n  padding-right: 15px;\n  margin-left: 15px;\n  align-items: center;\n  position: sticky;\n  //top: var(--ion-safe-area-top);\n  z-index: 200;\n  background-color: var(--ion-background-color);\n  transition: all 0.2s;\n  width: 100%;\n  min-height: 50px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
