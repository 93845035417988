import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OrwiAddress } from 'src/app/services/dto/orwi-address';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'orwi-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss'],
})
export class AddressFormComponent implements OnInit {


  @Input() orwiAddress: OrwiAddress = new OrwiAddress()
  @Output() cancelForm = new EventEmitter
  @Output() saveForm = new EventEmitter

  constructor(private glb: GlobalService) { }

  ngOnInit() { }

  cancel() {
    this.cancelForm.emit()
    this.closeForm()
  }

  save() {
    this.validateForm()
  }

  closeForm() {

    let bd = document.getElementById("backdrop-address")
    bd.style.height = "0px"

    let el = document.getElementById("address-form")
    el.classList.add("animate__slideOutDown")
    setTimeout(() => {
      el.classList.remove("animate__slideOutDown")
    }, 1000);

    setTimeout(() => {
      el.style.position = ""
      el.style.zIndex = "200"
      el.style.top = "0px"
    }, 200);
  }

  validateForm() {
    let nameValid = this.orwiAddress.name.length > 1 ? true : false
    let directionValid = this.orwiAddress.direction == "" ? false : true
    if (!nameValid) {
      this.glb.shakeInput("address-name")
    }

    if (!directionValid) {
      this.glb.shakeInput("address-direction")
    }

    if (nameValid && directionValid) {
      this.closeForm()
      this.saveForm.emit()
    }

  }


  
}
