import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-slide3',
  templateUrl: './slide3.page.html',
  styleUrls: ['./slide3.page.scss'],
})
export class Slide3Page implements OnInit {

  constructor(private nav: NavController) { }

  ngOnInit() {
  }

  skip() {
    this.nav.navigateForward("home")
  }

  skipAll() {
    this.nav.navigateRoot("home")
  }

}
