// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-button {
  display: flex;
  justify-content: flex-end;
  z-index: 99999;
  font-size: 30px;
  mix-blend-mode: difference;
  position: absolute;
  top: 10px;
}

.back-button {
  display: flex;
  justify-content: flex-start;
  z-index: 99999;
  font-size: 30px;
  mix-blend-mode: difference;
  position: absolute;
  top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ui/closer/closer.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,yBAAA;EAEA,cAAA;EACA,eAAA;EACA,0BAAA;EAEA,kBAAA;EACA,SAAA;AADJ;;AAIA;EACI,aAAA;EACA,2BAAA;EAEA,cAAA;EACA,eAAA;EACA,0BAAA;EAEA,kBAAA;EAEA,SAAA;AAJJ","sourcesContent":[".close-button {\n    display: flex;\n    justify-content: flex-end;\n\n    z-index: 99999;\n    font-size: 30px;\n    mix-blend-mode: difference;\n\n    position: absolute;\n    top: 10px\n}\n\n.back-button {\n    display: flex;\n    justify-content: flex-start;\n\n    z-index: 99999;\n    font-size: 30px;\n    mix-blend-mode: difference;\n\n    position: absolute;\n\n    top: 10px\n   \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
