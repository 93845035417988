// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (prefers-color-scheme: dark) {
  .bonus {
    color: limegreen;
    font-size: 16px;
  }
}
.bonus {
  color: green;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home/store-range/store-range.component.scss"],"names":[],"mappings":"AAAA;EACI;IACE,gBAAA;IACA,eAAA;EACJ;AACF;AAIE;EACE,YAAA;EACA,eAAA;AAFJ","sourcesContent":["@media (prefers-color-scheme: dark) {\n    .bonus {\n      color: limegreen;\n      font-size: 16px;\n    }\n  \n    \n  }\n  \n  .bonus {\n    color: green;\n    font-size: 16px;\n  }\n  \n "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
