import { Injectable } from "@angular/core";
import { DeviceInfo } from "@capacitor/device";
import { OpenNativeSettings } from "@awesome-cordova-plugins/open-native-settings";
import {
  LoadingController,
  ModalController,
  ToastController,
  ModalOptions,
  AlertController,
  AlertOptions,
  ActionSheetOptions,
  ActionSheetController,
} from "@ionic/angular";
import { LoadingOptions } from "@ionic/core";
import { TranslocoService } from "@ngneat/transloco";
import { feedback } from "./dto/orwi-feedback";
import { environment } from "../../environments/environment";
@Injectable({
  providedIn: "root",
})
export class GlobalService {
  feedback: feedback;

  DeviceInfo: iDeviceInfo;

  PushToken = "";

  darkTheme = false;
  startupQrCode = "";

  networkConnected = true;
  googleMapSdkInjected = false;
  googleApiKey = "AIzaSyBkTa1cYBK4hq4qNuMNmDl68Pwi6XaT87c";

  oLoading: HTMLIonLoadingElement;

  ipAddress = "";

  orwiContent: any;

  tempBanner =
    "https://www.turizmgunlugu.com/wp-content/uploads/2017/12/mila-restoran.jpg";

  orwiApp = { version: "", production: false };
  tempProductImage: string;
  giftCardImage: string;
  uuid: any;

  constructor(
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private translocoService: TranslocoService,
    private modalController: ModalController,
    private alertController: AlertController,
    private actionSheetController: ActionSheetController
  ) {
    //this.googleApiKey = "AIzaSyBlcvydJ5f6zvpIa0uj0rZG2Kk6O-I5Prk"

    this.orwiApp.production = environment.production;
    this.orwiApp.version = environment.appVersion;
  }

  async openAlert(options: AlertOptions) {
    const alert = await this.alertController.create(options);
    await alert.present();

    return alert;
  }

  async openActionSheet(sheetOptions: ActionSheetOptions) {
    const actionSheet = await this.actionSheetController.create(sheetOptions);

    await actionSheet.present();

    return actionSheet.onDidDismiss();
  }

  consolelog(
    log1,
    log2 = undefined,
    log3 = undefined,
    type: "public" | "private" = "private",
    logType: "log" | "error" | "warn" = "log"
  ) {
    // if (type == "private" && document.URL.indexOf("localhost") == -1) {
    if (environment.production) return;
    if (document.URL.indexOf("localhost") !== -1) {
      //return
    }
    if (logType == "log") {
      console.log(log1, log2, log3);
    } else if (logType == "error") {
      console.error(log1, log2, log3);
    } else {
      console.warn(log1, log2, log3);
    }
  }

  loadingTimeOut = undefined;
  async showLoading(m: string = "") {
    const globalLoading = document.querySelector("#global-loading");

    if (!globalLoading) return;

    globalLoading.classList.add("active");

    if (!this.loadingTimeOut) {
      this.loadingTimeOut = setTimeout(() => {
        this.closeLoading();
      }, 30000);
    }

    return;

    let options: LoadingOptions = {
      message:
        '<ion-img src="/assets/icon/beltur-jump.png" alt="loading..."></ion-img>',
      cssClass: "custom-loading",
      translucent: false,
      showBackdrop: false,
      spinner: null,
      keyboardClose: true,
      duration: 30000,
    };

    let opt2 = {
      cssClass: "loading-controller",
      message: "",
      duration: 30000,
    };

    this.oLoading = await this.loadingCtrl.create(options);
    await this.oLoading.present();
  }

  closeLoading(): Promise<boolean> {
    const globalLoading = document.querySelector("#global-loading");

    if (!globalLoading) return;
    globalLoading.classList.remove("active");

    if (this.loadingTimeOut) {
      clearTimeout(this.loadingTimeOut);
      this.loadingTimeOut = undefined;
    }

    return;

    return new Promise((resolve, reject) => {
      if (this.oLoading) {
        this.oLoading.dismiss();
        this.oLoading = undefined;
      }
      setTimeout(() => {
        if (this.oLoading) {
          this.oLoading.dismiss();
          this.oLoading = undefined;
        }
        resolve(true);
      }, 500);
    });
  }

  async toast(
    header,
    message,
    position: "top" | "middle" | "bottom",
    color: "danger" | "warning" | "success" | "dark",
    duration: number = 2000,
    button: boolean = false
  ) {
    let config = {
      header: header,
      message: message,
      position: position,
      color: color,
      animated: true,
      cssClass: "toast",
    };

    if (button) {
      config["buttons"] = [
        {
          text: this.translocoService.translate("Yes"),
          role: "cancel",
          handler: () => {
            this.consolelog("Cancel clicked");
          },
        },
        {
          text: this.translocoService.translate("No"),
          role: "cancel",
          handler: () => {
            this.consolelog("Cancel clicked");
          },
        },
      ];
    } else {
      config["duration"] = duration;
    }

    const toast = await this.toastCtrl.create(config);
    toast.present();
  }

  async toastButton(
    header,
    message,
    position: "top" | "middle" | "bottom",
    color: "danger" | "warning" | "success",
    okey = true,
    cancel = true,
    done = false
  ) {
    let duration = 0;
    let button = true;

    let buttons = [];

    return new Promise(async (resolve, reject) => {
      let config = {
        header: header,
        message: message,
        position: position,
        color: color,
        animated: true,
        cssClass: "toast",
      };

      if (cancel) {
        buttons.push({
          text: this.translocoService.translate("No"),
          role: "cancel",
          handler: () => {
            this.consolelog("Cancel clicked");
            resolve("no");
          },
        });
      }

      if (okey) {
        buttons.push({
          text: this.translocoService.translate("Yes"),
          role: "okey",
          handler: () => {
            this.consolelog("Okey clicked");
            resolve("yes");
          },
        });
      }

      if (done) {
        buttons.push({
          text: this.translocoService.translate("Done"),
          role: "okey",
          handler: () => {
            this.consolelog("Okey clicked");
            resolve("yes");
          },
        });
      }

      if (buttons) {
        config["buttons"] = buttons;
      } else {
        config["duration"] = duration;
      }

      const toast = await this.toastCtrl.create(config);
      toast.present();
    });
  }


  getImageBrightness(imageUrl: string, rois: ROI[]): Promise<number[]> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        if (!context) {
          reject("Unable to get 2D context");
          return;
        }

        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0);

        const imageData = context.getImageData(
          0,
          0,
          canvas.width,
          canvas.height
        ).data;
        const brightnessValues = this.calculateRegionBrightness(
          imageData,
          canvas.width,
          canvas.height,
          rois
        );

        resolve(brightnessValues);
      };

      img.onerror = () => {
        reject("Error loading image");
      };

      img.src = imageUrl;
    });
  }

  private calculateRegionBrightness(
    imageData: Uint8ClampedArray,
    width: number,
    height: number,
    rois: ROI[]
  ): number[] {
    const brightnessValues: number[] = [];

    for (const roi of rois) {
      let sum = 0;
      let pixelCount = 0;

      for (let y = roi.y; y < roi.y + roi.height; y++) {
        for (let x = roi.x; x < roi.x + roi.width; x++) {
          const i = (y * width + x) * 4;

          // Check if the pixel is within the image bounds
          if (x >= 0 && x < width && y >= 0 && y < height) {
            // Extract RGB values
            const red = imageData[i];
            const green = imageData[i + 1];
            const blue = imageData[i + 2];

            // Calculate brightness using luminance formula
            const brightness = 0.299 * red + 0.587 * green + 0.114 * blue;

            sum += brightness;
            pixelCount++;
          }
        }
      }

      // Calculate average brightness for the ROI
      const averageBrightness = sum / pixelCount;
      brightnessValues.push(averageBrightness);
    }

    return brightnessValues;
  }

  openNativeAppSettings() {
    OpenNativeSettings
      .open("application_details")
      .then((res) => {
        this.consolelog(res);
      })
      .catch((err) => {
        this.consolelog(err);
      });
  }

  titleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        if (word[0]) {
          return word.replace(word[0], word[0].toUpperCase());
        } else {
          return word;
        }
      })
      .join(" ");
  }

  notTurkishCharacter(value: string): string {
    let replacedVal = value
      .replace(/ı/g, "i")
      .replace(/İ/g, "I")
      .replace(/ü/g, "u")
      .replace(/Ü/g, "U")
      .replace(/ş/g, "s")
      .replace(/Ş/g, "S")
      .replace(/ö/g, "o")
      .replace(/Ö/g, "O")
      .replace(/ğ/g, "g")
      .replace(/Ğ/g, "G")
      .replace(/ç/g, "c")
      .replace(/Ç/g, "C");
    return replacedVal;
  }

  cleanGsm(gsm: string): string {
    return gsm
      .replace("+", "")
      .replace("(", "")
      .replace("(", "")
      .replace("-", "")
      .replace(" ", "")
      .trim();
  }

  shakeInput(id) {
    let el = document.getElementById(id);
    if (!el.classList.contains("animate__animated")) {
      el.classList.add("animate__animated");
    }

    el.classList.add("animate__shakeX");

    setTimeout(() => {
      el.classList.remove("animate__shakeX");
    }, 1000);
  }

  

  async openModal(modalOptions: ModalOptions): Promise<HTMLIonModalElement> {
    const modal = await this.modalController.create({
      ...modalOptions,
    });

    await modal.present();

    return modal;
  }

  async closeModal(data?: any) {
    return this.modalController.dismiss(data);
  }
}

export interface iDeviceInfo extends DeviceInfo {
  uuid?: string;
  appVersion? : string
  appBuild?: string
  appName?  :string
}


export interface ROI {
  x: number;
  y: number;
  width: number;
  height: number;
}