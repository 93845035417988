import { Component, OnInit, Input, ViewChild, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { IonItemSliding } from '@ionic/angular';
import { Folio, FolioRow } from 'src/app/services/dto/orwi-folio';
import { FolioService } from 'src/app/services/folio/folio.service';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'orwi-folio-rows',
  templateUrl: './folio-rows.component.html',
  styleUrls: ['./folio-rows.component.scss'],
})
export class FolioRowsComponent implements OnInit, OnChanges {



  @Input() folio: Folio
  @Input() totalRowVisible = false
  @Input() deleteEnable = true
  @Input() qtyEnable = false
  @Input() rows: 'all' | 'old' | 'new' | 'payment' = "all"
  @Input() headers = false
  @Input() attachFolio = false

  @ViewChild('slideItem') dialog: IonItemSliding

  constructor(
    private chref: ChangeDetectorRef,
    public fs: FolioService, public ss: StoreService, private glb: GlobalService) { }

  ngOnInit() {

   /*  this.folio.rows.map(o => {
      o.orginalQty = o.qty
    }) */


    setTimeout(() => {
      this.showSlider()
    }, 500);
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.folio) {
      this.chref.detectChanges()
    }
  }

  getRows(type?) {
    let _type = type ? type : this.rows
    if (_type == "all") {
      return this.fs.getRows(this.folio)
    } else if (_type == "new") {
      return this.fs.getNewRows(this.folio)
    } else if (_type == "old") {
      return this.fs.getOldRows(this.folio)
    } else if (_type == "payment") {
      return this.fs.getPaymentRows(this.folio)
    }
  }

  isEmpty() {
    return this.getRows().length == 0
  }




  deleteFolioRow(item: FolioRow) {

    this.fs.removeRow(item.id)
    let count = this.fs.getProductCountOnFolio(item.itemID)
    this.ss.store.products.find(o => o.id == item.itemID).temp_onFolioCount = count
    this.fs.saveFolio()
  }

  addFavorite(item) {

  }

  editItem(item) {

  }

  add(fr: FolioRow) {

    if (this.attachFolio) {
     
      if (fr.orginalQty == fr.qty) {
        return
      } else {
        fr.qty++
      }
      
      
    }

  }

  remove(fr: FolioRow) {



    if (this.attachFolio) {
     
      if (fr.qty == 0) {
        return
      } else {
        fr.qty--
      }
      
      
    }

  }

  showSlider() {

    if (!this.dialog) return;
    //if (this.glb.listItemSlidingHelper) return;
    setTimeout(() => {
      this.dialog.open("start")
      setTimeout(() => {
        this.dialog.open("end")
      }, 1000);
      setTimeout(() => {
        this.dialog.close()
      }, 1500);

    }, 1);
    //this.glb.listItemSlidingHelper = true
  }

}
