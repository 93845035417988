// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.os-main {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 25px;
  margin-left: 10px;
  margin-right: 10px;
  box-shadow: var(--orwi-shadow);
  height: 50px;
  border-radius: 10px;
}

.os-icon {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.3215686275);
  font-size: 18px;
}

.os-button {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.os-text {
  width: calc(100% - 100px);
}

.os-input {
  width: 100%;
  font-size: 14px;
  background-color: var(--background);
  border: 0px;
}

.search-bar {
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home/place-search/place-search.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,iBAAA;EACA,kBAAA;EACA,8BAAA;EACA,YAAA;EACA,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,kCAAA;EACA,eAAA;AACJ;;AAEA;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,WAAA;EACA,eAAA;EACA,mCAAA;EACA,WAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":[".os-main {\n    display: flex;\n    align-items: center;\n    margin-top: 20px;\n    margin-bottom: 10px;\n    font-size: 25px;\n    margin-left: 10px;\n    margin-right: 10px;\n    box-shadow: var(--orwi-shadow);\n    height: 50px;\n    border-radius: 10px;\n}\n\n.os-icon {\n    width: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: #00000052;\n    font-size: 18px;\n}\n\n.os-button {\n    width: 50px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.os-text {\n    width: Calc(100% - 100px);\n}\n\n.os-input {\n    width: 100%;\n    font-size: 14px;\n    background-color: var(--background);\n    border: 0px;\n}\n\n.search-bar {\n    font-size: 16px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
