import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Slide1Page } from './components/slide1/slide1.page';
import { Slide2Page } from './components/slide2/slide2.page';
import { Slide3Page } from './components/slide3/slide3.page';

const routes: Routes = [
  {path: "slide-1", component: Slide1Page},
  {path: "slide-2", component: Slide2Page},
  {path: "slide-3", component: Slide3Page},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BelturIntroRoutingModule { }
