import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'orwi-circular-progress',
  templateUrl: './circular-progress.component.html',
  styleUrls: ['./circular-progress.component.scss'],
})
export class CircularProgressComponent implements OnInit, OnChanges {

  @Input() width = "90px"
  @Input() height = "90px"
  @Input() background = "white"
  @Input() foreground = "#D06740"
  @Input() value = 20
  @Input() id = "TG"

  RADIUS = 12;
  CIRCUMFERENCE = 2 * Math.PI * this.RADIUS;

  constructor() {

    

    

   }
  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.value) {
      
      this.progress(changes.value.currentValue, changes.value.previousValue)
    }
  }

   ngAfterViewInit() {
    let progressMeter: any = document.getElementById('progress__meter' + this.id);
    if (!progressMeter) return;
    
    progressMeter.style.stroke = this.background

    let progressValue: any = document.getElementById('progress__value' + this.id) ;
    progressValue.style.stroke = this.foreground

    let progress: any = document.getElementById('progress' + this.id);
    progress.style.width = this.width
    progress.style.height = this.height
    this.progress(this.value)
   }

  progress(value, from = 0) {

    let main: any = document.getElementById('progress' + this.id)

    let lFrom = from
    if (from > value) {
      lFrom = 100 - from
    }
    if (main) {
      main.style.setProperty('--from-value', lFrom);

    } else {
      return
    }
  


    let progressValue: any = document.getElementById('progress__value' + this.id);
    let progress = value / 100;
    let dashoffset = this.CIRCUMFERENCE * (1 - progress);
    progressValue.style.strokeDasharray = this.CIRCUMFERENCE;
    // console.log('progress:', value + '%', '|', 'offset:', dashoffset)

   
    progressValue.style.strokeDashoffset = dashoffset;
    progressValue.style.to = dashoffset
    progressValue.classList.add("anim")

    setTimeout(() => {
      progressValue.classList.remove("anim")
    }, 2000);

  }

  setValue(val) {
    this.progress(val)
  }

  ngOnInit() {}

}
