// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mod-select {
  margin-bottom: 10px;
}

ion-segmen ion-segment-button {
  --background-checked: red;
  --background-focused: red;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home/mode-select/mode-select.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,yBAAA;AACJ","sourcesContent":[".mod-select {\n    margin-bottom: 10px;\n}\n\nion-segmen ion-segment-button {\n    --background-checked: red;\n    --background-focused : red;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
