// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orwi-scratch {
  margin: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: var(--orwi-dialog-color);
  box-shadow: var(--orwi-shadow);
  border-radius: var(--orwi-radius);
  color: var(--ion-text-color);
  z-index: 101;
  height: 100%;
  width: 100%;
  opacity: 0;
  font-family: "Inter";
  padding-top: var(--ion-safe-area-top);
  box-shadow: var(--orwi-shadow);
  display: flex;
  flex-direction: column;
  padding-bottom: var(--ion-safe-area-bottom);
}

.title {
  width: 100%;
  background-color: var(--ion-color-success);
  color: var(--ion-color-success-contrast);
  padding: 9px;
  font-weight: bold;
  min-height: 35px;
}

.close {
  font-size: 25px;
  position: absolute;
  right: 8px;
  z-index: 102;
  top: calc(var(--ion-safe-area-top) + 8px);
  color: var(--ion-color-success-contrast);
}

.sci {
  width: 100%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-size: 16px;
  flex-direction: column;
  margin-top: 10px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gamification/orwi-scratch/orwi-scratch.component.scss"],"names":[],"mappings":"AAAA;EACI,SAAA;EACA,kBAAA;EACA,MAAA;EACA,SAAA;EACA,OAAA;EACA,QAAA;EACA,YAAA;EACA,0CAAA;EACA,8BAAA;EACA,iCAAA;EACA,4BAAA;EACA,YAAA;EACA,YAAA;EACA,WAAA;EACA,UAAA;EACA,oBAAA;EACA,qCAAA;EACA,8BAAA;EACA,aAAA;EACA,sBAAA;EACA,2CAAA;AACJ;;AAEE;EACE,WAAA;EACA,0CAAA;EACA,wCAAA;EACA,YAAA;EACA,iBAAA;EACA,gBAAA;AACJ;;AAEE;EACE,eAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;EACA,yCAAA;EACA,wCAAA;AACJ;;AAEE;EACE,WAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;EACA,eAAA;EACA,sBAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":[".orwi-scratch {\n    margin: 0;\n    position: absolute;\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    margin: auto;\n    background-color: var(--orwi-dialog-color);\n    box-shadow: var(--orwi-shadow);\n    border-radius: var(--orwi-radius);\n    color: var(--ion-text-color);\n    z-index: 101;\n    height: 100%;\n    width: 100%;\n    opacity: 0;\n    font-family: \"Inter\";\n    padding-top: var(--ion-safe-area-top);\n    box-shadow: var(--orwi-shadow);\n    display: flex;\n    flex-direction: column;\n    padding-bottom: var(--ion-safe-area-bottom);\n  }\n\n  .title {\n    width: 100%;\n    background-color: var(--ion-color-success);\n    color: var(--ion-color-success-contrast);\n    padding: 9px;\n    font-weight: bold;\n    min-height: 35px;\n  }\n\n  .close {\n    font-size: 25px;\n    position: absolute;\n    right: 8px;\n    z-index: 102;\n    top: Calc(var(--ion-safe-area-top) + 8px);\n    color: var(--ion-color-success-contrast);\n  }\n\n  .sci {\n    width: 100%;\n    border-radius: 10px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-family: \"Inter\";\n    font-size: 16px;\n    flex-direction: column;\n    margin-top: 10px;\n    text-align: center;\n  }\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
