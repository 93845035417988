import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { NavController, DomController } from '@ionic/angular';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'orwi-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {


  constructor(
    private elementref: ElementRef,
    public us: UserService,
    private nav: NavController,
    private dom: DomController,
    private renderer: Renderer2) { }
  ngOnInit() { }




  initHeader() {

    //this.elementref.nativeElement.style.setProperty('--orwi-top', "400px")

    var style = getComputedStyle(document.body);
    let safeAreaStr = style.getPropertyValue('--ion-safe-area-top')
    safeAreaStr = safeAreaStr.replace("px", "")
    let safe = parseInt(safeAreaStr)

    let orwiUser = document.getElementById("orwi-user")
    let orwiHeader = document.getElementById("orwihead")

    let orwiHeaderMarginTop = safe + 49
    
    this.renderer.setStyle(orwiHeader, "height", orwiHeaderMarginTop + 'px')



    document.documentElement.style.setProperty('--orwi-top', (100 + safe) + "px");

  }


  login() {
    this.nav.navigateForward("/otp-request")
  }

  back() {
    this.nav.pop()
  }

}
