import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { GlobalService } from 'src/app/services/global.service';
import { FeedService } from 'src/app/services/home/feed.service';
import { StoreService } from 'src/app/services/store/store.service';
import { UIStore } from 'src/app/state/ui/ui.store';

@Component({
  selector: 'orwi-slide-header',
  templateUrl: './slide-header.component.html',
  styleUrls: ['./slide-header.component.scss'],
})
export class SlideHeaderComponent implements OnInit {

  @Output() back_click = new EventEmitter()

  banners = []
  constructor(
    private uiStore: UIStore,
    private glb: GlobalService,
    private nav: NavController,
    private feedService: FeedService,
    private ss: StoreService) { }

  ngOnInit() {
    this.banners = []
    if (!this.banners || this.banners.length == 0) {
      this.banners = []
      // this.banners.push({ base64: this.glb.tempBanner })
    }

    this.ss.getStoreBanners(this.ss.activeStoreId).then((o: any) => {
      if (o && o.length > 0) {
        this.banners = o
      }
    })

  }

  ngAfterViewInit() {
    
  }

  back() {
    //this.nav.pop()
    this.back_click.emit()
  }

  openUrl(item) {
    debugger;
    console.log("clicked!", item);
    if (!item.rootLink) return;

    if (item.rootLink.includes("https")) {
      window.open(item.rootLink, "_blank");
    } else {
      this.nav.navigateForward(item.rootLink);
    }
  }

  contact() {
    this.uiStore.update({orwiContactVisible: true})
  }

}
