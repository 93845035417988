import { Injectable } from '@angular/core';
import { UserService } from '../user/user.service';
import { IdGeneratorService } from '../helpers/id-generator.service';
import { OrwiService } from '../orwi.service';
import * as moment from 'moment';
import { NavController } from '@ionic/angular';
import { GlobalService } from '../global.service';
import { deliveryFolio, Folio, FolioRow, RecordStatus, ServiceType } from '../dto/orwi-folio';
import { StoreService } from '../store/store.service';
import { Modifier, ModifierProduct, Product } from '../dto/orwi-product';
import { StoreEventsService } from '../events/store-events.service';
import { TranslocoService } from '@ngneat/transloco';
import { promptInput, promptResponse } from 'src/app/components/ui/orwi-prompt/orwi-prompt.component';
import { OrwiPromptService } from 'src/app/components/ui/orwi-prompt/orwi-prompt.service';
import { FbService } from '../events/fb.service';
import { Ticket } from '../dto/orwi-store';
@Injectable({
  providedIn: 'root'
})
export class FolioService {



  previousFolioId = ""
  activeRow: FolioRow
  folio: Folio = new Folio()
  reservation: Ticket = new Ticket()
  folioStatus: 'unset' | 'loading' | 'loaded' | 'done' | 'close' | 'save' | 'error'

  constructor(
    private se: StoreEventsService,
    private storeService: StoreService,
    private orwiService: OrwiService,
    private userService: UserService,
    private idGen: IdGeneratorService,
    private nav: NavController,
    private op: OrwiPromptService,
    private glb: GlobalService,
    private transloco: TranslocoService,
    private fb :  FbService
      ) {


    this.se.storeChecked.subscribe(o => {
      this._getFolio()
    })



    /*     this.oe.appStateEvent.subscribe(o => {
          if (o) {
            this.glb.removePendingLocalNotif()
            if (this.folio && this.folio.id) {
              this._refreshFolio()
              this.storeService.fetchStoreInfo(this.storeService.store.id)
            }
          } else {
            if (this.folio && this.folio.id) {
              if (this.getNewRows().length > 0) {
                this.glb.localNotif()
              }
            }
          }
        })
    
        this.oe.storeEvents.subscribe(o => {
          if (o == "loaded") {
            this._getFolio()
          } else if (o == "loading") {
            this.folio = new Folio()
          }
        })
    
        this.oe.folioEvents.subscribe(o => {
          this.folioStatus = o
        })
    
     
    
        this.oe.socketEvents.subscribe(async o => {
          this.glb.consolelog("Folio Socket Event", o)
          if (o.type == "folio-changed") {
            let rows: FolioRow[] = o.data;
            rows.forEach(rw => {
              let fl = this.folio.rows.findIndex(fl => fl.id == rw.id)
              if (fl > -1) {
                this.folio.rows[fl] = rw
              } else {
                this.folio.rows.push(rw)
              }
            })
          } else if (o.type == "folio-received") {
            this.folio = await this._getFolioById(o.data)
            this.nav.navigateForward(this.glb.paymentPage)
          } else if (o.type == "folio-closed") {
            this._getFolio()
            this.nav.navigateRoot(this.glb.landingPage)
          }
        }) */



  }

  //create new or open folio //!load open folio if exists
  async createOrOpenFolio(type: ServiceType, tableID: string, tableName: string) {
    let openFolio = undefined //await this.loadFolio(tableID)
    // is old folio, load it ->

    if (openFolio != undefined) {
      this.folio = openFolio

      //TODO move to close folio
      /*  this.folio.rows.filter(r => r.recordStatus == "new").map(fr => {
         fr.recordStatus = "old"
       }) */
      this.lockFolio()
      // is new folio, create new one ->
    } else {
      this.folio = new Folio();
      this.folio.id = this.idGen.generateMaxi();
      this.folio.type = type

      this.folio.creation = new Date();
      this.folio.lastChange = new Date();
      //?this.folio.creator.userID = this.cls.userInfo.id
      //?this.folio.creator.userName = this.cls.userInfo.name
      this.folio.table.id = tableID;
      this.folio.table.name = tableName;
      this.folio.type = "table";
      this.lockFolio()
      this.saveFolio()
    }
  }


  lockFolio() {
    this.folio.lastChange = new Date();
    //?this.folio.lock.userID = this.cls.userInfo.id
    //?this.folio.lock.userName = this.cls.userInfo.name
    this.folio.lock.status = "locked";
    this.folio.lock.time = new Date()
  }

  // Relase folio lock, convert new flagged rows to old and save folio then load open folios
  doneFolio() {
    this.folio.lock.status = "unlocked";
    //?this.folio.updaters.push({ userID: this.cls.userInfo.id, userName: this.cls.userInfo.name, time: new Date() })
    //this.folio.rows.filter(p => p.recordStatus == 'new').map(m => m.recordStatus = "old")
    return this._doneFolio()
  }

  // Relase folio lock, delete new flagged rows and save folio then load open folios
  cancelFolio() {
    return this.orwiService.serviceRequest("/api/folio/cancelFolio", this.folio, this.userService.token).toPromise()
  }

  createRow(product_id, product_name, qty, parent_id, price, image?, isGift?: boolean): FolioRow {
    isGift = isGift == undefined ? false : isGift == false ? false : true
    let row: FolioRow = new FolioRow()
    row.id = this.idGen.generate()
    row.name = product_name
    row.qty = qty
    row.unitPrice = price
    row.itemID = product_id
    row.itemImage = image
    row.parentID = parent_id
    row.recordStatus = "new"
    row.isGift = isGift
    this.pushRow(row)
    return row
  }

  pushRow(row: FolioRow) {
    //TODO refresh NS
    row.selected = false
    this.folio.rows.push(row);
  }

  saveFolio() {
    //! clear folio selection and loyalty items
    this.folio.rows.map(o => {
      o.isFree = false
      o.selected = false
    })
    return this._saveFolio()

  }

  closeFolio() {
    this._closeFolio()
  }

  getRowTotalWithSubs(row: FolioRow) {
    let total = this.getSubRowsTotal(row)
    row.price = row.qty * row.unitPrice;
    total = row.price + total
    if (row.isGift) {
      total = 0
    }
    return total;
  }

  getRowTotal(row: FolioRow) {
    let total = 0//this.getSubRowsTotal(row)
    row.price = row.qty * row.unitPrice;
    total = row.price + total
    if (row.isGift) {
      total = 0
    }
    return total;
  }

  getSubRowsTotal(row: FolioRow) {
    let filtered = this.folio.rows.filter(o => o.parentID == row.id)
    let total = filtered.reduce((sum, item) => sum + (item.unitPrice * item.qty), 0);
    for (const iterator of filtered) {
      total += this.getSubRowsTotal(iterator)
    }
    return total
  }

  getFolioTotal(rs: RecordStatus = "all", withSpendedPoints = true, withTip = false, withDiscount = true, _folio: Folio = undefined) {
    _folio = _folio ? _folio : this.folio
    let filteredRows = _folio.rows.filter(o => !o.isGift && !o.isPayment && !o.isLoyalty && o.recordStatus != 'deleted')
    if (!withDiscount) {
      filteredRows = filteredRows.filter(o => o.isDiscount == false)
    }
    if (rs !== "all") {
      filteredRows = filteredRows.filter(o => o.recordStatus == rs)
    }
    let total = filteredRows.reduce((sum, item) => sum + (item.unitPrice * item.qty), 0)


    if (withSpendedPoints) {
      total = total - _folio.orwi.spent
    }
    if (withTip) {
      total += _folio.tip
    }
    total = parseFloat(total.toFixed(2))
    return total
    // return filteredRows.reduce((sum, item) => sum + (item.unitPrice * item.qty), 0);
    //TODO:yuvarlama hatası duzeltilmeli
  }

  getUnPaidTotal() {
    return this.getFolioTotal() + this.getPaidTotal()
  }

  getPaidTotal() {
    return this.folio.rows.filter(o => !o.isGift && o.isPayment).reduce((sum, item) => sum + (item.unitPrice * item.qty), 0);
  }

  getProductCountOnFolio(productID: string) {
    let onfolio = this.folio.rows.filter(o => o.itemID == productID && o.parentID == "0" && o.recordStatus != "deleted" && o.recordStatus != "removed")
    return onfolio.reduce((sum, item) => sum + item.qty, 0)
  }

  getModifierCountOnFolio(productID, parentID, qtyID?) {

    let findedModifiersInFolio: FolioRow[]

    if (qtyID) {
      findedModifiersInFolio = this.folio.rows.filter(
        p =>
          p.parentID == parentID &&
          p.itemID == productID &&
          p.qtyID == qtyID
      );
    } else {
      findedModifiersInFolio = this.folio.rows.filter(
        p =>
          p.parentID == parentID &&
          p.itemID == productID
      );
    }

    let count = findedModifiersInFolio.reduce((sum, item) => sum + item.qty, 0);

    if (count < 0)
      count = 0

    return count;
  }

  //add payment row : discount id, discount name, discount amount
  addDiscount(id, discount, amount) {
    let row = new FolioRow()
    row.id = this.idGen.generate()
    row.itemID = id
    row.name = discount
    row.qty = 1
    row.unitPrice = amount - (amount * 2)
    row.parentID = "0"
    row.isDiscount = true
    row.rowType = "discount"
    row.couponCode = id
    row.recordStatus = "new"
    this.pushRow(row)
    //this.saveFolio()
  }

  //add payment row : payment id, payment name, pay amount
  addPayment(id, payment, amount) {
    let row = new FolioRow()
    row.id = this.idGen.generate()
    row.name = payment
    row.itemID = id
    row.qty = 1
    row.unitPrice = amount - (amount * 2)
    row.parentID = "0"
    row.isPayment = true
    row.rowType = "payment"
    this.pushRow(row)

    this.saveFolio()
  }

  cancelUnsaved() {
    for (const iterator of this.getNewRows()) {
      this.removeRow(iterator.id)
    }
  }

  //!sub-sub rows ?
  removeRow(folioRowID: string) {
    let subRows = this.folio.rows.filter(p => p.parentID == folioRowID);
    subRows.forEach(element => {
      let subFoliIndex = this.folio.rows.findIndex(sf => sf.id == element.id);
      this._removeRow(subFoliIndex)
    })
    let folioIndex = this.folio.rows.findIndex(p => p.id == folioRowID);
    this._removeRow(folioIndex)
  }

  private _removeRow(index: number) {
    if (index == undefined) return
    if (index == -1) return
    if (this.folio.rows[index].recordStatus == "new") {
      this.folio.rows.splice(index, 1);
    } else {
      this.folio.rows[index].recordStatus = "deleted"
      //!this.folio.rows[index].deleters.push({ userID: this.cls.userInfo.id, userName: this.cls.userInfo.name, time: new Date(), qty: 1, reasonID: "1", reason: "Garson Hatası" })
    }
  }

  //get active rows

  getRows(_folio: Folio = undefined) {
    _folio = _folio ? _folio : this.folio
    return _folio.rows.filter(p => p.parentID === "0" && p.recordStatus != 'deleted' && !p.isPayment && !p.isDiscount)
  }

  getNewRows(_folio: Folio = undefined) {
    _folio = _folio ? _folio : this.folio
    return _folio.rows.filter(p => p.recordStatus == "new" && p.parentID === "0" && !p.isPayment && !p.isDiscount)
  }

  getOldRows(_folio: Folio = undefined) {
    _folio = _folio ? _folio : this.folio
    return _folio.rows.filter(p => p.recordStatus == "old" && p.parentID === "0" && !p.isPayment && !p.isDiscount)
  }

  getSubRows(parentID: string) {
    let fr = this.folio.rows.filter(p => p.parentID === parentID && p.recordStatus != 'deleted' && !p.isPayment && !p.isDiscount)
    fr.sort((a, b) => a.qtyID - b.qtyID)
    return fr
  }

  //get discount rows in HTML
  getDiscountRows() {
    return this.folio.rows.filter(p => p.parentID === "0" && p.recordStatus != 'deleted' && p.isDiscount)
  }

  //get folio rows, only parentID == 0
  getPaymentRows(_folio: Folio = undefined) {
    _folio = _folio ? _folio : this.folio
    return _folio.rows.filter(p => p.parentID === "0" && p.recordStatus != 'deleted' && p.isPayment && !p.isDiscount)
  }

  //check modifiers selection 
  modifierCheck = []
  private _checkModifier(m: Modifier, qtyID) {
    let selected = m.products.filter(x => x.selected).length
    let ok = ((selected >= m.minSelect && selected <= m.maxSelect) || (m.minSelect === 0 && m.maxSelect === 0))
    if (!ok) {
      this.modifierCheck.push({ id: m.id, name: m.group, min: m.minSelect, max: m.maxSelect, qtyID: qtyID })
    }
    for (const iterator of m.products) {
      for (const it of iterator.subModifiers) {
        this._checkModifier(it, 1)
      }
    }
  }

  checkModifiers() {
    this.modifierCheck = []
    for (const _modif_groups of this.activeRow.modifierGroups) {
      for (const _modifs of _modif_groups.modifierGroup) {
        this._checkModifier(_modifs, _modif_groups.order)
      }
    }
    return this.modifierCheck
  }

  getModifiers(productID): Modifier[] {
    let prd: Product = this.storeService.store.products.find(o => o.id == productID)
    let mdf: Modifier[] = this.storeService.getModifiers(prd.id, prd.group)
    return mdf
  }

  addRemoveModifier(modifierProduct: ModifierProduct, modifier: Modifier, folioRowParentID, isDetach = false, qtyID = 1): Promise<any> {

    return new Promise((resolve, reject) => {

      let parentRow = this.folio.rows.find(o => o.id == folioRowParentID)

      let selLenght = modifier.products.filter(p => p.selected).length
      if (selLenght > modifier.maxSelect && modifier.maxSelect != 0) {
        modifierProduct.selected = false
        reject({ error: "exceeded selectin", min: modifier.minSelect, max: modifier.maxSelect, group: modifier.group })
      }


      if (modifierProduct.selected) {
        let row = this.createRow(modifierProduct.productID, modifierProduct.productName, parentRow.qty, folioRowParentID, modifierProduct.price)
        row.qtyID = qtyID
        row.isModifier = true
        if (isDetach) {
          row.isDetachableModifier = true
          row.unitPrice = row.unitPrice == 0 ? 0 : row.unitPrice - (row.unitPrice * 2)
        }
        if (modifierProduct.productID != "") {
          modifierProduct.subModifiers = this.getModifiers(modifierProduct.productID)
        }
        modifierProduct.temp_rowID = row.id
        modifierProduct.selected = true
        row.modifiers = modifierProduct.subModifiers
      }
      else {
        this.removeRow(modifierProduct.temp_rowID)
        modifierProduct.temp_rowID = ""
        modifierProduct.subModifiers = []
      }
      resolve(true)
    })
  }

  getAdvices() {
    try {


      this.storeService.store.advices.sort(function (a, b) {
        return b.order - a.order;
      });
      this.folio.rows.map(s => {
        let prd: Product = this.storeService.store.products.find(l => l.id == s.itemID)

        if (prd) {
          s["group"] = prd.group
        }
      })
      let group = undefined
      this.storeService.store.advices.forEach(o => {
        let x = this.folio.rows.find(p => p["group"] == o.group)
        if (!x) {
          group = { group: o.group, description: o.description }
        }
      })
      return group
    } catch (error) {

    }
  }

  getTips() {
    let tips = [] // %5 - %10 - %20 - %25

    tips.push({ id: 2, rate: 0.1, tip: parseFloat((this.getFolioTotal() * 0.1).toFixed(2)) })
    tips.push({ id: 1, rate: 0.15, tip: parseFloat((this.getFolioTotal() * 0.15).toFixed(2)) })
    tips.push({ id: 3, rate: 0.20, tip: parseFloat((this.getFolioTotal() * 0.2).toFixed(2)) })
    tips.push({ id: 4, rate: 0.25, tip: parseFloat((this.getFolioTotal() * 0.25).toFixed(2)) })
    return tips
  }

  grandTotalforMasterPass() {
    let total: number = this.getFolioTotal("all", true, true)
    total = total * 100
    let totals = total.toFixed(0)
    return totals
  }

  //New folio service functions

  _refreshFolio() {
    // this.oe.folioEvents.next("loading")
    this.orwiService.serviceRequest("/api/folio/refreshFolio", { id: this.folio.id }, this.userService.token).subscribe((o: any) => {
      if (o.response) {

        this.folio = Object.assign(o.response)
        this.glb.consolelog("_refreshFolio", this.folio)
        // this.oe.folioEvents.next("loaded")
        if (this.folio.status !== "open") {
          this.folio = new Folio()
          //this.nav.navigateRoot(this.glb.landingPage)
        }

      } else if (o.error) {
        this.glb.toast("RefreshFolio", o.error.desc, "top", "warning", 3000)
      }

    }, e => {
      //this._getFolio()
    })
  }

  

  _getFolio() {

    if (!this.userService.user) return;
    if (this.storeService.store.mainAction == "only-menu") return;


    let payload = {
      storeId: this.storeService.store.id,
      tableId: this.storeService.tableId,
      time: this.storeService.schedulerTime,
      //note: this.storeService.schedulerNote,
      addressId: this.storeService.addressId,
      scheduleNote: this.storeService.schedulerNote,
      source: 'orwi'
    }

    this.orwiService.serviceRequest("/api/folio/getFolio", payload, this.userService.token).subscribe((o: any) => {

      if (o.response) {
        this.folio = Object.assign(o.response);
        this.glb.consolelog("_getFolio", this.folio)
      } else if (o.error) {
        this.glb.toast(o.error.desc, "", "top", "warning")
      }

    })
  }

  _saveFolio() :Promise<boolean> {
    // this.oe.folioEvents.next("loading")
    return new Promise((resolve, reject) => {
      this.orwiService.serviceRequestPromise("/api/folio/saveFolio", this.folio, this.userService.token).then((o: any) => {

        if (o.response) {
          this.folio = Object.assign(o.response);
          // this.oe.folioEvents.next("save")
          this.glb.consolelog("_saveFolio", this.folio)
          resolve(true)
        } else if (o.error) {
          this.glb.toast(o.error.desc, "", "top", "warning")
          this._refreshFolio()
          resolve(false)
        }

      }, (e: any) => {

       resolve(false)

      })
    })
  }

  _doneFolio() : Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.orwiService.serviceRequestPromise("/api/folio/doneFolio", this.folio, this.userService.token).then((o: any) => {
        if (o.response) {
          this.folio = Object.assign(o.response);
          // this.oe.folioEvents.next("done")
          this.glb.consolelog("_doneFolio", this.folio)
          resolve(true)
        } else if (o.error) {
          this.glb.toastButton(o.error.desc, "doneFolio", "top", "warning", false, false, true)
          resolve(false)
        }
      }, (e) => {
        resolve(false)
      })
    })
  }

  _closeFolio(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.orwiService.serviceRequestPromise("/api/folio/closeFolio", this.folio, this.userService.token).then((o: any) => {

        if (o.response) {
          this.glb.consolelog("_closeFolio", this.folio)
          this.folio = Object.assign(o.response)
          this.previousFolioId = this.folio.id
          this.userService.user.openCheckIn = ""
          //this.oe.folioEvents.next("close")
          resolve(true)
        } else if (o.error) {
          this.glb.toastButton(o.error.desc, "closeFolio", "top", "warning", false, false, true)
          resolve(false)
        }

      }, (e: any) => {
        //this.oe.folioEvents.next("error")
        reject(e)
      })
    })
  }

  _lockFolio() {
    this.orwiService.serviceRequest("/api/folio/lockFolio", { folioId: "", lockStatus: false }, this.userService.token).subscribe(o => { })
  }

  clearPayment() {
    let payments = this.folio.rows.filter(o => o.rowType = "payment")
    payments.forEach(o => {
      this.removeRow(o.id)
    })
  }

  OrwiWonPoint() {
    let multipler = this.storeService.store.orwiPayPoint || 0
    let won = this.getFolioTotal("all", true, false) * multipler
    return won
  }

  orwiSpendPoint(value) {
    this.folio.orwi.spent = value
  }

  setRowQty(row: FolioRow, qty) {
    row.qty = qty
    let rows = this.folio.rows.filter(o => o.parentID == row.id)
    rows.forEach(o => {
      o.qty = row.qty
    })
  }

  _getFolioById(id): Promise<Folio> {
    return new Promise((resolve, reject) => {
      this.orwiService.serviceRequestPromise("/api/folio/getFolioById", { id: id }, this.userService.token).then((o: any) => {
        if (o.response) {
          resolve(o.response)
        } else if (o.error) {
          this.glb.consolelog("_getFolioById-error", o.error)
        }
      }, (e: any) => {
        //this.oe.folioEvents.next("error")
      })
    })
  }

  calcFolioPoint(folio: Folio) {
    return new Promise((resolve, reject) => {
      this.orwiService.serviceRequestPromise("/api/user-point/calcPointWithFolio", folio, this.userService.token).then((o: any) => {
        if (o.response) {
          resolve(o.response)
        }

      }, (e: any) => {
        //this.oe.folioEvents.next("error")
      })
    })
  }




  lastWaiterCall
  callWaiter(message = "") {

    let seconds = moment().diff(this.lastWaiterCall, "seconds")
    if (seconds < 60 && this.lastWaiterCall !== undefined) return

    this.lastWaiterCall = moment()
    this.glb.showLoading()
    this.orwiService.serviceRequestPromise("/api/folio/callWaiter", { id: this.folio.id, message: message }, this.userService.token).then(o => {
      this.glb.closeLoading()
      this.glb.toast(this.transloco.translate("Call Waiter"), this.transloco.translate("I'm noticed the waiter"), "bottom", "success")
      this.glb.closeLoading()
    })

  }

  prepareCallWaiter() {
    let message = this.transloco.translate("Do you have a message you want to convey to the waiter?")
    let title = this.transloco.translate("Call Waiter")
    let inp: promptInput = { id: "message", type: "textarea", placeholder: this.transloco.translate("Message for the waiter"), required: false }
    let inps: promptInput[] = [inp]


    let prompt_response = this.op.showComponent({ title: title, message: message, inputs: inps, closable: true })


    prompt_response.click.subscribe((response: promptResponse) => {

      if (response.button.id == "ok") {
        let msg = ""
        if (response.inputs.length > 0) {
          msg = response.inputs[0].value
        }

        this.callWaiter(msg)
        this.fb.call_waiter(this.userService.user.id,this.storeService.store.name,msg)
      }

      prompt_response.closeClick.emit()


    })
  }


  receiveFolioFromPOS(storeId, tableName): Promise<Folio> {

    return new Promise((resolve, reject) => {
      this.glb.showLoading()
      this.orwiService.serviceRequestPromise("/api/folio/reciveFolioFromPOS", { id: storeId, tableName: tableName }, this.userService.token).then((o: any) => {
        this.glb.closeLoading()
        if (o.response) {
          this.folio = o.response
          resolve(this.folio)
        } else {
          resolve(undefined)
        }
      })
    })


  }


  controlFolioFromPOS(storeId, tableName): Promise<Folio> {
    return new Promise((resolve, reject) => {
      setTimeout(() => {

        this.orwiService.serviceRequestPromise("/api/folio/controlFolioFromPOS", { id: storeId, tableName: tableName }, this.userService.token).then((o: any) => {
          if (o.response) {
            resolve(o.response)
          }
        })

      }, 500);

    })
  }


  requestCheck() {
    this.glb.showLoading("...")
    this.orwiService.serviceRequestPromise("/api/folio/requestCheck", { id: this.folio?.id, paymentType: "" }, this.userService.token).then(o => {
      this.glb.closeLoading()
      this.glb.toast(this.transloco.translate("Request Check"), this.transloco.translate("I'm noticed the store for payment."), "bottom", "success")
      this.glb.closeLoading()
    })
  }

  getOldFolios(type: 'delivery' | 'all' | 'in-store'): Promise<deliveryFolio[]> {
    return new Promise((resolve, reject) => {
      this.orwiService.serviceRequestPromise("/api/folio/getUserFolios", { filter: type, limit: 100, skip: 0 }, this.userService.token).then((o: any) => {
        this.glb.consolelog(o)
        if (o.response) {
          o.response.map(o => {
            o["showDetail"] = false
          })
          resolve(o.response)
        }
      }, e => {
        reject(false)
      })
    })
  }

}


