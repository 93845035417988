import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BelturIntroRoutingModule } from './beltur-intro-routing.module';
import { Slide1PageModule } from './components/slide1/slide1.module';
import { Slide2PageModule } from './components/slide2/slide2.module';
import { Slide3PageModule } from './components/slide3/slide3.module';
import { BelturCoffeeComponent } from './components/beltur-coffee/beltur-coffee.component';
import { CircularProgressComponent } from './components/circular-progress/circular-progress.component';
import { SharedModule } from 'src/app/helpers/shared.module';


@NgModule({
  declarations: [
    BelturCoffeeComponent,
    CircularProgressComponent,
  
  ],
  imports: [
    CommonModule,
    BelturIntroRoutingModule,
    Slide1PageModule,
    Slide2PageModule,
    Slide3PageModule,
    SharedModule,
  ], 
  exports: [
BelturCoffeeComponent,
  ]
})
export class BelturIntroModule { }
