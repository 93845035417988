import { Component, OnInit } from "@angular/core";
import { NavController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { emailIsValid } from "src/app/helpers/methods/form";
import { CustomMail } from "src/app/services/dto/orwi-user";
import { GlobalService } from "src/app/services/global.service";
import { UserService } from "src/app/services/user/user.service";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-organization-form",
  templateUrl: "./organization-form.page.html",
  styleUrls: ["./organization-form.page.scss"],
})
export class OrganizationFormPage implements OnInit {
  name = "";
  email = "";
  phone = "";
  desc = "";
  constructor(
    private nav: NavController,
    private us: UserService,
    private glb: GlobalService,
    private transloco: TranslocoService,
    private domSanit : DomSanitizer
  ) {
    if (us.user && !us.user.isVirtual) {
      this.name = this.us.user.name;
      this.email = this.us.user.email;
      this.phone = this.us.user.gsm;
    }
  }

  ngOnInit() {
  
  }
  
  safeUrl = this.domSanit.bypassSecurityTrustResourceUrl(
    "https://beltur.istanbul/tr/toplanti-ve-davetler"
  );

  async send() {
    if (!emailIsValid(this.email)) {
      this.glb.shakeInput("email");
      return;
    }

    if (this.desc.length < 5) {
      await this.glb.toast(
        this.transloco.translate("warning"),
        this.transloco.translate("You Didn't Enter Your Message"),
        "middle",
        "success"
      );
      return;
    }


    let fb: CustomMail = {
      senderName: this.name,
      senderMail: this.email,
      senderPhone: this.phone,
      content: this.desc,
      toMailList: ["musterihizmetleri@beltur.istanbul"],
      title: this.transloco.translate("Event Request Form"),
      subject: this.transloco.translate("Event Request Form"),
    };

    this.us.sendCustomMail(fb).then(async (o) => {
      await this.glb.toast(
        this.transloco.translate('Thank You'),
        this.transloco.translate('Transaction Successfull'),
        "middle",
        "success"
      );
      this.nav.pop();
    });
  }
}
