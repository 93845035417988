import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { orwiLocation, locationEvent } from '../dto/orwi-location';
import { FbService } from '../events/fb.service';
import { LocationEventsService } from '../events/location-events.service';
import { GlobalService } from '../global.service';
import { Geolocation } from '@capacitor/geolocation'


@Injectable({
  providedIn: 'root'
})
export class LocationService {


  isChanged = true
  oldLocation: orwiLocation
  currentLocation: orwiLocation
  selectedLocation: orwiLocation
  locationStatus: 'success' | 'prompt' | 'denied' | 'error' | 'unset' | 'loading' | 'custom' = "unset"
  maxDistance: number = 20

  constructor(
    private fb: FbService,
    private glb: GlobalService, private plt: Platform,
    private locationEvents: LocationEventsService, private http: HttpClient,) { }

  async getCurrentPosition(ask = false): Promise<locationEvent> {

    let str = `mobile: ${this.plt.is("mobile")},android: ${this.plt.is("android")}, desktop: ${this.plt.is("desktop")}, capacitor: ${this.plt.is("capacitor")} - ios: ${this.plt.is("ios")} - iphone: ${this.plt.is("iphone")}`
    //this.glb.toast("pl", str, "middle", "success",60000)
    this.glb.consolelog(str)
    if (this.plt.is("capacitor")) {

      if (this.plt.is("android")) {
        this.locationStatus = "loading"
        this.locationEvents.currentLocationEvent.next({ location: undefined, status: "loading", isChanged: this.isChanged })
        return this.getLocation()
      } else {
        return this.currentPositionForCapacitor(ask)
      }
    } else {
      this.locationStatus = "loading"
      this.locationEvents.currentLocationEvent.next({ location: undefined, status: "loading", isChanged: this.isChanged })
      return this.getLocation()
    }
  }


  async currentPositionForCapacitor(ask = false): Promise<locationEvent> {


    return new Promise(async (resolve, reject) => {
      this.locationStatus = "loading"
      this.locationEvents.currentLocationEvent.next({ location: undefined, status: "loading", isChanged: this.isChanged })

     
     
      const hasPermission = await  Geolocation.checkPermissions()



      if (hasPermission.location == "prompt") {

        if (ask) {

          this.getLocation().then(o => {
            resolve(o)
          }, e => {
            resolve({ location: undefined, status: "error", isChanged: this.isChanged })
          })


        } else {
          this.locationStatus = "prompt"
          this.locationEvents.currentLocationEvent.next({ location: undefined, status: "prompt", isChanged: this.isChanged })
          resolve({ location: undefined, status: "prompt", isChanged: this.isChanged })
        }

      } else if (hasPermission.location == "denied") {
        this.fb.locationRequest(false)
        this.locationStatus = "denied"
        this.locationEvents.currentLocationEvent.next({ location: undefined, status: "denied", isChanged: this.isChanged })
        resolve({ location: undefined, status: "denied", isChanged: this.isChanged })


        if (ask) {

          this.getLocation().then(o => {
            resolve(o)
          }, e => {
            resolve({ location: undefined, status: "error", isChanged: this.isChanged })
          })


        }


      } else if (hasPermission.location = "granted") {
        this.getLocation().then(o => {
          resolve(o)
        }, e => {
          reject(e)
        })
      }
    })

  }


  private async getLocation(): Promise<locationEvent> {
    return new Promise(async (resolve, reject) => {
      let coordinates
      try {


        this.oldLocation = this.currentLocation ? this.currentLocation : undefined

        coordinates = await Geolocation.getCurrentPosition();
        this.currentLocation = { longitude: coordinates.coords.longitude, latitude: coordinates.coords.latitude }
        this.locationEvents.currentLocationEvent.next({ location: this.currentLocation, status: "success", isChanged: this.isChanged })
        this.locationStatus = "success"
        this.isChanged = true

        if (this.oldLocation) {
          let km = this.distance(this.oldLocation.latitude, this.oldLocation.longitude, this.currentLocation.latitude, this.currentLocation.longitude, "K")
          if (km < 0.3) {
            this.isChanged = false
          }
        }
        this.fb.locationRequest(true)

        resolve({ location: this.currentLocation, status: "success", isChanged: this.isChanged })

      } catch (error) {
        this.fb.locationRequest(false)

        this.glb.consolelog(error)

        if (this.plt.is("capacitor")) {
          reject("error")
          this.locationStatus = "error"
          this.locationEvents.currentLocationEvent.next({ location: undefined, status: "error", error: error, isChanged: this.isChanged })

        } else {

          this.locationStatus = "denied"
          this.locationEvents.currentLocationEvent.next({ location: undefined, status: "denied", error: error, isChanged: this.isChanged })


        }

      }
    })

  }

  watchPosition() {
    const wait = Geolocation.watchPosition({}, (position, err) => {
      this.glb.consolelog("watch", position)
    })
  }

  useDefaultPosition() {

    let lng = 29.069172884826358
    let lat = 41.12125536571792

    this.selectedLocation = { latitude: lat, longitude: lng }


    this.getReverseGeoCode(lat, lng).then(o => {
      this.locationEvents.selectMapPoisition.next(o)
    })

  }

  getReverseGeoCode(lat, lng) {

    return new Promise((resolve, reject) => {
      var apiUrl = "https://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lng + "&key=AIzaSyBkTa1cYBK4hq4qNuMNmDl68Pwi6XaT87c"
      this.http.get(apiUrl).subscribe((response: any) => {
        resolve(response.results)
      }, e => {
        reject("error")
      });
    })
  }

  distance(lat1, lon1, lat2, lon2, unit = "K") {
    if ((lat1 == lat2) && (lon1 == lon2)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * lat1 / 180;
      var radlat2 = Math.PI * lat2 / 180;
      var theta = lon1 - lon2;
      var radtheta = Math.PI * theta / 180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180 / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit == "K") { dist = dist * 1.609344 }
      if (unit == "N") { dist = dist * 0.8684 }
      return dist;
    }
  }




}



