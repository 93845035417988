// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.disabled {
  filter: grayscale(100%);
  opacity: 0.5;
}`, "",{"version":3,"sources":["webpack://./src/app/components/gamification/super-orwi-point-field/super-orwi-point-field.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,YAAA;AACJ","sourcesContent":[".disabled {\n    filter: grayscale(100%);\n    opacity: 0.5;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
