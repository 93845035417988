import { ApplicationRef, Component, ComponentFactoryResolver, ComponentRef, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { FeedCategory, PaymentType, ServiceType } from 'src/app/services/dto/orwi-home';

import { FeedService } from 'src/app/services/home/feed.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {


  @Output() filterChanged = new EventEmitter<FeedCategory[]>()

  allServiceTypesSelected = true
  allCategoriesSelected = true
  allPaymentTypesSelected = true

  onlyMenu : boolean = false;


  paymentTypes: PaymentType[] = []


  constructor(public feedService :FeedService) {
    this.feedService.loadServiceTypes()
   }

  ngOnInit() {
    // this.serviceTypes.push({ id: "table", name: "Masaya Servis", selected: false })
    // this.serviceTypes.push({ id: "table", name: "Paket", selected: false })
    // this.serviceTypes.push({ id: "table", name: "Gel - Al", selected: false })
    // this.serviceTypes.push({ id: "table", name: "Hazır Masa", selected: false })
    // this.serviceTypes.push({ id: "table", name: "Rezervasyon", selected: false })

    this.paymentTypes.push({ id: "table", name: "Nakit", selected: false })
    this.paymentTypes.push({ id: "table", name: "Kredi Kartı", selected: false })
    this.paymentTypes.push({ id: "table", name: "Multinet", selected: false })
    this.paymentTypes.push({ id: "table", name: "Ticket", selected: false })



    this.feedService.categories = this.feedService.categories
    this.feedService.categories.map(o => o.selected = false)
  }

  closeModal()
  {
    this.feedService.filterModalStatus = 'close'
  }


  selectCat(item) {
    if (this.allCategoriesSelected) {
      this.toggleSelectAllCategories()
    }
    item.selected = item.selected ? false : true
  }

  changeOnlyMenu()
  {
    this.onlyMenu = this.onlyMenu ? false : true
  }

  selectServiceType(item) {
    if (this.allServiceTypesSelected) {
      this.toggleSelectAllServiceTypes()
    }
    item.selected = item.selected ? false : true
  }


  selectPaymentType(item) {
    if (this.allPaymentTypesSelected) {
      this.toggleSelectAllPaymentTypes()
    }
    item.selected = item.selected ? false : true
  }

 



  toggleSelectAllCategories() {
    let el = document.getElementById("cat-all")
    this.allCategoriesSelected = this.allCategoriesSelected ? false : true
    this.feedService.categories.map(o => o.selected = false)
    if (this.allCategoriesSelected) {
      el.classList.add("item-selected")
    } else {
      el.classList.remove("item-selected")
    }
  }

  toggleSelectAllServiceTypes() {
    let el = document.getElementById("serviceType-all")
    this.allServiceTypesSelected = this.allServiceTypesSelected ? false : true
    this.feedService.serviceTypes.map(o => o.selected = false)
    if (this.allServiceTypesSelected) {
      el.classList.add("item-selected")
    } else {
      el.classList.remove("item-selected")
    }
  }


  toggleSelectAllPaymentTypes() {
    let el = document.getElementById("paymentType-all")
    this.allPaymentTypesSelected = this.allPaymentTypesSelected ? false : true
    this.paymentTypes.map(o => o.selected = false)
    if (this.allPaymentTypesSelected) {
      el.classList.add("item-selected")
    } else {
      el.classList.remove("item-selected")
    }
  }

  appyFilter() {

    this.filterChanged.emit([])
    this.feedService.filterModalStatus = 'close'
    
    

  }

  clearFilter() {
    this.feedService.categories.map(o => o.selected = false)
    this.paymentTypes.map(o => o.selected = false)
    this.feedService.serviceTypes.map(o => o.selected = false)
    this.feedService.filterModalStatus = 'close'
  }



}
