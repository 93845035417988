import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { OrwiModalComponent } from '../../ui/orwi-modal/orwi-modal.component';
import { GoogleMapModalService } from './google-map-modal.service';

@Component({
  selector: 'app-google-map-modal',
  templateUrl: './google-map-modal.component.html',
  styleUrls: ['./google-map-modal.component.scss'],
})
export class GoogleMapModalComponent implements OnInit {

  @ViewChild('googleModal') orwiModal: OrwiModalComponent

  @Input() actionType = "select-address"

  constructor(private cl: GoogleMapModalService) { }

  ngOnInit() { }


  close() {
    return new Promise((resolve, reject) => {

    


      let comp = document.getElementById('orwi-action-' + this.orwiModal.componentId)
      comp.classList.add("animate__slideOutDown")
      setTimeout(() => {
        this.cl.destroyComponent()
        resolve(true)
      }, 1000);
    })
  }
}
