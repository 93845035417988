import { Component, OnInit } from "@angular/core";
import { NavController, Platform } from "@ionic/angular";
import { faQrcode } from "@fortawesome/free-solid-svg-icons";
import { UserService } from "src/app/services/user/user.service";
import { QrScannerEventsService } from "src/app/services/events/qr-scanner-events.service";
import { Subscription } from "rxjs";
import { StoreService } from "src/app/services/store/store.service";
import { GlobalService } from "src/app/services/global.service";
import { FbService } from "src/app/services/events/fb.service";

@Component({
  selector: "home-qr-button",
  templateUrl: "./qr-button.component.html",
  styleUrls: ["./qr-button.component.scss"],
})
export class QrButtonComponent implements OnInit {
  qrEventSubscription: Subscription;
  faQrcode = faQrcode;
  backDropEnabled = false;
  toggle = false;
  constructor(
    private glb: GlobalService,
    private qe: QrScannerEventsService,
    private plt: Platform,
    private us: UserService,
    private fb: FbService,
    private ss: StoreService,
    private nav: NavController
  ) {}

  ngOnInit() {}

  close() {
    this.buttonToggle();
  }

  buttonToggle() {
    this.toggle = !this.toggle; // this.toggle ? false : true
    this.backDropEnabled = this.toggle;
    this.translate();
    if (this.toggle) {
      this.fb.fab_button("main");
    }
  }

  myReservations()
  {
    this.nav.navigateForward('reservations')
  }

  translate() {
    let texts = document.getElementsByClassName("text");

    for (const element of texts as any) {
      element.classList.toggle("text-open");
    }
    // if (this.toggle) {
    //   setTimeout(() => {
    //     for (const index in texts) {
    //        texts[index].classList.add("text-open");
    //     }
    //     // texts[0].classList.add("text-open");
    //     // texts[1].classList.add("text-open");
    //     //texts[2].classList.add('text-open')
    //   }, 600);
    // } else {
    //   texts[0].classList.remove("text-open");
    //   texts[1].classList.remove("text-open");
    //   //texts[2].classList.remove('text-open')
    // }

    let el = document.getElementById("gray1");

    el.classList.toggle("trans1");
    // if (this.toggle) {
    //   el.classList.add("trans1");
    // } else {
    //   el.classList.remove("trans1");
    // }

    setTimeout(() => {
      let el1 = document.getElementById("gray2");
      el1.classList.toggle("trans2");

      // if (this.toggle) {
      //   el1.classList.add("trans2");
      // } else {
      //   el1.classList.remove("trans2");
      // }
    }, 200);

    setTimeout(() => {
      let el3 = document.getElementById('gray3')
      if (this.toggle) {
        el3.classList.add("trans3")
      } else {
        el3.classList.remove("trans3")
      }
    }, 400);
  }

  scanQr() {
    this.fb.fab_button("scan_qr");
    this.buttonToggle();
    setTimeout(() => {
      if (this.plt.is("capacitor")) {
        this.nav.navigateForward("scan-qr");
      } else {
        this.nav.navigateForward("scan-qr-web");
      }
    }, 600);

    if (this.qrEventSubscription) this.qrEventSubscription.unsubscribe;
    this.qrEventSubscription = this.qe.qrScannerEvent.subscribe((o) => {
      this.glb.consolelog("qrScannerEvent", "qr-button");
      if (o.status == "success") {
        this.ss.storeCheckIn(o.qrcode);
        this.qrEventSubscription.unsubscribe();
      } else {
        this.qrEventSubscription.unsubscribe();
      }
    });
  }

  showQr() {
    this.fb.fab_button("show_qr");
    this.buttonToggle();
    this.us.checkActiveUser((allreadyLoggedIn) => {
      this.nav.navigateForward("my-code", {
        replaceUrl: !allreadyLoggedIn,
      });
    });
  }

  haveCoupon() {
    this.fb.fab_button("have_coupon");
    this.buttonToggle();
    this.us.checkActiveUser((allreadyLoggedIn) => {
      this.nav.navigateForward("my-gifts", {
        replaceUrl: !allreadyLoggedIn,
      });
    });
  }
}
