import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { LocalizedDatePipe } from './pipes/localized-date.pipe';
import { UniqueStorePipe } from './pipes/unique-store.pipe';
import { ElementVisibleDirective } from './directives/element-visible.directive';
import { DeferLoadDirective } from './directives/defer-load.directive';
import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { QRCodeModule } from 'angularx-qrcode';
//import { ZXingScannerModule } from '@zxing/ngx-scanner';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    UniqueStorePipe,
    ArrayFilterPipe,
    ElementVisibleDirective,
    DeferLoadDirective,
  ],
  exports: [
    FormsModule,
    TranslocoModule,
    FontAwesomeModule,
    UniqueStorePipe,
    ElementVisibleDirective,
    DeferLoadDirective,
    ArrayFilterPipe,
    QRCodeModule,
    //ZXingScannerModule
  ]
})
export class SharedModule { }