import { Component, OnInit } from '@angular/core';
import { UIStore } from 'src/app/state/ui/ui.store';

@Component({
  selector: 'orwi-contact-button',
  templateUrl: './orwi-contact-button.component.html',
  styleUrls: ['./orwi-contact-button.component.scss'],
})
export class OrwiContactButtonComponent implements OnInit {

  constructor(private uiStore: UIStore) { }

  ngOnInit() {}

  contact() {
    this.uiStore.update({orwiContactVisible: true})
  }

}
