import { Injectable } from "@angular/core";
import { readyException } from "jquery";
import { Feed, FeedCategory, ServiceType } from "../dto/orwi-home";
import { orwiLocation } from "../dto/orwi-location";
import { FolioService } from "../folio/folio.service";
import { GlobalService } from "../global.service";
import { OrwiService } from "../orwi.service";
import { UserService } from "../user/user.service";

@Injectable({
  providedIn: "root",
})
export class FeedService {
  activeMode: modeValues = "avrupa";
  activeFeed: Feed;
  filterModalStatus: "open" | "close" = "close";
  categories: FeedCategory[] = [];
  feeds: Feed[];
  filteredFeeds: Feed[];
  serviceTypes: ServiceType[] = [];
  searchText = "";
  feedLoaded = false;

  constructor(
    private fs: FolioService,
    private os: OrwiService,
    private us: UserService,
    private glb: GlobalService
  ) {
    this.getCatagories();
  }

  getFeed(): Promise<Feed[]> {
    this.feedLoaded = false;
    return new Promise((resolve, reject) => {
      let body = { parentId: "61757166bcd20b2a905f910b" };
      this.os
        .serviceRequestPromise("/api/store/getChildStores", body, this.us.token)
        .then(
          (o: any) => {
            if (o.response) {
              this.filteredFeeds = this.feeds = o.response;
              resolve(this.filteredFeeds);
            }
            this.feedLoaded = true;
          },
          (err) => {
            this.feedLoaded = false;
          }
        );
    });
  }

  getCatagories(): Promise<FeedCategory[]> {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequestPromise("/api/category/getCategories", {}, this.us.token)
        .then((o: any) => {
          if (o.response) {
            this.categories = o.response;           

            this.categories = this.categories.filter(o => o.id !== 'avrupa' && o.id !== 'anadolu')
      
            resolve(this.categories);
          }
        });
    });
  }

  loadServiceTypes() {
    this.serviceTypes.push({
      id: "table",
      name: "Masaya Servis",
      selected: false,
    });
    this.serviceTypes.push({ id: "delivery", name: "Paket", selected: false });
    this.serviceTypes.push({
      id: "self-ordering",
      name: "Gel - Al",
      selected: false,
    });
    this.serviceTypes.push({
      id: "timed-table",
      name: "Hazır Masa",
      selected: false,
    });
    // this.serviceTypes.push({ id: "table", name: "Rezervasyon", selected: false })
  }

  initCats() {
    let cats: any[] = [];
    let selectedCategories = this.categories.filter((o) => o.selected);
    let filteredItems: Feed[] = [];
console.log('initCats',selectedCategories)
    for (const iterator of selectedCategories) {
      cats.push(iterator.id);
    }
    filteredItems = this.feeds
      .filter((el) => el.isVisible)     

    // console.log(cats);
    if (cats.length > 0) {
      filteredItems = filteredItems.filter((filteredItem) =>
        filteredItem.subCategories.find((subCategory) => {
          return (
            cats.findIndex(
              (selectedCategory) => selectedCategory == subCategory
            ) > -1
          );
        })
      );
    }

    if (this.searchText) {
      console.log(this.searchText, this.searchText.toLocaleLowerCase());
      filteredItems = filteredItems.filter((feed) => {
        return feed.name
          .toLocaleLowerCase()
          .includes(this.searchText.toLocaleLowerCase());
      });
    }
   

    return filteredItems.sort((a,b)=> a.range - b.range);
  }

  filterFeedByCats(items: Feed[]) {
    const cats: any[] = [];
    const selectedCategories = this.categories.filter((o) => o.selected);
    let filteredItems: Feed[];

    for (const iterator of selectedCategories) {
      cats.push(iterator.id);
    }

    filteredItems = items
      .filter((el) => el.isVisible)     

    if (cats.length > 0) {
      filteredItems = filteredItems.filter(
        (filteredItem) =>
          filteredItem.subCategories.find((o) => {
            return cats.findIndex((l) => l == o) > -1;
          })
      );
    }

    return filteredItems;
  }
}

export type modeValues = "avrupa" | "anadolu";
