import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { NavController } from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { Feed } from "src/app/services/dto/orwi-home";
import { FbService } from "src/app/services/events/fb.service";
import { GlobalService } from "src/app/services/global.service";
import { FeedService } from "src/app/services/home/feed.service";
import { LocationService } from "src/app/services/location/location.service";
import { StoreService } from "src/app/services/store/store.service";
import { UserService } from "src/app/services/user/user.service";

@Component({
  selector: "home-feed-card",
  templateUrl: "./feed-card.component.html",
  styleUrls: ["./feed-card.component.scss"],
})
export class FeedCardComponent implements OnInit,AfterViewInit {
  @Input() feed: Feed;

  @ViewChild("heartElem", { static: false }) heartElem: ElementRef<HTMLElement>;

  constructor(
    private fb: FbService,
    public us: UserService,
    private renderer: Renderer2,
    private transloco: TranslocoService,
    private nav: NavController,
    public storeService: StoreService,
    public feedService: FeedService,
    public glb: GlobalService,
    private ls: LocationService
  ) {}

  feedImage = undefined;

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.initMyPlace(this.feed.id)
  }

  getImage() {
    this.storeService.getStoreBanners(this.feed.id).then((res: any) => {
      if (res && res.length > 0) {
        this.feedImage = res[0].base64 || this.glb.tempBanner;
      } else {
        this.feedImage = this.glb.tempBanner;
      }
    });
  }

  toggle = false;
  storeClick() {
    this.feedService.activeFeed = this.feed;
    this.storeService.activeStoreId = this.feed.id;

    this.fb.select_feed(this.feed);

    console.log("select_feed");
    this.nav.navigateForward("store-home");
  }

  range() {
    if (this.ls.currentLocation) {
      let r = this.ls.distance(
        this.ls.currentLocation.latitude,
        this.ls.currentLocation.longitude,
        this.feed.geoLocation.latitude,
        this.feed.geoLocation.longitude,
        "K"
      );
      return r.toFixed(2) + " KM";
    } else {
      return "";
    }
  }

  togglee(feedId: any) {
    // let comp = document.getElementById(id);
    if (!this.heartElem) return;
    if (this.heartElem.nativeElement.classList.contains("heart-filter")) {
      this.storeService.deleteMyPlace(feedId).then((x) => {
        this.heartElem.nativeElement.classList.remove("heart-filter");
        this.heartElem.nativeElement.classList.remove("liked");
        this.glb.toast(
          "",
          this.transloco.translate("Removed from Favorites"),
          "bottom",
          "dark"
        );
      });
      return;
    }

    this.storeService.saveMyPlace(feedId).then((x) => {
      this.heartElem.nativeElement.classList.add("liked");
      this.heartElem.nativeElement.classList.add("heart-filter");
      this.glb.toast(
        "",
        this.transloco.translate("Added to favourites"),
        "bottom",
        "dark"
      );
      this.heartElem.nativeElement.classList.remove("liked");
    });
  }

  initMyPlace(feedId) {
    // let comp = document.getElementById(feedId);
    // console.log(comp);
    if (!this.heartElem) return;

    if (
      this.storeService.myPlace.filter((x) => x.storeId === feedId).length > 0
    ) {
      this.heartElem.nativeElement.classList.add("liked");
      this.heartElem.nativeElement.classList.add("heart-filter");
      // this.togglee(feedId)
    }
  }

  getHours() {
    return (
      this.feed.workingHours[0].open + "-" + this.feed.workingHours[0].close
    );
  }

  closed() {
    return this.feed.workingHours[0].closed;
  }

  getPointTitle() {
    return this.feed.pointTitle || this.transloco.translate("Store Point");
  }

  getUserPoint() {
    return this.us.getStorePoint(this.feed.id, this.feed.parentId);
  }

  showTip(e, f: Feed, type) {
    e.preventDefault();
    e.stopPropagation();

    let el = document.getElementById(type + "-" + f.id);
    let tip = document.getElementById("detail-tip");
    let rect = el.getBoundingClientRect();
    tip.innerHTML = this.getPropertyDec(type);

    this.renderer.setStyle(tip, "top", rect.top + 30 + "px");
    this.renderer.setStyle(tip, "left", rect.left + 15 + "px");
    this.renderer.setStyle(tip, "visibility", "visible");

    setTimeout(() => {
      this.renderer.setStyle(tip, "visibility", "hidden");
    }, 5000);
  }

  getPropertyDec(type) {
    let delivery = this.transloco.translate("Home Delivery");
    let payment = this.transloco.translate("Online Payment");
    let order = this.transloco.translate("Order at Table");
    let loyalty = this.transloco.translate("Earn Store Money & Gifts");
    let reserve = this.transloco.translate("Table Reservation");
    let menu = this.transloco.translate("Digital Menu");

    if (type == "delivery") return delivery;
    if (type == "payment") return payment;
    if (type == "order") return order;
    if (type == "loyalty") return loyalty;
    if (type == "reserve") return reserve;
    if (type == "menu") return menu;
  }
}
