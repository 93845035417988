import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.scss'],
})
export class WhatsappButtonComponent  implements OnInit {
countryCode : string = "90"
whatsappNumber :string = "4446644"
url :string = "https://wa.me/"+this.countryCode+this.whatsappNumber
  constructor() { }

  ngOnInit() {}

}
