import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';
import { FeedCategory } from 'src/app/services/dto/orwi-home';
import { FbService } from 'src/app/services/events/fb.service';
import { FeedService } from 'src/app/services/home/feed.service';

@Component({
  selector: 'home-feed-categories',
  templateUrl: './feed-categories.component.html',
  styleUrls: ['./feed-categories.component.scss'],
})
export class FeedCategoriesComponent implements OnInit {

  @Output() selectionChanged = new EventEmitter<FeedCategory[]>()

  cats: FeedCategory[] = []

  constructor(
    private fb: FbService,
    private nav: NavController,
    public fs: FeedService) {

    this.fs.getCatagories().then(o => {
      this.fs.categories = o

      this.cats = this.fs.categories.filter(o => o.id !== 'avrupa' && o.id !== 'anadolu')


    })





  }

  ngOnInit() {



  }

  getColor(catID) {
    //buyukada,bufe,fuaye,beach
    switch (catID) {
      case "restaurant":
        return "#d47d58";
      case "buyukada":
        return "#d47d58";
      case "bufe":
        return "#d47d58";
      case "fuaye":
        return "#e7303a";
      case "burger":
        return "#e7303a";
      case "beach":
        return "#00b8d4";
      case "cafe":
        return "#ffd21f";
      case "cafe-plus":
        return "#ffd21f";
      case "mini":
        return "#ffd21f";
      case "ice-cream":
        return "#8f1745";
      default:
        return "#000000";
    }
  }

  selectCat(item: FeedCategory) {
    if (this.fs.categories.find(o => o.id == item.id).selected) {
      this.clearFilter();
      return;
    }
    this.clearFilter();
    this.fb.categoryFilter(item.id)
    this.fb.category(item.name)
    item.selected = item.selected ? false : true
    let el = document.getElementById("cat-" + item.id)
    if (el) {
      el.classList.add("animate__flash")
      setTimeout(() => {
        el.classList.remove("animate__flash")
      }, 1000);
    }

    this.fs.categories.find(o => o.id == item.id).selected = item.selected

    this.selectionChanged.emit([])

  }


  showAllCategories() {
    this.nav.navigateForward("select-cat")
  }

  showFilterClearButton() {
    return this.fs.categories.filter(o => o.selected).length > 0
  }

  clearFilter() {
    this.fs.categories.map(o => o.selected = false)
    this.fs.categories.map(o => o.selected = false)
    this.selectionChanged.emit([])
  }



}
