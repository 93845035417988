import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'orwi-closer',
  templateUrl: './closer.component.html',
  styleUrls: ['./closer.component.scss'],
})
export class CloserComponent implements OnInit {

  @Input() type : 'back' | 'close' = "close"
  @Output() closing = new EventEmitter()

  constructor(private nav: NavController) { }

  ngOnInit() {


   
   }


   ngAfterViewInit() {
    var style = getComputedStyle(document.body);
    let safeAreaStr = style.getPropertyValue('--ion-safe-area-top')
    safeAreaStr = safeAreaStr.replace("px", "")
    let safe = parseInt(safeAreaStr)

    let clsr = document.getElementById("closer")
    clsr.style.top = safe + "px"

   }


  close() {
    this.closing.emit("")
    if (this.closing.observers.length == 0) {
      this.nav.pop()
    }
  }
}
