import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { ToastController } from '@ionic/angular';
import { SoketPushDeeplinkRoutingService } from './soket-push-deeplink-routing.service';
import { GlobalService } from '../global.service';

@Injectable({
  providedIn: 'root'
})
export class SocketService {


  message = '';
  messages = [];
  currentUser = '';
  connected = false

  constructor(
    public socket: Socket,
    private glb: GlobalService,
    //private spdr: SoketPushDeeplinkRoutingService,
    private toastCtrl: ToastController) {




  }


  initEvents(userToken) {

    this.socket.fromEvent("folio-changed").subscribe(o => {
      //this.route({ type: "folio-changed", socketData: o })
      console.log("folio-changed",o)
    })

    this.socket.fromEvent("folio-closed").subscribe(o => {
      // this.route({ type: "folio-closed", socketData: o })
      console.log("folio-closed",o)
    })

    this.socket.fromEvent("folio-received").subscribe(o => {
      // this.route({ type: "folio-received", socketData: o })
      console.log("folio-received",o)
    })

    this.socket.fromEvent("store-menu-changed").subscribe(o => {
      // this.route({ type: "store-menu-changed", socketData: o })
      console.log("store-menu-changed",o)
    })

    this.socket.fromEvent("new-user-message").subscribe(o => {
      // this.route({ type: "new-user-message", socketData: o })
      console.log("new-user-message",o)
    })

    this.socket.fromEvent("user-points-changed").subscribe(o => {
      //this.route({ type: "user-points-changed", socketData: o })
      console.log("user-points",o)
    })

    this.socket.fromEvent("login").subscribe(o => {
      console.log("login", o, this.socket)
    })

    this.socket.fromEvent("connect").subscribe(o => {
      console.log("scoket.connect", o)
      this.connected = true
    })

    this.socket.fromEvent("welcome").subscribe(o => {
      console.log("welcome",o)
      this.socket.emit("login", userToken)
    })



  }

  async showToast(msg) {
    let toast = await this.toastCtrl.create({
      message: msg,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }
}
