import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { UIState, UIStore } from './ui.store';

@Injectable({ providedIn: 'root' })
export class UIQuery extends Query<UIState> {


    orwiContactVisible$ = this.select("orwiContactVisible")
    orwiContactClose$ = this.select("orwiContactClose")
    attachFolio = this.select("attachFolio")
    orwiScratch = this.select("scratch")
    rejectedAttachFolioId = this.select("rejectedAttachFolioId")
    
    

    constructor(protected store: UIStore) {
        super(store);
    }

}

