import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocationStatus } from 'src/app/services/dto/orwi-location';
import { LocationEventsService } from 'src/app/services/events/location-events.service';
import { GlobalService } from 'src/app/services/global.service';
import { AddressService } from 'src/app/services/location/address.service';
import { LocationService } from 'src/app/services/location/location.service';
import { GoogleMapModalService } from '../google-map-modal/google-map-modal.service';


@Component({
  selector: 'orwi-location-changer',
  templateUrl: './location-changer.component.html',
  styleUrls: ['./location-changer.component.scss'],
})
export class LocationChangerComponent implements OnInit {

  location = "*"
  locationDesc = ""
  locationStatus : LocationStatus = "unset"

  addressSubscriber: Subscription
  locationSubscriber: Subscription

  constructor(
    private chRef: ChangeDetectorRef,
    private glb: GlobalService,
    private le: LocationEventsService,
    private componentLoader: GoogleMapModalService,
    private ls: LocationService,
    private ads: AddressService) { }


  ngOnDestroy() {
    this.addressSubscriber.unsubscribe()
  }

  ngOnInit() {

    if (this.ls.locationStatus == "success") {
      this.initLocation()
    } else {
      this.initLocation()
    }

    if (this.locationSubscriber && !this.locationSubscriber.closed) this.locationSubscriber.unsubscribe()
    if (this.addressSubscriber && !this.addressSubscriber.closed) this.addressSubscriber.unsubscribe()

    this.locationSubscriber = this.le.currentLocationEvent.subscribe(o => {

      this.locationStatus = o.status

      if (o.status == "success") {
        if (o.isChanged || this.location == "*") this.getLocationDesc()
      } else if (o.status == "loading") {
        
      } else if (o.status == "error") {
        
      } else if (o.status == "denied") {
        
      } else if (o.status == "prompt") {
       
      } else if (o.status == "unset") {
        
      } else if (o.status == "custom") {

      }

      this.chRef.detectChanges()

    })

    this.addressSubscriber = this.le.selectMapPoisition.subscribe(o => {
      this.locationStatus = this.ls.locationStatus
      let adr = o[0]
      let adrC: adrComp[] = adr.address_components
      let country = adrC.find(o => o.types.find(x => x == "country"))?.short_name
      let city = adrC.find(o => o.types.find(x => x == "administrative_area_level_1"))?.long_name
      let county = adrC.find(o => o.types.find(x => x == "administrative_area_level_2"))?.long_name
      let c1 = adrC.find(o => o.types.find(x => x == "administrative_area_level_3"))?.long_name
      let c2 = adrC.find(o => o.types.find(x => x == "administrative_area_level_4"))?.long_name
      this.location = county + "," + city + " - " + country
      this.chRef.detectChanges()
    })

  }

  getLocationDesc() {
    this.ads.getReverseGeoCode(this.ls.currentLocation.latitude, this.ls.currentLocation.longitude).then(o => {
      this.glb.consolelog("reverse")
      let adr = o[0]
      let adrC: adrComp[] = adr.address_components
      let country = adrC.find(o => o.types.find(x => x == "country"))?.short_name
      let city = adrC.find(o => o.types.find(x => x == "administrative_area_level_1"))?.long_name
      let county = adrC.find(o => o.types.find(x => x == "administrative_area_level_2"))?.long_name
      let c1 = adrC.find(o => o.types.find(x => x == "administrative_area_level_3"))?.long_name
      let c2 = adrC.find(o => o.types.find(x => x == "administrative_area_level_4"))?.long_name
      this.location = county + "," + city + " - " + country
      this.chRef.detectChanges()
    })
  }

  initLocation() {
    this.location = "*"
    this.locationStatus = "unset"
    this.ls.getCurrentPosition()
  }

  getLocation() {
    this.location = "*"
    this.ls.getCurrentPosition().then(o => {
    }, e => {
      this.location = e 
    })
  }

  openMap() {
    let lci = document.getElementById("lc-inner")
    lci.classList.add("animate__flash")
    setTimeout(() => {
      lci.classList.remove("animate__flash")
    }, 1000);

    this.componentLoader.showComponent()
  }

}

class adrComp {
  long_name = ""
  short_name = ""
  types = []
}