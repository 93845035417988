import { Component, Input, OnInit } from '@angular/core';
import { EntityUIQuery } from '@datorama/akita';
import { TranslocoService } from '@ngneat/transloco';
import { IFeedBack } from 'src/app/services/dto/orwi-user';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';
import { UIQuery } from 'src/app/state/ui/ui.query';
import { UIStore } from 'src/app/state/ui/ui.store';

@Component({
  selector: 'orwi-contact',
  templateUrl: './orwi-contact.component.html',
  styleUrls: ['./orwi-contact.component.scss'],
})
export class OrwiContactComponent implements OnInit {

  name = ""
  email = ""
  gsm = ""
  message = ""
  inputValue
  mode = ""
  @Input() backDropEnabled = true


  // @Output() valueEmit = new EventEmitter<{ button: promptButton, inputs?: promptInput[] }>()


  constructor(
    public us: UserService,
    public uiQuery: UIQuery, private uiStore: UIStore,
    private transloco: TranslocoService,
    private glb: GlobalService,private storeService :StoreService) {

  /*   this.uiQuery.orwiContactVisible$.subscribe(o => {
      if (o == true) {
        setTimeout(() => {
          this.show()
        }, 100);
      }
    }) */

    this.uiQuery.orwiContactClose$.subscribe(o => {
      this.closeAntimate().then(o => {
        if (o) this.uiStore.update({ orwiContactVisible: false, orwiContactClose: false })
      })
    })



  }

  ngOnInit() {

    if (this.us.user && !this.us.user.isVirtual) {
      this.name = this.us.user.name
      this.email = this.us.user.email
      this.gsm = this.us.user.gsm
    }

  }


  show() {
    let comp = document.getElementById('orwi-contact')
    comp.style.opacity = "1"
    comp.classList.add("animate__bounceInUp")

    setTimeout(() => {
      comp.classList.remove("animate__bounceInUp")
    }, 1000);
  }

  ngAfterViewInit() {


  }

  closeAntimate() {
    return new Promise((resolve, reject) => {
      let comp = document.getElementById('orwi-contact')
      if (!comp) return
      comp.classList.add("animate__bounceOutDown")
      setTimeout(() => {
        comp.classList.remove("animate__bounceOutDown")
        resolve(true)
      }, 500);
    })
  }


  closeButtonClick() {
    this.uiStore.update({ orwiContactClose: true })
  }


  save() {
    if (this.name.length <= 2 && (this.gsm.length <= 8 || this.email.length <= 5)) {
      this.glb.shakeInput("inputs")
      return
    }

    if (this.message.length <= 10) {
      this.glb.shakeInput("contact-message")
      return
    }

    if (this.mode == "") {
      this.glb.shakeInput("radio-buttons")
      return
    }

    let fb: IFeedBack = {
      id : '',
      name: this.name,
      phone: this.gsm,
      email: this.email,
      message: this.message,      
      status:this.mode,
      userId : this.us.user.id,
      feedbackCats : [],
      storeId : "61757166bcd20b2a905f910b"
    }

    this.glb.showLoading()
    this.us.saveFeedBack(fb).then(o => {
      this.glb.closeLoading()
      this.closeButtonClick()
    })

  }


}
