import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QrScannerEventsService {


  qrScannerEvent = new Subject<iQrScanner>()

  constructor() { }
}

export interface iQrScanner {
  status: 'success' | 'cancel' | 'error'
  qrcode : string
}