import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { parse } from '@fortawesome/fontawesome-svg-core';
import { TranslocoService } from '@ngneat/transloco';
import { OrwiCurrencyPipe } from 'src/app/helpers/pipes/orwi-currency.pipe';
import { Coupon } from 'src/app/services/dto/orwi-store';
import { FolioService } from 'src/app/services/folio/folio.service';
import { GlobalService } from 'src/app/services/global.service';
import { CouponService } from 'src/app/services/store/coupon.service';
import { StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'store-point-field',
  templateUrl: './orwi-point-field.component.html',
  styleUrls: ['./orwi-point-field.component.scss'],
})
export class OrwiPointFieldComponent implements OnInit {

  // getStorePoint 0 ise
  internalDisabled = false

  @Input() disabled = false
  loading = false
  @Output() value_changed = new EventEmitter<number>()

  applyValue = ""
  value = ""
  constructor(
    private transloco: TranslocoService,
    private glb: GlobalService,
    private ss: StoreService,
    private us: UserService,
    private fs: FolioService,
    private cs: CouponService) { }

  ngOnInit() {

    this.internalDisabled = (this.getStorePoint()  == 0)

   }


  getPlaceHolder() {

    let point = this.getStorePoint() 
    return point.toFixed(2) + " " + (this.ss.store.pointTitle || this.transloco.translate('Store Money'))

  }

  getStorePoint() {
    if (this.us.user && !this.us.user.isVirtual) {
      
      let st = this.us.user.orwiPoints.find(o => (o.storeId == this.ss.activeStoreId || o.storeId == this.ss.store.parentId) && o.pointType == "store")

      if (st) {
        return st.balance || 0
      } else {
        return 0
      }
    } else {
      return 0
    }
  }


  applyOrwiPoint() {

    if (this.disabled || this.internalDisabled) return

    if (this.value == "") {
      this.value = this.applyValue = this.usePoint().toFixed(2)
    } else {
      this.applyValue = this.value
    }

    if (parseFloat(this.applyValue) > this.getStorePoint()) this.applyValue = this.value = this.usePoint().toFixed(2)


    if (parseFloat(this.applyValue) > this.fs.getFolioTotal('all')) this.applyValue = this.value = this.fs.getFolioTotal('all').toFixed(2)


    this.value_changed.emit(parseFloat(this.applyValue))
  }



  parseError(error) {

    this.glb.toast(this.transloco.translate("Error"), error.desc, "bottom", "warning")

  }



  clearInput() {
    this.value = this.applyValue = ""
    this.value_changed.emit(0)
  }


  focus() {
    /*  if (this.value == "") {
       this.value = this.usePoint().toFixed(2)
     } */
  }

  blur() {

    //if (this.applyValue == "") this.value = ""
  }




  usePoint(_point = 0) {
    let point = this.getStorePoint()
    let total = this.fs.getFolioTotal('all', false, true)

    if (_point > point) {
      _point = point
    }

    if (parseFloat(_point.toString()) != 0) {
      point = parseFloat(_point.toString())
    } else if (parseFloat(_point.toString()) < 0) {
      point = this.getStorePoint()
    }
    return point
    //this.fs.folio.orwi.spent = point > total ? total : point
    //this.fs.saveFolio()
  }

}
