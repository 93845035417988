// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo {
  color: red;
}

.progress {
  transform: rotate(-90deg);
  --from-value: 110;
}

.progress__meter,
.progress__value {
  fill: none;
}

.progress__meter {
  stroke: white !important;
}

.progress__value {
  stroke: #d06740 !important;
  stroke-linecap: round;
}

.anim {
  animation: progress 2s alternate;
}

@keyframes progress {
  from {
    stroke-dashoffset: var(--from-value);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/modules/beltur-intro/components/circular-progress/circular-progress.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;AACF;;AAEA;EACE,yBAAA;EACA,iBAAA;AACF;;AAEA;;EAEE,UAAA;AACF;;AAEA;EACE,wBAAA;AACF;;AAEA;EACE,0BAAA;EACA,qBAAA;AACF;;AAEA;EACE,gCAAA;AACF;;AAEA;EACE;IACE,oCAAA;EACF;AACF","sourcesContent":[".demo {\n  color: red;\n}\n\n.progress {\n  transform: rotate(-90deg);\n  --from-value: 110;\n}\n\n.progress__meter,\n.progress__value {\n  fill: none;\n}\n\n.progress__meter {\n  stroke: white !important;\n}\n\n.progress__value {\n  stroke: #d06740 !important;\n  stroke-linecap: round;\n}\n\n.anim {\n  animation: progress 2s alternate;\n}\n\n@keyframes progress {\n  from {\n    stroke-dashoffset: var(--from-value);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
