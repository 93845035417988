// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content {
  height: 80vh;
  margin-top: 10vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  width: 95vw;
  margin-left: 2.5vw;
}

.banner {
  font-family: "Inter";
  font-weight: 500;
  font-size: 24px;
  margin-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/beltur-intro/components/slide1/slide1.page.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,6BAAA;EACA,qBAAA;EACA,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,oBAAA;EACA,gBAAA;EACA,eAAA;EACA,gBAAA;AACF","sourcesContent":[".content {\n  height: 80vh;\n  margin-top: 10vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-evenly;\n  align-items: flex-end;\n  width: 95vw;\n  margin-left: 2.5vw;\n}\n\n.banner {\n  font-family: \"Inter\";\n  font-weight: 500;\n  font-size: 24px;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
