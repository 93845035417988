// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cats {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  overflow-y: hidden;
  color: var(--ion-text-color);
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  scroll-snap-points-x: repeat(300px);
}

.cat-item {
  min-height: 80px;
  /* width: 150px !important; */
  margin: 10px;
  padding: 10px;
  min-width: 80px;
  scroll-snap-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  box-shadow: var(--orwi-shadow);
  text-align: center;
  flex-direction: column;
  font-size: 10px;
}

.cat-selected {
  background-color: #D06740;
  color: var(--ion-color-primary-contrast);
  border-bottom-color: transparent !important;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home/feed-categories/feed-categories.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,4BAAA;EAEA,uBAAA;EACA,6BAAA;EACA,mCAAA;AAAF;;AAGA;EACE,gBAAA;EACA,6BAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;EACA,8BAAA;EACA,kBAAA;EACA,sBAAA;EACA,eAAA;AAAF;;AAGA;EACE,yBAAA;EACA,wCAAA;EACA,2CAAA;AAAF","sourcesContent":[".cats {\n  display: flex;\n  align-items: center;\n  overflow-x: scroll;\n  overflow-y: hidden;\n  color: var(--ion-text-color);\n\n  scroll-behavior: smooth;\n  scroll-snap-type: x mandatory;\n  scroll-snap-points-x: repeat(300px);\n}\n\n.cat-item {\n  min-height: 80px;\n  /* width: 150px !important; */\n  margin: 10px;\n  padding: 10px;\n  min-width: 80px;\n  scroll-snap-align: center;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 10px;\n  box-shadow: var(--orwi-shadow);\n  text-align: center;\n  flex-direction: column;\n  font-size: 10px;\n}\n\n.cat-selected {\n  background-color: #D06740;\n  color: var(--ion-color-primary-contrast);\n  border-bottom-color: transparent!important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
