import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { OrganizationFormPageRoutingModule } from './organization-form-routing.module';

import { OrganizationFormPage } from './organization-form.page';
import { ComponentsModule } from 'src/app/components/components.module';
import {TranslocoModule} from "@ngneat/transloco";

@NgModule({
    imports: [
        ComponentsModule,
        CommonModule,
        FormsModule,
        IonicModule,
        OrganizationFormPageRoutingModule,
        TranslocoModule
    ],
  declarations: [OrganizationFormPage]
})
export class OrganizationFormPageModule {}
