// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.open {
  color: green;
}

.close {
  color: red;
}

.menu-text {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 13px;
  color: #615b5b;
}

.show-menu {
  min-width: 140px;
  display: flex;
  justify-content: flex-end;
  text-align: right;
}

@media (prefers-color-scheme: dark) {
  .open {
    color: limegreen;
  }
  .close {
    color: pink;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/home/store-status/store-status.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;AACF;;AAEA;EACE,UAAA;AACF;;AAEA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,cAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,yBAAA;EACA,iBAAA;AACF;;AAEA;EACE;IACE,gBAAA;EACF;EAEA;IACE,WAAA;EAAF;AACF","sourcesContent":[".open {\n  color: green;\n}\n\n.close {\n  color: red;\n}\n\n.menu-text {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  font-weight: bold;\n  font-size: 13px;\n  color: #615b5b;\n}\n\n.show-menu {\n  min-width: 140px;\n  display: flex;\n  justify-content: flex-end;\n  text-align: right;\n}\n\n@media (prefers-color-scheme: dark) {\n  .open {\n    color: limegreen;\n  }\n\n  .close {\n    color: pink;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
