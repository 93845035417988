// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orwi-header {
  transition: all 0.3s ease 0s;
  z-index: 101;
  position: fixed;
  top: 0;
  height: 44px;
  display: flex;
  box-shadow: var(--orwi-shadow);
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: white;
}

.orwi-user {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: var(--ion-safe-area-top);
  z-index: 101;
}

.orwi-user-buttons {
  display: flex;
  font-size: 24px;
  color: var(--ion-color-primary);
  margin-right: 10px;
  width: 120px;
  justify-content: space-around;
}

.orwi-user-login-button {
  font-weight: bold;
}

.orwi-user-name {
  flex-grow: 2;
  font-weight: bold;
  font-size: 14px;
  padding-left: 15px;
}

.icon-badge {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: var(--ion-color-primary);
  border-radius: 50%;
  bottom: 2px;
  right: 0px;
}

.welcome-text {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home/header/header.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,YAAA;EACA,eAAA;EACA,MAAA;EACA,YAAA;EACA,aAAA;EACA,8BAAA;EACA,sBAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,uBAAA;AACJ;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,WAAA;EACA,8BAAA;EACA,oCAAA;EACA,YAAA;AAAJ;;AAGA;EACI,aAAA;EACA,eAAA;EACA,+BAAA;EACA,kBAAA;EACA,YAAA;EACA,6BAAA;AAAJ;;AAGA;EAEI,iBAAA;AADJ;;AAKA;EACI,YAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;AAFJ;;AAKA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,0CAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;AAFJ;;AAKA;EACI,WAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;AAFJ","sourcesContent":[".orwi-header {\n    transition: all 0.3s ease 0s;\n    z-index: 101;\n    position: fixed;\n    top: 0;\n    height: 44px;\n    display: flex;\n    box-shadow: var(--orwi-shadow);\n    flex-direction: column;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    background-color: white;\n    //border-radius: 00px 00px 20px 20px;\n}\n\n.orwi-user {\n    display: flex;\n    align-items: center;\n    width: 100%;\n    justify-content: space-between;\n    margin-top: var(--ion-safe-area-top);\n    z-index: 101;\n}\n\n.orwi-user-buttons {\n    display: flex;\n    font-size: 24px;\n    color: var(--ion-color-primary);\n    margin-right: 10px;\n    width: 120px;\n    justify-content: space-around;\n}\n\n.orwi-user-login-button {\n    //flex-grow: 2;\n    font-weight: bold;\n}\n\n\n.orwi-user-name {\n    flex-grow: 2;\n    font-weight: bold;\n    font-size: 14px;\n    padding-left: 15px;\n}\n\n.icon-badge {\n    position: absolute;\n    width: 10px;\n    height: 10px;\n    background-color: var(--ion-color-primary);\n    border-radius: 50%;\n    bottom: 2px;\n    right: 0px;\n}\n\n.welcome-text {\n    width: 100%;\n    overflow: hidden;\n    display: flex;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
