import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "arrayFilter",
  pure: true,
})
export class ArrayFilterPipe implements PipeTransform {
  transform(value: any[], options: FilterOptions): any[] {
    if (options.gte && options.dateFieldKey) {
      value = value.filter(
        (val) =>
          new Date(val[options.dateFieldKey])?.toISOString() >
          options.gte.toISOString()
      );
    }

    if (options.lte && options.dateFieldKey) {
      value = value.filter(
        (val) =>
          new Date(val[options.dateFieldKey])?.toISOString() <=
          options.lte.toISOString()
      );
    }
    return value;
  }
}

interface FilterOptions {
  gte?: Date;
  lte?: Date;
  dateFieldKey: string;
}
