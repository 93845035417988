import { ParseError } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Coupon } from 'src/app/services/dto/orwi-store';
import { FbService } from 'src/app/services/events/fb.service';
import { GlobalService } from 'src/app/services/global.service';
import { CouponService } from 'src/app/services/store/coupon.service';
import { StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'orwi-coupon-field',
  templateUrl: './coupon-field.component.html',
  styleUrls: ['./coupon-field.component.scss'],
})
export class CouponFieldComponent implements OnInit {

  @Input() disabled = false

  loading = false
  @Output() coupon_loaded = new EventEmitter<Coupon>()

  couponCode = ""
  constructor(
    private transloco: TranslocoService,
    private glb: GlobalService,
    private us: UserService,
    private cs: CouponService,private fb :FbService) { }

  ngOnInit() { }


  applyCouponCode() {
    this.loading = true
    
    

     
      if (this.couponCode == "") {
        this.glb.shakeInput("coupon-field")
        this.loading = false
        return
      }
  
      this.couponCode = this.couponCode.toUpperCase().replace(/İ/g,"I")
     
  
      this.cs.loadCoupon(this.couponCode).then(async (o: any) => {
        if (o.error) {
          this.parseError(o.error)
          this.loading = false
          this.glb.shakeInput("coupon-field")
        } else if (o.response) {         
          let cp: Coupon = o.response
          this.fb.scan_coupon(this.us.user.id,cp.storeName,cp.storeId)
          if (cp.couponType == "free-product" || cp.couponType == "discount-percent") {
            await this.cs.getUserCoupons()
          } else if (cp.couponType == "orwi-point") {
            await this.us.getPoints()
          } 
          this.coupon_loaded.emit(o.response)
          this.loading = false
          this.couponCode = ""
        }
      })
      

 
  }


  parseError(error) {

   

    this.glb.toast(this.transloco.translate("Error"), error.desc, "bottom", "warning")

  }

 

  clearInput() {
    this.couponCode = ""
  }

}
