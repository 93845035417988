import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector, Input } from '@angular/core';
import { GoogleMapModalComponent } from './google-map-modal.component';

@Injectable({
  providedIn: 'root'
})
export class GoogleMapModalService {

  component: ComponentRef<GoogleMapModalComponent>
  

  id = ""


  constructor(
    private app: ApplicationRef,
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector
    ) {}

  showComponent(actionType = "select-address"): GoogleMapModalComponent {
    let newNode = document.createElement('div');
    document.getElementById('actionContainer').appendChild(newNode);
    let resolver = this.componentFactoryResolver.resolveComponentFactory(GoogleMapModalComponent);
    this.component = resolver.create(this.injector, [], newNode);  
    this.component.instance.actionType = actionType  
    this.app.attachView(this.component.hostView);
    return this.component.instance
  }

  destroyComponent() {
    this.component.destroy()
    this.component = undefined
   }

   close() {
     this.component.instance.close().then(o => {
       this.destroyComponent()
     })
   }
 
}
