
import { Component, OnInit, Renderer2, ElementRef, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GlobalService } from 'src/app/services/global.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { DOCUMENT } from '@angular/common';

import { Plugins } from '@capacitor/core';
import { AddressService } from 'src/app/services/location/address.service';
import { LocationService } from 'src/app/services/location/location.service';

import { Loader } from "@googlemaps/js-api-loader"
import { LocationEventsService } from 'src/app/services/events/location-events.service';
import { Feed } from 'src/app/services/dto/orwi-home';
import { Store } from 'src/app/services/dto/orwi-store';
import { orwiLocation } from 'src/app/services/dto/orwi-location';
import MarkerClusterer from '@googlemaps/markerclustererplus';


declare const google: any;
var that;
const { Geolocation, Network } = Plugins;

@Component({
  selector: 'google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit, OnChanges {
  map: any;
  public markers: any[] = [];
  markerCluster : MarkerClusterer
  mapOptions: any;
  marker: any;
  centeredMarker: any;
  private mapsLoaded: boolean = false;
  private networkHandler = null;


  @Input() isMini = false
  @Input() places: Feed[]
  @Input() mapId = "orwimap"

  loader: Loader

  constructor(
    public glb: GlobalService,
    public addressService: AddressService,
    private router: Router,
    public us: UserService,
    private ls: LocationService,
    private le: LocationEventsService,
    private renderer: Renderer2,
    private element: ElementRef,
    @Inject(DOCUMENT) private _document) { that = this; }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes.places) {
      if (changes.places.currentValue?.length > 0) {
        this.deleteMarkers()
        this.initMarker(changes.places.currentValue)
      }
    }
  }



  ngOnInit() {



    this.loader = new Loader({
      mapIds: ['87464fb5da5c1bde'],
      apiKey: this.glb.googleApiKey,
      libraries: ["places", "geometry"]
    });


  }


  ngAfterViewInit() {



    this.loader.load().then(() => {

      let lng = this.ls.currentLocation?.longitude || 29.069172884826358
      let lat = this.ls.currentLocation?.latitude || 41.12125536571792
      let zoom = this.isMini ? 9 : 18

      let latLng = new google.maps.LatLng(lat, lng);

      let map = new google.maps.Map(document.getElementById(this.mapId) as HTMLElement, {
        center: latLng,
        zoom: zoom,
        //mapTypeId: google.maps.MapTypeId.ROADMAP,
        myLocationButton: true,
        zoomControl: false,
        panControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
        mapId: '87464fb5da5c1bde',
        keyboardShortcuts: false

      });



      this.le.googleMapInitialized.next(true)

      this.map = map


      var center = map.getCenter();
      that.addressService.checkSelectedPoint(center, false)

      map.addListener('zoom_changed', function () {

        that.refreshData()

      })

      map.addListener('dragend', function () {

        that.refreshData()

        if (!that.isMini) {
          that.addressService.checkSelectedPoint(center, false)
        }

      });


      this.le.currentLocationEvent.subscribe(o => {
        if (o.location) {
          this.map.setCenter({ lat: o.location.latitude, lng: o.location.longitude });
        }
      })




      this.initMarker(this.places)
    });




  }


  refreshData() {
    var center = this.map.getCenter();

    /*        var radius = new google.maps.Circle({
             center: center,
             radius: 5000 * xzoom,
             fillOpacity: 0.2,
             strokeOpacity: 0,
             map: that.map
           }); */

     
      var bounds = this.map.getBounds();

      var center = bounds.getCenter();
      var ne = bounds.getNorthEast();

      // r = radius of the earth in statute miles
      var r = 3963.0;

      // Convert lat or lng from decimal degrees into radians (divide by 57.2958)
      var lat1 = center.lat() / 57.2958;
      var lon1 = center.lng() / 57.2958;
      var lat2 = ne.lat() / 57.2958;
      var lon2 = ne.lng() / 57.2958;

      // distance = circle radius from center to Northeast corner of bounds
      var dis = r * Math.acos(Math.sin(lat1) * Math.sin(lat2) +
        Math.cos(lat1) * Math.cos(lat2) * Math.cos(lon2 - lon1));


      var bounds = this.map.getBounds();
      var center = this.map.getCenter();
      if (bounds && center) {
        var ne = bounds.getNorthEast();
        // Calculate radius (in meters).
        var radius = google.maps.geometry.spherical.computeDistanceBetween(center, ne);
      }

     let po =  this.ls.distance(center.lat(), center.lng(), ne.lat(),ne.lng())

      console.log(dis, radius, po)

      this.ls.maxDistance = po 

      let ol: orwiLocation = { latitude: center.lat(), longitude: center.lng() }

      this.le.currentLocationEvent.next({ location: ol, status: "success", isChanged: true })

  }



  setMapOnAll(map: google.maps.Map | null) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }

  // Removes the markers from the map, but keeps them in the array.
  hideMarkers(): void {
    this.setMapOnAll(null);
  }

  // Shows any markers currently in the array.
  showMarkers(): void {
    this.setMapOnAll(this.map);
  }

  // Deletes all markers in the array by removing references to them.
  deleteMarkers(): void {
    this.hideMarkers();
    this.markers = [];
  }

  initMarker(p: Feed[]) {
    //
    if (this.markerCluster) {
      this.markerCluster.setMap(null)
      //this.deleteMarkers()
      //return
    }
  

    const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    if (p) {


      const markers = p.map((location, i) => {
        return new google.maps.Marker({
          position: {lat: location.geoLocation.latitude, lng: location.geoLocation.longitude},
          //label: location.title,
          icon: '/assets/icon/fav16.png',
        });
      });


      //this.markers = markers

    
      // this.markers.map((o,i)=> {
      //   o.addListener('click', function () {

      //     var infowindow = new google.maps.InfoWindow({
      //       content: `
      //     <div style=" margin: 10px">
  
      //     <div style="font-weight:bold;">
      //     ${o.title}
      //     </div>
  
      //     <div>
      //     ${o.text}
      //     </div>
  
      //     </div>
      //     `
      //     });
      //     var coordinates = {
      //       lat: o.location.coordinates[1],
      //       lng: o.location.coordinates[0]
      //     };

      //     infowindow.open(this.map, o);
      //   });
      // })

      

      this.markerCluster = new MarkerClusterer(this.map, markers, 
        {
          imagePath:
          'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        });
    
      // Add a marker clusterer to manage the markers.
      // @ts-ignore MarkerClusterer defined via script
    /*   new MarkerClusterer(map, markers, {
        imagePath:
          "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m",
      }); */


     /*  p.forEach(o => {
        var infowindow = new google.maps.InfoWindow({
          content: `
        <div style=" margin: 10px">

        <div style="font-weight:bold;">
        ${o.title}
        </div>

        <div>
        ${o.text}
        </div>

        </div>
        `
        });
        var coordinates = {
          lat: o.location.coordinates[1],
          lng: o.location.coordinates[0]
        };

        let marker = new google.maps.Marker({

          position: coordinates,
          title: "Buradasınız.",
          icon: '/assets/icon/fav16.png',

        })

        marker.addListener('click', function () {
          infowindow.open(this.map, marker);
        });

        this.markers.push(marker)



      }) */

      //this.setMapOnAll(this.map)
    }


  }




}
