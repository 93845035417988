import { Component, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: "app-about-beltur",
  templateUrl: "./about-beltur.component.html",
  styleUrls: ["./about-beltur.component.scss"],
})
export class AboutBelturComponent implements OnInit {
  constructor(private domSanit: DomSanitizer) {}

  ngOnInit() {
    // let iFrameAboutBeltur : any = document.getElementById("about-beltur")
    // iFrameAboutBeltur.src = "https://beltur.istanbul/tr/hakkimizda"
  }

  safeUrl = this.domSanit.bypassSecurityTrustResourceUrl(
    "https://beltur.istanbul/tr/hakkimizda"
  );
}
