import { Injectable } from "@angular/core";
import * as MFS from "src/assets/masterpass/mfs-client.min.js";
import * as jq from "jquery";
import { AlertController, NavController } from "@ionic/angular";
import { FolioService } from "src/app/services/folio/folio.service";
import { GlobalService } from "src/app/services/global.service";
import { UserService } from "src/app/services/user/user.service";
import { TranslocoService } from "@ngneat/transloco";
import { OrwiService } from "src/app/services/orwi.service";
import { StoreService } from "src/app/services/store/store.service";
import { MasterpassEventsService } from "./masterpass-events.service";
import { Card, CardTransactions } from "./dto/card";
import { UserEventsService } from "src/app/services/events/user-events.service";
import { OrwiPromptService } from "src/app/components/ui/orwi-prompt/orwi-prompt.service";
import { FbService } from "src/app/services/events/fb.service";
import { TipService } from "src/app/services/folio/tip.service";

declare var MFS: any;

@Injectable({
  providedIn: "root",
})
export class MasterpassService {
  cardLoaderStatus: "unset" | "loading" | "success" | "error" = "unset";

  //paymentEvents = new Subject<any>()
  //cardEvents = new Subject<cardEventStatus>()

  status: "non-masterpass" | "non-orwi" | "registered";
  mpToken = "";
  masterpassServerUrl = "";
  merchantClientId = "";
  userGsm = "";
  actionId = "";
  active3dUrl = "";
  active3dId = "";
  isReady = true;
  isMasterCard = true;
  deletedString = "";

  activeCard: Card;

  creditCards: Card[] = [];
  cardTransactions: CardTransactions[] = [];

  constructor(
    private fs: FolioService,
    private ts: TipService,
    private nav: NavController,
    private glb: GlobalService,
    public us: UserService,
    private transloco: TranslocoService,
    private alertCtrl: AlertController,
    private os: OrwiService,
    private storeService: StoreService,
    private oe: MasterpassEventsService,
    private ue: UserEventsService,
    private op: OrwiPromptService,
    private fb: FbService
  ) {
    this.deletedString = this.transloco.translate("Card Deleted");

    debugger;
    MFS.setClientId(this.merchantClientId); //merchand cliendID
    MFS.setAddress(this.masterpassServerUrl); //testUri

    /*     oe.masterpassCardLoadingEvents.subscribe(o => {
          if (o == "loading") {
            this.cardLoaderStatus = "loading"
          } else if (o == "success") {
            this.cardLoaderStatus = "success"
            this.isReady = true
          } else if (o == "error") {
            this.cardLoaderStatus = "error"
          }
        }) */

    oe.masterpassEvents.subscribe((o) => {
      if (!this.us.user.isVirtual) {
        this.us.savelog("💳 masterpass ->" + o);
        this.fb.set_masterpass(o, this.us.user);
      }

      this.cardLoaderStatus == "unset";
      if (o == "registered") {
        this.getCardList();
      } else if (o == "server-error") {
        console.warn("Checkmasterpass Error, Try in 30 seconds");
        setTimeout(() => {
          this.initService();
        }, 30000);
      }
    });

    ue.userEvents.subscribe((o) => {
      this.glb.consolelog("uservents-masterpass", o);
      if (o == "logged-in") {
        this.userGsm = this.us.user.gsm.toString().replace("+", "");
        this.os
          .serviceRequest(
            "/api/master-pass/getConfigs",
            { storeId: "" },
            this.us.token
          )
          .subscribe((o: any) => {
            if (o.response) {
              let mp = Object.assign(o.response);
              if (mp.clientId !== this.merchantClientId) {
                this.merchantClientId = mp.clientId;
                this.masterpassServerUrl = mp.serviceUrl;
                this.cardLoaderStatus = "loading";
              }

              this.initService();
            }
          });
      } else if (o == "logged-out") {
        this.mpToken = "";
        this.creditCards = [];
        this.status = "non-masterpass";
        //this.checkMasterPass()
      }

      /* else if (o == "web-app-logged-in") {
        this.userGsm = this.userService.user.gsm.toString().replace("+", "")

        this.orwiService.serviceRequest("/api/master-pass/getConfigs", { storeId: "" }, this.userService.token).subscribe(o => {
          this.glb.consolelog("uservents-masterpass-2", o)
          let mp = Object.assign(o)

          this.merchantClientId = mp.clientId
          this.masterpassServerUrl = mp.serviceUrl
          this.cardLoaderStatus = "loading"
          this.initService()
        })

      } */
    });

    /*     //BURADAN PATLIYOR...
        oe.storeEvents.subscribe(o => {
          this.glb.consolelog("oe store events-masterpass", o)
          if (o == "loaded" && this.userService.user) {
            if (storeService.store.masterpass) {
              if (this.merchantClientId !== storeService.store.masterpass.clientId) {
                this.merchantClientId = storeService.store.masterpass.clientId
                this.masterpassServerUrl = storeService.store.masterpass.serviceUrl
                this.initService()
              } else {
                this.glb.consolelog("masterpass", "merchant same...")
              }
            } else {
              console.error("Masterpass params not defined..")
            }
          }
        }) */
  }

  async initService() {
    if (this.us.user?.isVirtual) return;
    this.isReady = false;
    MFS.setClientId(this.merchantClientId);
    MFS.setAddress(this.masterpassServerUrl);
    await this.getToken();
    setTimeout(() => {
      this.checkMasterPass();
    }, 100);
  }

  getToken() {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequestPromise(
          "/api/master-pass/createToken",
          { storeId: this.storeService.store?.id },
          this.us.token
        )
        .then((o: any) => {
          if (o.response) {
            this.mpToken = o.response.token;
            resolve(this.mpToken);
          }
        });
    });
  }

  commitPurchase(token: string, folioNo: string, amount: string) {
    let parameters = {
      token: token,
      folioId: folioNo,
      amount: amount,
      actionId: this.actionId,
      storeId: this.storeService.store.id,
      cardId: this.activeCard.id,
    };
    return this.os.serviceRequest(
      "/api/master-pass/commitPurchase",
      parameters,
      this.us.token
    );
  }

  startPurchase(cardId, amount): Promise<string> {
    this.glb.consolelog("folioid", this.fs.folio.id);
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequestPromise(
          "/api/master-pass/startPurchase",
          { folioId: this.fs.folio.id, cardId: cardId, amount: amount },
          this.us.token
        )
        .then(
          (o: any) => {
            if (o.response) {
              this.actionId = o.response.actionId;
              resolve(this.actionId);
            } else if (o.error) {
              this.glb.toast(o.error.desc, "", "top", "warning", 3000);
            }
          },
          (e) => {
            reject("error");
          }
        );
    });
  }

  //MASTERPASS REQUEST
  async getCardList(msisdn: string = "") {
    await this.getToken();
    this.cardLoaderStatus = "loading";
    this.creditCards = [];
    this.cardTransactions = [];
    this.oe.masterpassCardLoadingEvents.next("loading");
    msisdn = this.us.user.gsm.replace("+", "");

    let tempThis = this;

    (function ($) {
      $(document).ready(function () {
        MFS.listCards(msisdn, tempThis.mpToken, listCardsResponseHandler);
      });

      function listCardsResponseHandler(statusCode, response) {
        tempThis.glb.consolelog("getCardFromMasterPass", response, statusCode);
        if (response.responseCode == "1419") {
          tempThis.setCardLoadingError("Undefined Token");
        } else if (response.responseCode == "1051") {
          tempThis.setCardLoadingError("Undefined User");
        } else if (
          response.responseCode != "0000" &&
          response.responseCode != ""
        ) {
          tempThis.setCardLoadingError("get card error");
        } else if (
          response.responseCode == "0000" ||
          response.responseCode == ""
        ) {
          tempThis.saveCards(response.cards);
        }
      }
    })(jq);
  }

  saveCards(cards) {
    this.saveCreditCards(cards).then((o) => {
      this.glb.consolelog("credit_cards", o);
      this.cardLoaderStatus = "success";
      this.oe.masterpassCardLoadingEvents.next("success");
    });
  }

  setCardLoadingError(error) {
    this.cardLoaderStatus = "error";
    this.oe.masterpassCardLoadingEvents.next("error");
  }

  //MASTERPASS REQUEST
  checkMasterPass() {
    let tempThis = this;

    (function ($) {
      $(document).ready(function () {
        $("#checkMP-form").find('input[name="userId"]').val(tempThis.userGsm); //gsm no input a Set Edilir.
        $("#checkMP-form").find('input[name="token"]').val(tempThis.mpToken); //token input a Set Edilir.
        MFS.checkMasterPass($("#checkMP-form"), chkMPResponseHandler);
      });
      function chkMPResponseHandler(status, response) {
        console.log("checkMasterPass", status, response);
        if (response.responseCode == "0000" || response.responseCode == "") {
          // Register Success
          tempThis.glb.consolelog(response.accountStatus);
          if (response.accountStatus == "0000000000000000") {
            tempThis.status = "non-masterpass";
            tempThis.oe.masterpassEvents.next("non-masterpass");
          } else if (
            response.accountStatus == "0111001100000000" ||
            response.accountStatus == "0111001000000000"
          ) {
            tempThis.status = "registered";
            tempThis.oe.masterpassEvents.next("registered");
          } else if (response.accountStatus == "0110001100000000") {
            tempThis.status = "non-orwi";
            tempThis.oe.masterpassEvents.next("non-orwi");
          }
          tempThis.glb.consolelog(tempThis.status);
        } else {
          tempThis.oe.masterpassEvents.next("server-error");
          tempThis.sendToast(response.responseDescription);
        }
      }
    })(jq);
  }

  //MASTERPASS REQUEST
  async deleteCardOnMasterPass(cardName: string, uniqueId: string) {
    let tempThis = this;
    (function ($) {
      $(document).ready(function () {
        $("#deleteCard-form")
          .find('input[name="token"]')
          .val(tempThis.mpToken!); //token input a Set Edilir.
        $("#deleteCard-form")
          .find('input[name="msisdn"]')
          .val(tempThis.us.user.gsm.toString().replace("+", "")); //token input a Set Edilir.
        $("#deleteCard-form")
          .find('input[name="accountAliasName"]')
          .val(cardName);
        MFS.deleteCard($("#deleteCard-form"), mfsResponseHandler);
        function mfsResponseHandler(status: string, response: any) {
          tempThis.glb.closeLoading();
          if (response.responseCode == "0000" || response.responseCode == "") {
            // Register Success
            tempThis.cardRemoved(cardName);
          } else {
            tempThis.errorToast(
              response.responseDescription,
              response.responseCode
            );
            tempThis.glb.consolelog(response.responseDescription);
          }
        }
      });
    })(jq);
  }

  cardRemoved(cardName) {
    this.glb
      .toast(
        "ORWI",
        this.transloco.translate("Card Deleted"),
        "bottom",
        "success",
        3500
      )
      .then(() => {
        setTimeout(() => {
          this.removeCard(cardName);
          this.oe.masterpassCardLoadingEvents.next("card-deleted");
          this.checkMasterPass();
          this.getCardList();
        }, 100);
      });
  }

  removeCard(cardId) {
    let card = this.creditCards.findIndex((o) => o.name == cardId);
    if (card) {
      this.creditCards.splice(card, 1);
    }
  }

  //MASTERPASS REQUEST
  async linkToMasterPass() {
    await this.getToken();
    let tempThis = this;
    (function ($) {
      $("#linkCardToClient-form")
        .find('input[name="token"]')
        .val(tempThis.mpToken!); //token input a Set Edilir.
      $("#linkCardToClient-form")
        .find('input[name="msisdn"]')
        .val(tempThis.us.user.gsm.toString().replace("+", "")); //token input a Set Edilir.
      MFS.linkCardToClient($("#linkCardToClient-form"), mfsResponseHandler);
      function mfsResponseHandler(status: string, response: any) {
        tempThis.glb.consolelog("linkToMasterPass", status, response);
        if (response.responseCode == "0000" || response.responseCode == "") {
          tempThis.glb.consolelog("linkleme başarılı");
          tempThis.fb.mp_connect(tempThis.us.user.id);
        } else {
          if (
            response.responseCode == "5001" ||
            response.responseCode == "5008"
          ) {
            // OTP sor
            tempThis.oe.masterpassOtpEvents.next("validate-otp-link");
          } else {
            tempThis.glb.consolelog("Link Cards", response.responseDescription);
            tempThis.errorToast(
              response.responseDescription,
              response.responseCode
            );
            tempThis.checkMasterPass();
          }
        }
      }
    })(jq);
  }

  async doTipPayment() {
    let cardName = "";
    this.activeCard = this.creditCards.find((o) => o.selected == true);
    if (this.activeCard) {
      let card = this.creditCards.find((o) => o.selected == true);
      cardName = card.name;
      this.isMasterCard =
        card.masked_pan.startsWith("5") || card.masked_pan.startsWith("6");
    } else {
      this.glb.toast(
        this.transloco.translate("Please Select Credit Card"),
        "",
        "bottom",
        "warning"
      );
      return;
    }

    this.glb.showLoading("");

    let saveStat = await this.ts.saveFolio();

    if (!saveStat) {
      this.oe.paymentEvents.next("save-tip-folio-error");
      return;
    }

    await this.getToken();
    this.glb.consolelog("start tip purchase");

    await this.startTipPurchase(
      this.activeCard.id,
      this.ts.grandTotalforMasterPass()
    );

    let tempThis = this;

    (function ($) {
      $(document).ready(function () {
        $("#purchase-form").find('input[name="listAccountName"]').val(cardName);
        $("#purchase-form").find('input[name="token"]').val(tempThis.mpToken!); //token input a Set Edilir.
        $("#purchase-form")
          .find('input[name="msisdn"]')
          .val(tempThis.us.user.gsm.toString().replace("+", "")); //token input a Set Edilir.
        $("#purchase-form")
          .find('input[name="orderNo"]')
          .val(tempThis.actionId); //token input a Set Edilir.
        $("#purchase-form")
          .find('input[name="amount"]')
          .val(tempThis.ts.grandTotalforMasterPass()); //token input a Set Edilir.
      });
    })(jq);

    if (navigator.onLine == false) {
      this.errorToast(
        this.transloco.translate("Please Check Internet Connection"),
        "INET"
      );
      this.oe.paymentEvents.next("network-tip-error");
      return;
    }

    //TODO:bu datanin duzenlenmesi lazim
    MFS.setAdditionalParameters({
      order_product_name_arr: ["Yemek Bedeli"],
      order_product_code_arr: ["001"],
      order_price_arr: [this.ts.grandTotalforMasterPass()],
      order_qty_arr: ["1"],
      order_product_info_arr: ["Yemek Bedeli"],
      bill_first_name: this.us.user.name,
      bill_last_name: "",
      bill_email: this.us.user.id.substring(12) + "@orwi.app",
      bill_phone: this.glb.cleanGsm(this.us.user.gsm),
      delivery_phone: this.glb.cleanGsm(this.us.user.gsm),
      bill_country_code: "TR",
      delivery_first_name: this.us.user.name,
      "delivery_last_nam:e": "",
      delivery_address: this.glb.notTurkishCharacter("OrwiTip"),
      delivery_email: this.us.user.id.substring(12) + "@orwi.app",
    });
    setTimeout(() => {
      let tempThis = this;
      (function ($) {
        MFS.purchase($("#purchase-form"), mfsResponseHandler);
        return false;
        function mfsResponseHandler(status: string, response: any) {
          tempThis.glb.consolelog("MFS.purchase", response);
          tempThis.doPaymentTipActions(response);
        }
      })(jq);
    });
  }

  doPaymentTipActions(response) {
    this.glb.consolelog("doPaymentActions", response);

    if (response.responseCode == "0000" || response.responseCode == "") {
      // payment Success

      let masterpassTotal = this.ts.grandTotalforMasterPass();
      this.commitTipPurchase(
        response.token,
        this.ts.tipFolio.id,
        masterpassTotal
      ).subscribe((res: any) => {
        if (res.response) {
          let result = Object.assign(res.response);
          this.glb.consolelog("commitPurchase", result);
          this.glb.closeLoading();

          if (result.status == true) {
            this.ts.tipFolio = result.folio;
            this.oe.paymentEvents.next("success-tip");
          } else {
            this.oe.paymentEvents.next("error-tip");
            this.errorToast(result.message, "COMMIT");
          }
        } else if (res.error) {
          this.errorToast(res.error.desc, "COMMIT");
        }
      });
    } else {
      if (response.responseCode == "5001" || response.responseCode == "5008") {
        this.glb.consolelog("OTP-VALIDATE");
        this.oe.masterpassOtpEvents.next("validate-otp-payment");
      } else if (response.responseCode == "5010") {
        // Ask 3D Secure
        this.oe.paymentEvents.next("show-3d");
        this.active3dUrl =
          response.url3D +
          "&returnUrl=" +
          "https://masterpass3d.orwi.app?id=" +
          this.actionId;
        this.glb.consolelog("3durl", this.active3dUrl);
        this.nav.navigateForward("/payment3d");
      } else {
        this.oe.paymentEvents.next("error");
        this.glb.closeLoading();
        this.errorToast(response.responseDescription, response.code);
      }
    }
  }

  commitTipPurchase(token: string, folioNo: string, amount: string) {
    let parameters = {
      token: token,
      folioId: folioNo,
      amount: amount,
      actionId: this.actionId,
      storeId: "tip-store",
      cardId: this.activeCard.id,
    };
    return this.os.serviceRequest(
      "/api/master-pass/commitPurchase",
      parameters,
      this.us.token
    );
  }

  startTipPurchase(cardId, amount): Promise<string> {
    this.glb.consolelog("folioid", this.ts.tipFolio.id);
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequestPromise(
          "/api/master-pass/startPurchase",
          { folioId: this.ts.tipFolio.id, cardId: cardId, amount: amount },
          this.us.token
        )
        .then(
          (o: any) => {
            if (o.response) {
              this.actionId = o.response.actionId;
              resolve(this.actionId);
            } else if (o.error) {
              this.glb.toast(o.error.desc, "", "top", "warning", 3000);
            }
          },
          (e) => {
            reject("error");
          }
        );
    });
  }

  //DO PAYMENT WITH REGISTRATION CARD BEGIN
  //MASTERPASS REQUEST
  async doPayment() {
    debugger;
    let cardName = "";
    this.activeCard = this.creditCards.find((o) => o.selected == true);
    if (this.activeCard) {
      let card = this.creditCards.find((o) => o.selected == true);
      cardName = card.name;
      this.isMasterCard =
        card.masked_pan.startsWith("5") || card.masked_pan.startsWith("6");
    } else {
      this.glb.toast(
        this.transloco.translate("Please Select Credit Card"),
        "",
        "bottom",
        "warning"
      );
      return;
    }

    this.glb.showLoading("");

    let saveStat = await this.fs.saveFolio();

    if (!saveStat) {
      this.oe.paymentEvents.next("save-folio-error");
      return;
    }

    this.glb.consolelog("getting token", this.mpToken);
    await this.getToken();
    this.glb.consolelog("token did", this.mpToken);
    this.glb.consolelog("start purchase");

    await this.startPurchase(
      this.activeCard.id,
      this.fs.grandTotalforMasterPass()
    );
    this.glb.consolelog("start purchase did");

    let tempThis = this;

    (function ($) {
      $(document).ready(function () {
        $("#purchase-form").find('input[name="listAccountName"]').val(cardName);
        $("#purchase-form").find('input[name="token"]').val(tempThis.mpToken!); //token input a Set Edilir.
        $("#purchase-form")
          .find('input[name="msisdn"]')
          .val(tempThis.us.user.gsm.toString().replace("+", "")); //token input a Set Edilir.
        $("#purchase-form")
          .find('input[name="orderNo"]')
          .val(tempThis.actionId); //token input a Set Edilir.
        $("#purchase-form")
          .find('input[name="amount"]')
          .val(tempThis.fs.grandTotalforMasterPass()); //token input a Set Edilir.
      });
    })(jq);
    debugger;
    if (navigator.onLine == false) {
      this.errorToast(
        this.transloco.translate("Please Check Internet Connection"),
        "INET"
      );
      this.oe.paymentEvents.next("network-error");
      return;
    }

    //TODO:bu datanin duzenlenmesi lazim
    
    MFS.setAdditionalParameters({
      "order_product_name_arr": [
        "Yemek Bedeli",
      ],
      "order_product_code_arr": [
        "001",
      ],
      "order_price_arr": [
        this.fs.grandTotalforMasterPass(),
      ],
      "order_qty_arr": [
        "1",
      ],
      "order_product_info_arr": [
        "Yemek Bedeli",
      ],
      "bill_first_name": this.us.user.name,
      "bill_last_name": "",
      "bill_email": this.us.user.id.substring(12) + "@orwi.app",
      "bill_phone": this.glb.cleanGsm(this.us.user.gsm),
      "delivery_phone": this.glb.cleanGsm(this.us.user.gsm),
      "bill_country_code": "TR",
      "delivery_first_name": this.us.user.name,
      "delivery_last_name": "",
      "delivery_address": this.glb.notTurkishCharacter(this.storeService.store.name.toUpperCase().substring(0, 15)),
      "delivery_email": this.us.user.id.substring(12) + "@orwi.app",

    })

    setTimeout(() => {
      let tempThis = this;
      (function ($) {
        MFS.purchase($('#purchase-form'), mfsResponseHandler);
        return false;
        function mfsResponseHandler(status: string, response: any) {
          tempThis.glb.consolelog("MFS.purchase", response)
          tempThis.doPaymentActions(response)
        }
      })(jq);
    });
  }

  doPaymentActions(response) {
    console.log("doPaymentActions", response)

    if (response.responseCode == "0000" || response.responseCode == "") { // payment Success         

      let masterpassTotal = this.fs.grandTotalforMasterPass()
      this.commitPurchase(response.token, this.fs.folio.id, masterpassTotal).subscribe((res: any) => {
        if (res.response) {

          let result = Object.assign(res.response)
          this.glb.consolelog("commitPurchase", result)
          this.glb.closeLoading()

          if (result.status == true) {
            this.fs.folio = result.folio
            this.oe.paymentEvents.next("success")
          } else {
            this.oe.paymentEvents.next("error")
            this.errorToast(result.message, "COMMIT")
          }

        } else if (res.error) {
          this.errorToast(res.error.desc, "COMMIT")
        }


      })


    } else {

      if (response.responseCode == "5001" || response.responseCode == "5008") {
        this.glb.consolelog("OTP-VALIDATE")
        this.oe.masterpassOtpEvents.next("validate-otp-payment")
      } else if (response.responseCode == "5010") { // Ask 3D Secure
        this.oe.paymentEvents.next("show-3d")
        this.active3dUrl = response.url3D + "&returnUrl=" + "https://masterpass3d.orwi.app?id=" + this.actionId
        this.glb.consolelog("3durl", this.active3dUrl)
        this.nav.navigateForward("/payment3d")
      } else {
        this.oe.paymentEvents.next("error")
        this.glb.closeLoading()
        this.errorToast(response.responseDescription, response.code)
      }

    }

  }
 

  checkMembership() {
    if (this.status == "non-masterpass") {
      this.oe.masterpassEvents.next("register-card");
    } else if (this.status == "non-orwi") {
      this.oe.masterpassEvents.next("link-card");
    } else if (this.status == "registered") {
      this.glb.closeLoading();
    }
  }

  async askForMasterPassLink() {
    if (this.status == "non-orwi") {
      let title = "Masterpass";
      //MasterPass hesabınızda kayıtlı kartlarınız bulunmaktadır. Kartlarınızı Orwi Uygulaması'nda kullanmak için Tamam'a tıklayın.
      let message = this.transloco.translate(
        "You have cards registered in your MasterPass account. Click OK to use your cards in Beltur App."
      );
      let opr = this.op.showComponent({
        title: title,
        message: message,
        closable: true,
        height: "300px",
      });

      opr.click.subscribe((o) => {
        if (o.button.id == "ok") {
          this.linkToMasterPass();
          this.oe.masterpassEvents.next("link-card");
          this.us.savelog("Accept Link to masterpass");
        } else {
          this.oe.masterpassEvents.next("cancel-link");
          this.us.savelog("Reject Link to masterpass");
        }
        opr.closeClick.emit();
      });
    }
  }

  async askDeleteCard(cardName: string, uniqueId: string) {
    await this.getToken();
    let title = "Masterpass";
    console.log('CardList', this.creditCards)
    //MasterPass hesabınızda kayıtlı kartlarınız bulunmaktadır. Kartlarınızı Orwi Uygulaması'nda kullanmak için Tamam'a tıklayın.
    if (this.creditCards.length === 1) {
      let unLinkMessage = this.transloco.translate(
        "When you delete your last card, all your connections in Masterpass will be reset. Would you like to remove your connection with Beltur instead?"
      );

      let oprUnlink = this.op.showComponent({
        title: title,
        message: unLinkMessage,
        closable: true,
      });
      oprUnlink.click.subscribe(async (o) => {
        if (o.button.id == "ok") {
          oprUnlink.closeClick.emit();
          await this.unlinkMasterPass();
          return
        } else {
           this.deleteCardOnMasterPass(cardName, uniqueId);
          //  alert('deleteCardOnMasterPass 1')
          this.oe.masterpassEvents.next("link-card");
          oprUnlink.closeClick.emit();
        }

      });
    } else {
      let message = this.transloco.translate("Do you want to delete card?");
      let opr = this.op.showComponent({
        title: title,
        message: message + " :" + cardName,
        closable: true,
      });
      opr.click.subscribe((o) => {
        if (o.button.id == "ok") {
          // alert('deleteCardOnMasterPass 2')
            this.deleteCardOnMasterPass(cardName, uniqueId);
          this.oe.masterpassEvents.next("link-card");
        }
        opr.closeClick.emit();
      });
    }
  }

  async unlinkMasterPass() {
    await this.getToken()
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequest("/api/master-pass/unlinkAccount", { token: this.mpToken }, this.us.token)
        .subscribe((o: any) => {
          if (o.response) {
            if (o.response.statusCode === '200') {
              this.glb.toast('MasterPass', this.transloco.translate('The MasterPass affiliate link has been broken.'), 'bottom', 'dark')
              this.creditCards = []
              this.nav.navigateRoot("home")
            }
          } else if (o.error) {
            this.glb.toast('MasterPass', o.error.desc, "top", "danger", 3000);
          }
        });
    });
  }

  sendToast(msg: String) {
    this.glb.toast("MasterPass", msg, "bottom", "danger", 3500).then((o) => {
      setTimeout(() => {
        this.glb.closeLoading();
      }, 4000);
    });
  }

  errorToast(msg: String, responseCode) {
    this.glb
      .toast("MasterPass", msg + ` [${responseCode}]`, "bottom", "danger", 3500)
      .then((o) => {
        setTimeout(() => {
          this.glb.closeLoading();
        }, 4000);
      });
  }

  saveCreditCards(cards) {
    return new Promise((resolve, reject) => {
      this.os
        .serviceRequest("/api/credit-card/saveCards", cards, this.us.token)
        .subscribe((o: any) => {
          if (o.response) {
            this.creditCards = Object.assign(o.response);
            resolve(this.creditCards);
          }
        });
    });
  }

  getCreditCards() {
    this.os
      .serviceRequest("/api/credit-card/getCardList", {}, this.us.token)
      .subscribe((o: any) => {
        if (o.response) {
          this.creditCards = Object.assign(o);
        }
      });
  }

  setDefaultCard(id) {
    let card = this.creditCards.find((o) => o.id == id);
    if (card) {
      card.selected = true;
    }
    this.os
      .serviceRequest("/api/credit-card/setDefault", { id: id }, this.us.token)
      .toPromise();
  }

  getSelectedCardName() {
    let card = this.creditCards.find((o) => o.selected == true);
    if (card) {
      return card.name;
    } else {
      return "";
    }
  }

  askRegisterCard() {
    if (this.status == "non-masterpass") {
      this.us.savelog("new masterpass member");
      // this.nav.navigateForward('credit-card-form')
      return;
      let title = "Masterpass";
      let message = this.transloco.translate(
        "You have no cards registered on Masterpass. Please identify the card first."
      );
      let opr = this.op.showComponent({
        title: title,
        message: message,
        closable: true,
      });
      opr.click.subscribe((o) => {
        if (o.button.id == "ok") {
          this.us.savelog("new masterpass member");
          this.nav.navigateForward("credit-card-form");
        }
        opr.closeClick.emit();
      });
    }
  }
}

export type cardEventStatus =
  | "unset"
  | "cancel-register"
  | "success"
  | "loading"
  | "otp-validate"
  | "delete";
