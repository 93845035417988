import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PointInfo } from '../dto/orwi-user';

@Injectable({
  providedIn: 'root'
})
export class UserEventsService {

  userEvents = new Subject<'logged-in' | 'logged-out' | 'web-app-logged-in'>()
  userPointsChanged = new BehaviorSubject<PointInfo>(undefined)

  constructor() { }
}
