import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MyMaskUtil } from 'src/app/helpers/directives/mask-util';
import { User } from 'src/app/services/dto/orwi-user';
import { UserService } from 'src/app/services/user/user.service';
import { Plugins } from '@capacitor/core';
import { GlobalService } from 'src/app/services/global.service';
import { IonDatetime, NavController } from '@ionic/angular';
import {emailIsValid} from "../../../helpers/methods/form";
import { TranslocoService } from '@ngneat/transloco';
const { Browser } = Plugins;

@Component({
  selector: 'orwi-name-form',
  templateUrl: './name-form.component.html',
  styleUrls: ['./name-form.component.scss'],
})
export class NameFormComponent implements OnInit {
  @ViewChild("birth") birth: IonDatetime
  @Output() cancelForm = new EventEmitter
  @Output() saveForm = new EventEmitter

  terms = false
  privacy = false
  consent = false
  clarification = false

  public phoneValue01: string = '1231234567';
  public phoneValue02: string;
  public datemMask = MyMaskUtil.DATE_GENERATOR;


  constructor(
    private nav: NavController,
    private glb: GlobalService,
    public us: UserService,private transloco: TranslocoService) {

  }

  ngOnInit() { }

  save() {

    this.validateForm()


  }

  cancel() {
    this.cancelForm.emit("")
  }

  ngAfterViewInit() {
    // if (this.us.userLanguage == "tr") {
    //   this.birth.monthShortNames = "Ocak, Şubat,Mart,Nisan,Mayıs,Haziran,Temmuz,Ağustos,Eylül,Ekim,Kasım,Aralık"

    // }

    this.birth.cancelText = this.transloco.translate("Cancel")
    this.birth.doneText = this.transloco.translate("Done")


  }

  validateForm() {
    let nameValid = this.us.user.name.trim().length > 2 ? true : false
    let privacyChecked = this.privacy
    let termsChecked = this.terms

    const birthDayCheckRegex = /^(?:0[1-9]|[12][0-9]|3[01])[-/.](?:0[1-9]|1[012])[-/.](?:19\d{2}|20[01][0-9]|2022)/;
    let emailValid = emailIsValid(this.us.user.email);
    // let birthValid = birthDayCheckRegex.test(this.us.user.dateOfBirth)
    let birthValid = false

    if (this.us.user.dateOfBirth) {
      birthValid = true
    }

    if (!emailValid) {
      this.glb.shakeInput("email")
    }

    if (!birthValid) {
      this.glb.shakeInput("birth-date")
    }

    if (!nameValid) {
      this.glb.shakeInput("name")
    }

    if (!privacyChecked) {
      this.glb.shakeInput("privacy")
    }

    if (!termsChecked) {
      this.glb.shakeInput("terms")
    }

    if (!this.clarification) {
      this.glb.shakeInput("clarification")
    }

    if (!this.consent) {
      this.glb.shakeInput("consent")
    }

    if (nameValid && privacyChecked && termsChecked && this.clarification && this.consent && emailValid && birthValid) {
      //this.cancel()
      this.saveForm.emit()
    }

  }




  // async showPrivacy() {
  //   await Browser.open({ url: 'https://content.orwi.app/numnum/privacy' });
  // }

  // async showTerms() {

  //   await Browser.open({ url: 'https://content.orwi.app/numnum/terms' });
  // }



  async showPrivacy() {
    this.nav.navigateForward(["texts/privacy-policy"])
  }

  async showTerms() {

    this.nav.navigateForward(["texts/membership-agreement"])
  }

  showClarification() {
    this.nav.navigateForward(["texts/clarification-text"])
  }

  showConsenst() {
    this.nav.navigateForward(["texts/express-consent"])
  }

}
