// import {
//   ChangeDetectorRef,
//   Component,
//   EventEmitter,
//   Input,
//   OnInit,
//   Output,
// } from "@angular/core";
// import { Platform, NavController, ActionSheetController, IonContent } from "@ionic/angular";
// import { TranslocoService } from "@ngneat/transloco";
// import { Subscription } from "rxjs";
// import { schedulerPayload } from "src/app/services/dto/orwi-folio";
// import { Product, ProductGroup } from "src/app/services/dto/orwi-product";
// import { FbService } from "src/app/services/events/fb.service";
// import { QrScannerEventsService } from "src/app/services/events/qr-scanner-events.service";
// import { FolioService } from "src/app/services/folio/folio.service";
// import { GlobalService } from "src/app/services/global.service";
// import { StoreService } from "src/app/services/store/store.service";
// import { UserService } from "src/app/services/user/user.service";
// import { MenuQuery } from "src/app/state/menu.query";
// import { MenuStore } from "src/app/state/menu.store";

// @Component({
//   selector: "orwi-infinite",
//   templateUrl: "./product-gallery-infinite.component.html",
//   styleUrls: ["./product-gallery-infinite.component.scss"],
// })
// export class ProductGalleryInfiniteComponent implements OnInit {
//   disableDefer = false;
//   qrEventSubscription: Subscription;
//   @Input() type: "order" | "menu" = "menu";
//   @Input() filteredProducts: Product[] = [];
//   @Output() addItem = new EventEmitter();
//   products: Product[] = [];
//   gifts: Product[] = [];
//   objSelectedGroup: ProductGroup;
//   searchQuery = "";
//   giftSelectActive = false;
//   @Input() content: IonContent;
//   constructor(
//     private menuStore: MenuStore,
//     private chRef: ChangeDetectorRef,
//     private plt: Platform,
//     private fs: FolioService,
//     private que: QrScannerEventsService,
//     public menuQuery: MenuQuery,
//     private nav: NavController,
//     public ss: StoreService,
//     public us: UserService,
//     public glb: GlobalService,
//     private fb: FbService,
//     public actionSheet: ActionSheetController,
//     private transloco: TranslocoService
//   ) {}

//   ngOnInit() {
//     this.menuQuery.selectedGroup$.subscribe((o) => {
//       console.log(
//         "ProductGalleryInfiniteComponent",
//         JSON.stringify(this.objSelectedGroup)
//       );
//      setTimeout(() => {
//       if (o) {
//         let el = document.getElementById("g-" + o.id);
//         console.log("content",this.content)
//         this.content.getScrollElement().then(async (o) => {
//           if (el) {
//             let offset = 0;
//             let height = el.offsetTop - el.scrollTop;
//             let gift = document.getElementById("gift-title");
//             // console.log("scroll Top", o.scrollTop)
//             // console.log("scroll height", o.scrollHeight)
//             // console.log("element ofsetHeight", el.offsetHeight)
//             // console.log("element offsetTop", el.offsetTop)
//             // console.log("height", el.offsetTop)

//             this.disableDefer = true;
//             if (height > 1000 && el.offsetTop >= o.scrollTop)
//               await this.content.scrollToPoint(0, el.offsetTop - 1000, 50);
//             if (height > 1000 && el.offsetTop < o.scrollTop)
//               await this.content.scrollToPoint(0, el.offsetTop + 1000, 50);
//             //await this.content.scrollToPoint(0, el.offsetTop - 100, 1000)
//             this.content.scrollToPoint(0, el.offsetTop - 40, 1500).then((o) => {
//               this.disableDefer = false;
//             });
//           }
//         });
//       }
//      }, 1000);
//     });

//   }

//   _addItem(p: Product) {
//     // console.log(p);
//     // this.ss.activeProduct = p;
//     // this.nav.navigateForward("product-detail");
//     this.addItem.emit(p);
//   }

//   loadImage(e, p) {
//     if (p.imageUse) {
//       setTimeout(() => {
//         if (e) {
//           p.image = this.ss.fetchProductImage(p.id, "small");
//           p.printer = "1";
//           console.log(p)
//         }
//       }, 10);
//     }
//   }

//   deferGroup(e, i) {
//     if(this.disableDefer) return;
//     console.log("defer", e, i);
//     let id = "cover" + i.id;
//     let el = document.getElementById(id);
//     if (el) {
//       var div = document.getElementById("scrolling_div");

//       let op =
//         div.getBoundingClientRect().width - el.getBoundingClientRect().width;
//       op = op / 2;
//       div.scrollLeft = el.offsetLeft - op;

//        this.menuStore.update({ selectedGroup: i });
//     }
//   }

//   giftSelected() {

//     this.giftSelectActive = true
//     this.filteredProducts = []
//     let coupons = this.us.user.coupons.filter(o => o.couponType == 'free-product' && o.loaded
//       && (o.storeId == this.ss.store.id || o.storeId == this.ss.store.parentId))
//     let pids = []

//     for (const iterator of coupons) {
//       for (const prd of iterator.products) {
//         pids.push(prd)
//         let pc = this.ss.store.products.find(x => x.id == prd)
//         if (pc) {
//           pc["couponCode"] = iterator.id
//           this.filteredProducts.push(pc)
//         }
//       }
//     }

//     setTimeout(() => {

//       this.menuStore.update({ products: this.filteredProducts })
//       this.fadeInGallery()

//     }, 100);

//   }

//   groupSelected(item: ProductGroup) {
//     this.giftSelectActive = false;
//     this.products = this.ss.store.products.filter(
//       (o) => o.group == item.id && o.dlPrice !== 0 && o.price !== 0
//     );
//     this.products.map((o) => (o["couponCode"] = ""));
//     // this.filterServiceType();
//     console.log(this.filteredProducts)
//     this.glb.consolelog(
//       "Product Gallery -> FilteredProducts",
//       this.filteredProducts
//     );
//     let el1 = document.getElementById("g-"+item.id);
//     this.objSelectedGroup = item;
//     // el1.scrollIntoView({
//     //   behavior: "smooth",
//     //   block: "start",
//     //   inline: "center",
//     // });
//     //this.menuStore.update({ products: this.filteredProducts })
//     this.fadeInGallery();
//   }

//   subGroupSelected(item: ProductGroup) {
//     this.products = this.ss.store.products.filter((o) => o.group == item.id);
//     this.products.map((o) => (o["couponCode"] = ""));
//     // this.filterServiceType();
//     this.glb.consolelog(
//       "Product Gallery -> FilteredProducts",
//       this.filteredProducts
//     );
//     //setTimeout(() => {
//     //this.menuStore.update({ products: this.filteredProducts })
//     let el2 = document.getElementById("sub-g-"+item.id);
//     // el2.scrollIntoView({
//     //   behavior: "smooth",
//     //   block: "start",
//     //   inline: "nearest",
//     // });
//     this.fadeInGallery();
//     //}, 500);
//   }

//   // loadImage(e, p) {
//   //   if (p.imageUse) {
//   //     setTimeout(() => {
//   //       if (e) {
//   //         p.smallImage = this.ss.fetchProductImage(p.id, "small");
//   //         p.printer = "1";
//   //       }
//   //     }, 10);
//   //   }
//   // }

//   getPrice(item: Product) {
//     if (this.giftSelectActive) return 0;
//     if (this.ss.action == "delivery" || this.ss.action == 'take-away') {
//       return item.dlPrice || item.price
//     } else {
//       return item.price
//     }
//   }
//   fadeInGallery() {
//     return
//     document
//       .getElementsByClassName("gallery-menu")[0]
//       .classList.add("animate__fadeIn");
//     setTimeout(() => {
//       document
//         .getElementsByClassName("gallery-menu")[0]
//         .classList.remove("animate__fadeIn");
//     }, 500);
//   }

//   isUsedCoupon(item: Product) {
//     let ix = this.fs.folio.rows.findIndex(
//       (o) => o.couponCode == item.couponCode
//     );
//     return ix !== -1;
//   }
// }

import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import {
  Platform,
  NavController,
  ActionSheetController,
  IonContent,
} from "@ionic/angular";
import { TranslocoService } from "@ngneat/transloco";
import { Subscription } from "rxjs";
import { schedulerPayload } from "src/app/services/dto/orwi-folio";
import { Product, ProductGroup } from "src/app/services/dto/orwi-product";
import { FbService } from "src/app/services/events/fb.service";
import { QrScannerEventsService } from "src/app/services/events/qr-scanner-events.service";
import { FolioService } from "src/app/services/folio/folio.service";
import { GlobalService } from "src/app/services/global.service";
import { StoreService } from "src/app/services/store/store.service";
import { UserService } from "src/app/services/user/user.service";
import { MenuQuery } from "src/app/state/menu.query";
import { MenuStore } from "src/app/state/menu.store";

@Component({
  selector: "orwi-infinite",
  templateUrl: "./product-gallery-infinite.component.html",
  styleUrls: ["./product-gallery-infinite.component.scss"],
})
export class ProductGalleryInfiniteComponent implements OnInit {
  disableDefer = false;
  qrEventSubscription: Subscription;
  @Input() type: "order" | "menu" = "menu";
  @Input() filteredProducts: Product[] = [];
  @Output() addItem = new EventEmitter();
  products: Product[] = [];
  gifts: Product[] = [];
  objSelectedGroup: ProductGroup;
  searchQuery = "";
  giftSelectActive = false;

  @Input() content: IonContent;

  constructor(
    private menuStore: MenuStore,
    private chRef: ChangeDetectorRef,
    private plt: Platform,
    private fs: FolioService,
    private que: QrScannerEventsService,
    public menuQuery: MenuQuery,
    private nav: NavController,
    public ss: StoreService,
    public us: UserService,
    public glb: GlobalService,
    private fb: FbService,
    public actionSheet: ActionSheetController,
    private transloco: TranslocoService
  ) {}

  ngOnInit() {
    this.menuQuery.selectedGroup$.subscribe((o) => {
      console.log(
        "ProductGalleryInfiniteComponent",
        JSON.stringify(this.objSelectedGroup)
      );

      setTimeout(() => {
        if (o) {
          debugger;
          let el = document.getElementById("g-" + o.id);
          this.content.getScrollElement().then(async (o) => {
            if (el) {
              let offset = 0;
              let height = el.offsetTop - el.scrollTop;
              let gift = document.getElementById("gift-title");
              // console.log("scroll Top", o.scrollTop)
              // console.log("scroll height", o.scrollHeight)
              // console.log("element ofsetHeight", el.offsetHeight)
              // console.log("element offsetTop", el.offsetTop)
              // console.log("height", el.offsetTop)

              this.disableDefer = true;
              if (height > 1000 && el.offsetTop >= o.scrollTop)
                await this.content.scrollToPoint(0, el.offsetTop - 1000, 50);
              if (height > 1000 && el.offsetTop < o.scrollTop)
                await this.content.scrollToPoint(0, el.offsetTop + 1000, 50);
              //await this.content.scrollToPoint(0, el.offsetTop - 100, 1000)
              this.content
                .scrollToPoint(0, el.offsetTop - 40, 1500)
                .then((o) => {
                  this.disableDefer = false;
                });
            }
          });
        }
      }, 1000);
    });

    this.initGifts();
  }

  getProductSoldOut(productItem: Product) {
    // productItem?.soldOut || (menuQuery.selectedGroup$ | async)?.soldOut || (menuQuery.selectedSubGroup | async)?.soldOut
    try {
      let productSoldOut = productItem?.soldOut;
      let selectedGroup = this.menuStore.getValue().selectedGroup;
      let selectedSubGroup = this.menuStore.getValue().selectedSubGroup;
      let isProductInGroup = selectedGroup?.id == productItem?.group;
      let isProductInSubGroup = selectedSubGroup?.id == productItem?.group;
      let productGroup = this.menuStore
        .getValue()
        .allGroups.find((gr) => gr.id == productItem.group);
      let productGroupSoldOut = productGroup?.soldOut;

      if (!isProductInGroup && !isProductInSubGroup && !productGroupSoldOut) {
        if (productSoldOut) {
          return true;
        } else {
          return false;
        }
      }
      if (
        productSoldOut ||
        selectedGroup?.soldOut ||
        selectedSubGroup?.soldOut ||
        productGroupSoldOut
      ) {
        return true;
      }

      return false;
    } catch (error) {
      console.log("ERROR : ", error);
      return false;
    }
  }

  deferSubGroup(e, i) {
    console.log("defer", e, i);
  }

  deferGroup(e, i) {
    if (this.disableDefer) return;
    // console.log("defer", e, i);
    let id = "cover" + i.id;
    let el = document.getElementById(id);
    if (el) {
      var div = document.getElementById("scrolling_div");

      let op =
        div.getBoundingClientRect().width - el.getBoundingClientRect().width;
      op = op / 2;
      div.scrollLeft = el.offsetLeft - op;

      let subGroups = this.ss.store.productGroups.filter(o => o.parentID == i?.id)
      console.log(subGroups)
      this.menuStore.update({ intersectingGroup: i, subGroups: subGroups, selectedSubGroup: subGroups[0] ?? null});
    }
  }

  groupSelected(item: ProductGroup) {
    this.giftSelectActive = false;
    this.products = this.ss.store.products.filter(
      (o) => o.group == item.id && o.dlPrice !== 0 && o.price !== 0
    );
    this.products.map((o) => (o["couponCode"] = ""));
    this.filterServiceType();
    this.glb.consolelog(
      "Product Gallery -> FilteredProducts",
      this.filteredProducts
    );
    this.objSelectedGroup = item;

    //this.menuStore.update({ products: this.filteredProducts })
    this.fadeInGallery();
  }

  subGroupSelected(item: ProductGroup) {
    this.giftSelectActive = false;
    this.products = this.ss.store.products.filter((o) => o.group == item.id);
    this.products.map((o) => (o["couponCode"] = ""));
    this.filterServiceType();
    this.glb.consolelog(
      "Product Gallery -> FilteredProducts",
      this.filteredProducts
    );
    //setTimeout(() => {
    //this.menuStore.update({ products: this.filteredProducts })
    this.fadeInGallery();
    //}, 500);
  }

  filterServiceType() {
    debugger;
    this.filteredProducts = this.filteredProducts.filter((o) => o.price !== 0);
    this.filteredProducts = this.filteredProducts.filter(
      (o) => o.dlPrice !== 0
    );
    if (this.ss.action == "delivery") {
      this.filteredProducts = this.filteredProducts.filter(
        (o) => o.isShowDelivery == true
      );
    } else {
      this.filteredProducts = this.filteredProducts.filter(
        (o) => o.isShowInStore == true
      );
    }
  }

  initGifts() {
    this.gifts = [];
    let coupons = this.us.user.coupons.filter(
      (o) =>
        o.couponType == "free-product" &&
        o.loaded &&
        (o.storeId == this.ss.store.id || o.storeId == this.ss.store.parentId)
    );
    let pids = [];

    for (const iterator of coupons) {
      for (const prd of iterator.products) {
        pids.push(prd);
        let pc = this.ss.store.products.find((x) => x.id == prd);
        if (pc) {
          pc["couponCode"] = iterator.id;
          pc.group = "gift";
          this.gifts.push(pc);
        }
      }
    }
  }

  loadImage(e, p) {
    if (p.imageUse) {
      setTimeout(() => {
        if (e) {
          p.smallImage = this.ss.fetchProductImage(p.id, "small");
          p.printer = "1";
        }
      }, 10);
    }
  }

  _addItem(e: Product) {
    this.fb.select_item(
      e.name,
      this.menuQuery.getValue().selectedGroup.name,
      this.menuQuery.getValue().selectedGroup.id,
      this.ss.store.name
    );
    let groupSoldOut =
      this.menuQuery.getValue().selectedGroup?.soldOut &&
      e.group == this.menuQuery.getValue().selectedGroup?.id;
    let subGrpupSoldOut =
      this.menuQuery.getValue().selectedSubGroup?.soldOut &&
      e.group == this.menuQuery.getValue().selectedSubGroup?.id;
    let usedCoupon = this.isUsedCoupon(e);

    if (
      // e.soldOut ||
      // groupSoldOut ||
      // subGrpupSoldOut ||
      this.getProductSoldOut(e) ||
      (usedCoupon && this.giftSelectActive)
    ) {
      this.glb.shakeInput("item-" + e.id);
      return;
    } else {
      this.addItem.emit(e);
      return;
      if (this.ss.action == "only-menu") {
        this.presentActionSheet();
        return;
      } else {
        this.addItem.emit(e);
      }
    }
  }

  fadeInGallery() {
    return;
    document
      .getElementsByClassName("gallery-menu")[0]
      .classList.add("animate__fadeIn");
    setTimeout(() => {
      document
        .getElementsByClassName("gallery-menu")[0]
        .classList.remove("animate__fadeIn");
    }, 500);
  }

  isUsedCoupon(item: Product) {
    let ix = this.fs.folio.rows.findIndex(
      (o) => o.couponCode == item.couponCode
    );
    return ix !== -1;
  }

  getPrice(item: Product) {
    if (this.giftSelectActive) return 0;
    if (this.ss.action == "delivery" || this.ss.action == "take-away") {
      return item.dlPrice || item.price;
    } else {
      return item.price;
    }
  }

  async presentActionSheet() {
    this.actionSheet
      .create({
        header: this.ss.store.name,
        cssClass: "my-custom-class",
        buttons: this.fillActionButtons(),
      })
      .then((res) => {
        res.present();
      });
  }

  fillActionButtons() {
    let buttonList = [];

    if (this.ss.store.properties.timedTakeAway == true) {
      buttonList.push({
        text: this.transloco.translate("Takeaway"),
        icon: "assets/images/store-home/takeaway.svg",
        handler: () => {
          this.actionButtonClick("timed-take-away");
        },
      });
    }
    if (this.ss.store.properties.timedDineIn == true) {
      buttonList.push({
        text: this.transloco.translate("Quick Table"),
        icon: "assets/images/store-home/speedy-table.svg",
        handler: () => {
          this.actionButtonClick("timedDineIn");
        },
      });
    }

    if (this.ss.store.properties.delivery == true) {
      buttonList.push({
        text: this.transloco.translate("Delivery"),
        icon: "assets/images/store-home/delivery.svg",
        handler: () => {
          this.actionButtonClick("delivery");
        },
      });
    }

    if (this.ss.store.properties.order == true) {
      buttonList.push({
        text: this.transloco.translate("Are you in the place?"),
        icon: "assets/images/store-home/in-store.svg",
        handler: () => {
          this.actionButtonClick("table");
        },
      });
    }

    if (this.ss.store.properties.reservation == true) {
      buttonList.push({
        text: this.transloco.translate("reservation"),
        icon: "assets/images/store-home/reservation.svg",
        handler: () => {
          this.actionButtonClick("reservation");
        },
      });
    }

    buttonList.push({
      text: this.transloco.translate("Cancel"),
      icon: "close",
      role: "cancel",
      handler: () => {
        console.log("Cancel clicked");
      },
    });

    return buttonList;
  }

  actionButtonClick(e) {
    switch (e) {
      case "delivery":
        this.us.checkActiveUser((allreadyLoggedIn) => {
          this.nav.navigateForward("address-list", {
            replaceUrl: !allreadyLoggedIn,
          });
        });
        break;
      case "timedDineIn":
        this.us.checkActiveUser((allreadyLoggedIn) => {
          let payload: schedulerPayload = {
            status: "timed-table",
            point: this.ss.store.orwiPayPoint,
            name: this.ss.store.name,
            storeId: this.ss.store.id,
            type: "timed-table",
          };
          this.nav.navigateForward(["/scheduler", payload], {
            replaceUrl: !allreadyLoggedIn,
          });
        });
        break;

      case "table":
        setTimeout(() => {
          if (this.plt.is("capacitor")) {
            this.nav.navigateForward("scan-qr");
          } else {
            this.nav.navigateForward("scan-qr-web");
          }
        }, 600);

        if (this.qrEventSubscription) this.qrEventSubscription.unsubscribe;
        this.qrEventSubscription = undefined;
        this.qrEventSubscription = this.que.qrScannerEvent.subscribe((o) => {
          this.glb.consolelog("qrScannerEvent", "qr-button");
          if (o.status == "success") {
            this.ss.storeCheckIn(o.qrcode);
            this.qrEventSubscription.unsubscribe();
          } else {
            this.qrEventSubscription.unsubscribe();
          }
        });
        break;
      case "reservation":
        this.nav.navigateForward("reservation-home");
        break;
      case "timed-take-away":
        this.us.checkActiveUser((allreadyLoggedIn) => {
          let payload: schedulerPayload = {
            status: "timed-check-in",
            point: this.ss.store.orwiPayPoint,
            name: this.ss.store.name,
            storeId: this.ss.store.id,
            type: "timed-take-away",
          };
          this.nav.navigateForward(["/scheduler", payload], {
            replaceUrl: !allreadyLoggedIn,
          });
        });
        break;
      default:
        break;
    }

    // if (e == "delivery") {
    //   this.us.checkActiveUser(
    //     (allreadyLoggedIn) => {
    //       this.nav.navigateForward("address-list", {
    //         replaceUrl: !allreadyLoggedIn
    //       })
    //     })
    // }
    // else if (e == 'timedDineIn') {
    //   this.us.checkActiveUser(
    //     (allreadyLoggedIn) => {
    //       let payload: schedulerPayload = {
    //         status: "timed-table",
    //         point: this.ss.store.orwiPayPoint,
    //         name: this.ss.store.name,
    //         storeId: this.ss.store.id,
    //         type: "timed-table",
    //       }
    //       this.nav.navigateForward(["/scheduler", payload], { replaceUrl: !allreadyLoggedIn })
    //     }

    //   )
    // }
    // else if (e == 'table') {
    //   setTimeout(() => {

    //     if (this.plt.is("capacitor")) {
    //       this.nav.navigateForward("scan-qr")
    //     } else {
    //       this.nav.navigateForward("scan-qr-web")
    //     }
    //   }, 600);

    //   if (this.qrEventSubscription) this.qrEventSubscription.unsubscribe; this.qrEventSubscription = undefined
    //   this.qrEventSubscription = this.que.qrScannerEvent.subscribe(o => {
    //     this.glb.consolelog("qrScannerEvent", "qr-button")
    //     if (o.status == "success") {
    //       this.ss.storeCheckIn(o.qrcode)
    //       this.qrEventSubscription.unsubscribe()
    //     } else {
    //       this.qrEventSubscription.unsubscribe()
    //     }
    //   })
    // }
    // else if (e == 'reservation') {
    //   this.nav.navigateForward("reservation-home")
    // }

    // else if (e == 'timed-take-away') {
    //   this.us.checkActiveUser(

    //     (allreadyLoggedIn) => {
    //       let payload: schedulerPayload = {
    //         status: "timed-check-in",
    //         point: this.ss.store.orwiPayPoint,
    //         name: this.ss.store.name,
    //         storeId: this.ss.store.id,
    //         type: "timed-take-away",
    //       }
    //       this.nav.navigateForward(["/scheduler", payload], { replaceUrl: !allreadyLoggedIn })
    //     }

    //   )
    // }
  }

  myGifts() {
    this.nav.navigateForward("my-gifts");
  }

  giftSelected() {
    this.giftSelectActive = true;
    this.filteredProducts = [];
    let coupons = this.us.user.coupons.filter(
      (o) =>
        o.couponType == "free-product" &&
        o.loaded &&
        (o.storeId == this.ss.store.id || o.storeId == this.ss.store.parentId)
    );
    let pids = [];

    for (const iterator of coupons) {
      for (const prd of iterator.products) {
        pids.push(prd);
        let pc = this.ss.store.products.find((x) => x.id == prd);
        if (pc) {
          pc["couponCode"] = iterator.id;
          this.filteredProducts.push(pc);
        }
      }
    }

    setTimeout(() => {
      this.menuStore.update({ products: this.filteredProducts });
      this.fadeInGallery();
    }, 100);
  }
}
