import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { GlobalService } from "./global.service";
import { TranslocoService } from "@ngneat/transloco";

@Injectable({
  providedIn: "root",
})
export class OrwiService {
  oriwServiceUrl = "https://service.orwi.app";
  apiKey =
    "V1cxV2MweFlVakZqYlhld3dlcndlZmhyV20xMGMyRXpUbWhhUnpWdFlXNWtiVnBZWkd4a2JWWnR3cQ"; //beltur
  token = "";
  userLanguage = "en";
  userRegion = "us";
  get isIstanbulApp() {
    return Boolean(localStorage.getItem("isIstanbulApp"));
  }

  constructor(
    private http: HttpClient,
    private glb: GlobalService,
    private transloco: TranslocoService
  ) {
    this.oriwServiceUrl = "https://gandalf.orwi.app";
    // this.oriwServiceUrl = "https://service-test.orwi.app"
  // this.oriwServiceUrl = "https://127.0.0.1"
  }

  serviceRequest(path, postData, _token = "") {
    if (!this.glb.networkConnected) {
      this.glb.toast("Connection Error", "No Internet", "middle", "danger");
      return undefined;
    }
    this.token = _token;
    let url = `${this.oriwServiceUrl}${path}`;
    return this.http.post(url, postData, this.getHeaders());
  }

  externalServiceRequestPromise(type: "get" | "post" = "get",path, postData = {}, _token = ""): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.glb.networkConnected) {
        this.glb.toast("Connection Error", "No Internet", "middle", "danger");
        return undefined;
      }

      if (!navigator.onLine) {
        this.glb.toast("Connection Error", "No Internet", "middle", "danger");
        reject("offline");
        return;
      }

      this.token = _token;
      let url = `${path}`;
      (type == "get" ? this.http.get(url) : this.http.post(url,postData, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        }
      })).subscribe(
        (o) => {
          resolve(o);
        },
        (e) => {
          this.glb.consolelog("error", e);
          this.glb.closeLoading();
         // alert(JSON.stringify(e))
          if (e.status == 0) {
            this.glb.closeLoading();
            setTimeout(() => {
              this.glb.toast(
                "Opss!.",
                this.transloco.translate(
                  "There is a problem, try again after a while"
                ),
                "middle",
                "danger"
              );
            }, 500);
          } else {
            this.glb.closeLoading();
            setTimeout(() => {
              this.glb.toast("Opss!..", e.error.message, "middle", "danger");
            }, 500);
          }
          reject(e.error.message);
        }
      );
    });
  }

  serviceRequestPromise(path, postData, _token = "") {
    return new Promise((resolve, reject) => {
      if (!this.glb.networkConnected) {
        this.glb.toast("Connection Error", "No Internet", "middle", "danger");
        return undefined;
      }

      if (!navigator.onLine) {
        this.glb.toast("Connection Error", "No Internet", "middle", "danger");
        reject("offline");
        return;
      }

      this.token = _token;
      let url = `${this.oriwServiceUrl}${path}`;
      this.http.post(url, postData, this.getHeaders()).subscribe(
        (o) => {
          resolve(o);
        },
        (e) => {
          this.glb.consolelog("error", e);
          this.glb.closeLoading();
          if (e.status == 0) {
            this.glb.closeLoading();
            setTimeout(() => {
              this.glb.toast(
                "Opss!.",
                this.transloco.translate(
                  "There is a problem, try again after a while"
                ),
                "middle",
                "danger"
              );
            }, 500);
          } else {
            this.glb.closeLoading();
            setTimeout(() => {
              this.glb.toast("Opss!..", e.error.message, "middle", "danger");
            }, 500);
          }
          reject(e.error.message);
        }
      );
    });
  }

  getHeaders() {
    let httpOptions = {
      headers: new HttpHeaders({
        "content-Type": "application/json",
        accept: "application/json",
        apikey: this.apiKey,
        token: this.token,
        "accept-language":
        localStorage.getItem("activeLang") ?? this.userLanguage,
        region: this.userRegion,
      }),
    };
    return httpOptions;
  }

  public getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  getIP() {
    this.getIPAddress().subscribe((res: any) => {
      this.glb.ipAddress = res.ip;
    });
  }
}
