import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NavController } from '@ionic/angular';
import * as moment from 'moment';
import { Store } from 'src/app/services/dto/orwi-store';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'orwi-store-status',
  templateUrl: './store-status.component.html',
  styleUrls: ['./store-status.component.scss'],
})
export class StoreStatusComponent implements OnInit, OnChanges {
  openCloseText = ""
  storeOpen

  @Input() store: Store

  constructor(private ss: StoreService, private glb: GlobalService, private nav: NavController) { 



  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.store) {


      let oc = this.ss.getOpenClose(this.store)
      this.openCloseText = oc.text
      this.storeOpen = oc.status
    }
  }

  getWorkingHours() {
    let ms = moment().weekday()
    return this.store.workingHours[ms]
  }


  ngOnInit() {
    
  

  }



  ngAfterViewInit() {


    let oc = this.ss.getOpenClose(this.store)
    this.openCloseText = oc.text
    this.storeOpen = oc.status
   
  }

  showMenu() {
    this.glb.showLoading()
    this.ss.fetchMenu(this.ss.activeStoreId).then(o => {
      this.glb.closeLoading()
      this.ss.action = "only-menu"
      this.nav.navigateForward("menu-home")
    })

  }

 
}
