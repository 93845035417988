import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { IonSearchbar, NavController } from '@ionic/angular';
import { GoogleMapComponent } from '../google-map/google-map.component';
import { Plugins } from '@capacitor/core';
import { AddressService } from 'src/app/services/location/address.service';
import { GlobalService } from 'src/app/services/global.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { LocationService } from 'src/app/services/location/location.service';
import { geolocation } from 'src/app/services/dto/orwi-user';
import { LocationEventsService } from 'src/app/services/events/location-events.service';
import { OrwiAddress } from 'src/app/services/dto/orwi-address';
declare const google: any;
var that;
const { Geolocation, Network } = Plugins;
@Component({
  selector: 'google-map-select-address',
  templateUrl: './google-map-select-address.component.html',
  styleUrls: ['./google-map-select-address.component.scss'],
})
export class GoogleMapSelectAddressComponent implements OnInit {


  floor = ""
  room = ""

  orwiAddress: OrwiAddress = new OrwiAddress()
  type: 'address' | 'location'


  @Input() actionType: 'select-address' | 'save-address' = "select-address"
  @Output() close = new EventEmitter<any>()
  @Output() addressSave = new EventEmitter()



  @ViewChild('addressSearchBar', { read: IonSearchbar }) searchbar: IonSearchbar;
  @ViewChild('gmap') mapComponent: GoogleMapComponent;

  autocomplete: any;
  constructor(

    public glb: GlobalService,
    public addressService: AddressService,
    private route: ActivatedRoute,
    private nav: NavController,
    private ls: LocationService,
    private le: LocationEventsService,
    public us: UserService) {
    that = this;
  }

  ngAfterViewInit(): void {

    /*     setTimeout(() => {
          this.initAutoComplate()
        }, 1000); */

    this.le.googleMapInitialized.subscribe(o => {
      this.initAutoComplate()
    })

  }


  async initAutoComplate() {

    var options2 = {
      types: [],
      componentRestrictions: { country: "tr" }
    };

    this.autocomplete = new google.maps.places.Autocomplete(await this.searchbar.getInputElement(), options2);
    this.autocomplete.addListener('place_changed', this.setSelectedAddress);
    this.glb.consolelog("init auto complate")
  }

  setSelectedAddress() {
   
    var place = that.autocomplete.getPlace();
    that.addressService.checkSelectedPoint(place.geometry.location, false) //seçilen adres poligon sınırları içindemi kontrol edilir.             
    let latLng = new google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
    that.panMap(latLng)
    
  }

  async curentLocation() {
    this.ls.locationStatus =  "unset"
    this.ls.selectedLocation = undefined
    await this.ls.getCurrentPosition(true)
    this.close.emit("")
  }



  panMap(latLng) {

    this.mapComponent.map.panTo(latLng);
    this.mapComponent.map.setZoom(15);
    this.mapComponent.marker.position = latLng

    this.mapComponent.marker.setMap(this.mapComponent.map);
  }

  olsd_findLocation() {
    var center = this.mapComponent.map.getCenter();
    this.nav.navigateForward("/new-address-form")
  }

  ngOnInit() {
    this.route.params.subscribe(async o => {
      if (o.type) {
        this.type = o.type
      }
    })
  }
  /* 
    findLocation() {
      this.glb.showLoading("")
      var center = this.mapComponent.map.getCenter();
      let geo: geolocation = new geolocation()
      geo.latitude = center.lat()
      geo.longitude = center.lng()
      this.glb.activeDeliveryAddress = new Address()
      this.glb.activeDeliveryAddress.geoLocation = geo
      this.addressService.checkAddress(undefined, this.ss.store.id, geo).then((o: any) => {
        this.glb.closeLoading()
        if (o == null) {
          this.glb.closeLoading()
          let header = this.translate.instant("Out of Service Area")
          let msg = this.translate.instant("Sorry, the restaurant cannot serve this area.")
          this.glb.toast(header, msg, "bottom", "warning")
        } else {
          this.ss.minDeliveryFolioTotal = o.minAmount
          this.glb.consolelog("service properties", o)
          this.nav.navigateForward("/new-address-form/" + this.type)
        }
      })
  
    } */

  selectAddres() {
    //this.glb.showLoading("")
    this.ls.locationStatus = "custom"
    var center = this.mapComponent.map.getCenter();
    let geo: geolocation = new geolocation()
    geo.latitude = center.lat()
    geo.longitude = center.lng()
    this.ls.selectedLocation = { longitude: geo.longitude, latitude: geo.latitude }
    this.ls.currentLocation = this.ls.selectedLocation
    this.ls.isChanged = true
    this.addressService.getReverseGeoCode(geo.latitude, geo.longitude).then(o => {
      this.close.emit("")
      setTimeout(() => {
        this.le.selectMapPoisition.next(o)
      }, 1000);
    })


  }

  confirm() {


    var center = this.mapComponent.map.getCenter();
    let geo: geolocation = new geolocation()
    geo.latitude = center.lat()
    geo.longitude = center.lng()
    this.ls.selectedLocation = { longitude: geo.longitude, latitude: geo.latitude }
    this.addressService.getReverseGeoCode(geo.latitude, geo.longitude).then(o => {

  


      let adr = o[0]
      let adrC: adrComp[] = adr.address_components
      this.orwiAddress.country = adrC.find(o => o.types.find(x => x == "country"))?.short_name
      this.orwiAddress.city = adrC.find(o => o.types.find(x => x == "administrative_area_level_1"))?.long_name
      this.orwiAddress.town = adrC.find(o => o.types.find(x => x == "administrative_area_level_2"))?.long_name
      let c1 = adrC.find(o => o.types.find(x => x == "administrative_area_level_3"))?.long_name
      this.orwiAddress.district = adrC.find(o => o.types.find(x => x == "administrative_area_level_4"))?.long_name
      this.orwiAddress.building = adrC.find(o => o.types.find(x => x == "street_number"))?.long_name
      let rout = adrC.find(o => o.types.find(x => x == "route"))?.long_name

      //this.formatted_address = this.district + " " + this.rout + " " + this.street

      this.orwiAddress.address = this.orwiAddress.district + " " + " " + rout


      this.orwiAddress.geoLocation.approve = true
      this.orwiAddress.geoLocation.latitude = geo.latitude
      this.orwiAddress.geoLocation.longitude = geo.longitude




    })

    let bd = document.getElementById("backdrop-address")
    bd.style.height = "100%"


    let el = document.getElementById("address-form")
    el.style.position = "absolute"
    el.style.zIndex = "200"
    //el.style.width = "80%"
    //el.style.height = "80%"
    el.style.top = "0px"
    el.classList.add("animate__slideInUp")

    setTimeout(() => {
      el.classList.remove("animate__slideInUp")
    }, 1000);
  }

  cancelForm() {
    this.close.emit("")
  }

  saveForm() {
    this.us.saveAddress(this.orwiAddress).then(o => {
      this.close.emit("")
      this.le.addressSaved.next(o)
    })

  }




}
class adrComp {
  long_name = ""
  short_name = ""
  types = []
}