import { Modifier, ModifierGroup } from "./orwi-product";

export class FolioRow {
    id: string;
    name: string;
    itemID: string;
    qty: number = 0;
    unitPrice: number = 0;
    new: boolean = true;
    itemImage: string = "";
    parentID: string = "0";

    modifierID: string;
    modifierGroup: string;
    qtyID: number;
    note: string;



    price: number = 0;
    discount: number = 0;
    selected: boolean = false;
    seat: number = 0;

    creator: { userID: string; userName: string } = { userID: "", userName: "" };
    updaters: Array<{ userID: string; userName: string; time: Date }> = [];
    deleters: Array<{ userID: string; userName: string; time: Date, qty: number, reasonID: string, reason: string }> = [];
    recordStatus: RecordStatus

    payStatus: PayRow[] = []

    isModifier: boolean = false
    isMustModifier: boolean = false
    isDetachableModifier = false

    isGift: boolean = false

    isPaidFull: boolean = false

    isLoyalty: boolean = false
    isFree: boolean = false
    isPayment = false
    isDiscount = false


    modifiers: Modifier[] = []

    modifierGroups: ModifierGroup[] = []

    rowType: rowType = "product"

    externalId = ""

    source: 'pos' | 'orwi' = "orwi"

    creation: Date = new Date()


    approve = false

    couponCode = ""
  orginalQty: number;

  


    getRowTotal() {
        return this.unitPrice * this.qty
    }



}

export class PayRow {
    id: string
    name: string
    amount: number
}

export class Folio {
    id: string;
    creation: Date;
    lastChange: Date;
    rows: FolioRow[] = [];
    discount: number = 0;
    service: number = 0;
    total: number = 0;
    grandTotal: number = 0;
    parentID: string = "0";
    status: folioStatus = "open"
    type: ServiceType = "delivery"
    table: { id: string; name: string } = { id: "", name: "" };
    lock: { status: 'unlocked' | 'locked'; time: Date; userName: string; userID: string } = {
        status: "unlocked",
        time: new Date(),
        userName: "",
        userID: ""
    };

    creator: { userID: string; userName: string } = { userID: "", userName: "" };
    updaters: Array<{ userID: string; userName: string; time: Date }> = [];
    pax: number = 4;

    orwi: OrwiPointTransaction = { pointType: "orwi", won: 0, spent: 0 }

    tip = 0.0

    storeId = ""
    storeName = ""

    userId = ""
    externalId = ""
    customerName = ""
    scheduler: Date
    scheduleNote = ""
    isTimed = false
    isOrwiPay = false
    kickback = 0
    inoviceAddressId = ""
    deliveryAddressId = ""
    deliveryAddressType: 'address' | 'location' = "address"
    source: sources = "orwi"
    note = ""
    deliveryPaymentType
    plasticCutlery = true
    deliveryStatus: 'none' | 'routing' | 'preparing' | 'cancelled' | 'completed'
    isRating = false
    staffId = ""
}

export type ServiceType = 'self-ordering' | 'self' | 'table' | 'delivery' | 'take-away' | 'timed-take-away' | 'timed-table'
export type RecordStatus = 'new' | 'old' | 'deleted' | 'removed' | 'all'
export type rowType = 'product' | 'modifier' | 'custom-modifier' | 'discount' | 'payment' | 'promotions' | 'bonus'
export type folioStatus = 'open' | 'closed' | 'cancelled' | 'splitted'
export type sources = "orwi" | "loyalt-qr" | "orwi-pos" | "pos"

export interface schedulerPayload {
    status: string
    point: number
    name: string
    storeId: string
    type: ServiceType
}

export interface OrwiPointTransaction {
    pointType: 'orwi' | 'store' | 'stamp'
    spent: number
    won: number
}

export class deliveryFolio {
    id = ""
    grandTotal = 0
    creationDate = new Date()
    storeName = ""
    storeId = ""
    status = ""
    showDetail = false
    deliveryStatus: 'none' | 'routing' | 'preparing' | 'cancelled' | 'completed'
    type = ""
}