import { HttpClient } from "@angular/common/http";
import {
  TRANSLOCO_LOADER,
  Translation,
  TranslocoLoader,
  TRANSLOCO_CONFIG,
  translocoConfig,
  TranslocoModule,
  provideTransloco,
} from "@ngneat/transloco";
import { Injectable, NgModule, isDevMode } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable({ providedIn: "root" })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient) { }

  getTranslation(lang: string) {
    if (lang == 'tr') {
      return this.http.get<Translation>(`/assets/i18n/tr.json`);
    }
    return this.http.get<Translation>(`/assets/i18n/en.json`);
  }
}
const allLanguages = ["tr", "en", "nl", "ru", "de", "ar",
  "fr", "es", "it", "pt", "ja", "ko", "zh",
  "hi", "sv", "fi", "da", "no", "pl",
  "cs", "sk", "hu", "ro", "bg", "el", "uk",
  "vi", "th", "id", "ms", "tl", "he", "fa",
  "ka", "lt", "lv", "et", "hr", "sr", "sl",
  "sq", "bn", "mr", "ta", "te", "kn", "ur",
  "gu", "pa", "or", "si", "my", "km", "ne",
  "dz", "lo", "am", "ti", "so", "sw", "yo",
  "zu", "xh", "af", "mn", "ug", "ky", "tg",
  "uz", "tk", "sd", "ps", "ku", "hy", "az",
  "kk", "gl", "eu", "is", "fy", "cy", "gd",
  "mt", "lb", "fy"];

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: allLanguages,
        defaultLang: "tr",
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
  ],
})
export class TranslocoRootModule { }
