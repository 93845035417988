import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product, ProductGroup } from 'src/app/services/dto/orwi-product';
import { StoreService } from 'src/app/services/store/store.service';
import { MenuQuery } from 'src/app/state/menu.query';
import { MenuStore } from 'src/app/state/menu.store';

import { Plugins } from "@capacitor/core";
const { Keyboard } = Plugins;

@Component({
  selector: 'orwi-group-selector',
  templateUrl: './group-selector.component.html',
  styleUrls: ['./group-selector.component.scss'],
})
export class GroupSelectorComponent implements OnInit, AfterViewInit {


  @Input() showSubGroups = true
  @Output() groupSelected = new EventEmitter()
  @Output() subGroupSelected = new EventEmitter()
  @Output() giftSelected = new EventEmitter()
  @Input() selectedCategory;

  selectedGroup
  selectedSubGroup
  searchKeyword

  filteredProductGroups: ProductGroup[] = []

  constructor(
  
    public menuQuery: MenuQuery,
    private chRef: ChangeDetectorRef,
    private menuStore: MenuStore,
    public ss: StoreService) { }

  ngOnInit() {

    let el = document.getElementById("group")

    this.menuQuery.searchKeyword$.subscribe(o => {
      if (o == "") {
        this.searchKeyword = ""
      }
    })

    this.menuQuery.selectedGroup$.subscribe(o => {
      if (o) {
        let el1 = document.getElementById(`cover${o?.id}`)
        if (el1) el1.scrollIntoView({ behavior: "smooth", block: "end", inline: "center" });

        setTimeout(() => {
          this.chRef.detectChanges()
        }, 100);

      }
    })

    this.menuQuery.selectedSubGroup.subscribe(o => {
      console.log('sub group', o);
      if (o) {
        let el1 = document.getElementById(`sub-g-${o.id}`)
        console.log('Element 1', el1)
        if (el1) el1.scrollIntoView({ behavior: "smooth", block: "start", inline: "end" });
        setTimeout(() => {
          this.chRef.detectChanges()
        }, 100);
      }
    })


    this.filteredProductGroups = this.ss.store.productGroups.filter(o => o.parentID == "0")
    this.selectedGroup = this.filteredProductGroups[0]?.id
    setTimeout(() => {
      this.selectGroup(this.filteredProductGroups[0])
    }, 500);

    this.menuStore.update({
      selectedGroup: this.filteredProductGroups[0],
      groups: this.filteredProductGroups,
      allGroups: this.ss.store.productGroups,
      infiniteProducts: this.filteredProducts(),
      products: this.filteredProducts().filter(p => p.group == this.selectedGroup)
    })
  }

  ngAfterViewInit(): void {
    this.selectedCategory = this.ss.activeProductGroup?.id
    console.log('ngAfterViewInitselectedcat',this.selectedCategory)
    if (this.ss.activeProductGroup) {
      this.selectedCategory = this.ss.activeProductGroup.id
      let group = this.ss.store.productGroups.find(
        (el) => el.id == this.selectedCategory
      );
      console.log(group, this.selectedCategory);
      if (group) {
        this.menuStore.update({
          allGroups: this.ss.store.productGroups,
          selectedGroup: this.selectedGroup,
          groups: this.filteredProductGroups,
          infiniteProducts: this.filteredProducts(),
          products: this.filteredProducts().filter(
            (p) => p.group == this.selectedGroup
          ),
          intersectingGroup: this.selectedGroup,
        });
        this.selectedGroup = this.selectedCategory;

        setTimeout(() => {
          this.selectGroup(group);
        }, 800);
      }
    } else {
      this.menuStore.update({
        allGroups: this.ss.store.productGroups,
        selectedGroup: this.filteredProductGroups[0],
        groups: this.filteredProductGroups,
        infiniteProducts: this.filteredProducts(),
        products: this.filteredProducts().filter(
          (p) => p.group == this.selectedGroup
        ),
        intersectingGroup: this.filteredProductGroups[0],
      });
      this.selectedGroup = this.filteredProductGroups[0].id;
      setTimeout(() => {
        this.selectGroup(this.filteredProductGroups[0]);
      }, 500);
    }
  }

  selectGroup(item: ProductGroup) {
    let subGroups = this.ss.store.productGroups.filter(o => o.parentID == item.id)
    let products = this.filteredProducts().filter(p => p.group == item.id)

    
    if (subGroups.length > 0) {
      products = this.filteredProducts().filter(p => p.group == subGroups[0].id)
    }
    this.menuStore.update({
      giftSelected: false,
      intersectingGroup: item,
      products: products,
      selectedGroup: item, selectedSubGroup: subGroups[0] || null, subGroups: subGroups,
      groups: this.filteredProductGroups
    })
    this.groupSelected.emit(item);
  }

  selectSubGroup(item: ProductGroup) {
    this.menuStore.update({
      giftSelected: false,
      products: this.filteredProducts().filter(o => o.group == item.id),
      selectedSubGroup: item
    })

    this.subGroupSelected.emit(item);
  }

  getSubGroups(id) {
    let pg = this.ss.store.productGroups.filter(p => p.parentID == id)
    return pg
  }

  hasSubGroup(item: ProductGroup) {
    let subGroup = this.ss.store.productGroups.filter(o => o.parentID == item.id)
    if (subGroup.length > 0) {
      setTimeout(() => {
        this.selectSubGroup(subGroup[0])
        this.scrollSubGroup(subGroup[0])
      }, 100);
    }
  }

  scrollSubGroup(item: ProductGroup) {
    try {
      let id = "sub-cover" + item.id
      setTimeout(() => {
        document.getElementById(id).scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
      }, 200);

    } catch (error) {

    }
  }

  gifts() {
    this.menuStore.update({ giftSelected: true })
  }

  prev() {

    if (this.menuStore.getValue().subGroups.length > 0) {
      let six = this.menuStore.getValue().subGroups.findIndex(p => p.id == this.menuStore.getValue().selectedSubGroup.id)
      if (six > 0) {
        six--
        this.selectSubGroup(this.menuStore.getValue().subGroups[six])
        return
      }
    }


    let ix = this.filteredProductGroups.findIndex(o => o.id == this.menuStore.getValue().selectedGroup.id)
    if (ix == 0) return;
    ix--
    this.selectGroup(this.filteredProductGroups[ix])
  }

  next() {
    if (this.menuStore.getValue().subGroups.length > 0) {
      let six = this.menuStore.getValue().subGroups.findIndex(p => p.id == this.menuStore.getValue().selectedSubGroup.id)
      if (six < (this.menuStore.getValue().subGroups.length - 1)) {
        six++
        this.selectSubGroup(this.menuStore.getValue().subGroups[six])
        return
      }
    }

    let ix = this.filteredProductGroups.findIndex(o => o.id == this.menuStore.getValue().selectedGroup.id)
    if (ix == (this.filteredProductGroups.length - 1)) return;
    ix++
    this.selectGroup(this.filteredProductGroups[ix])
  }

  scrollCustomImplementation(element: HTMLElement) {
    let start = null;
    let target = element && element ? element.getBoundingClientRect().left : 0;
    let firstPos = window.pageXOffset || document.documentElement.scrollLeft;
    let pos = 0;

    (function () {
      var browser = ['ms', 'moz', 'webkit', 'o'];

      for (var x = 0, length = browser.length; x < length && !window.requestAnimationFrame; x++) {
        window.requestAnimationFrame = window[browser[x] + 'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[browser[x] + 'CancelAnimationFrame'] || window[browser[x] + 'CancelRequestAnimationFrame'];
      }
    })();

    function showAnimation(timestamp) {
      if (!start) {
        start = timestamp || new Date().getTime();
      } //get id of animation


      var elapsed = timestamp - start;
      var progress = elapsed / 600; // animation duration 600ms
      //ease in function from https://github.com/component/ease/blob/master/index.js

      var outQuad = function outQuad(n) {
        return n * (2 - n);
      };

      var easeInPercentage = +outQuad(progress).toFixed(2); // if target is 0 (back to top), the position is: current pos + (current pos * percentage of duration)
      // if target > 0 (not back to top), the positon is current pos + (target pos * percentage of duration)

      pos = target === 0 ? firstPos - firstPos * easeInPercentage : firstPos + target * easeInPercentage;
      window.scrollTo(pos, 0);

      if (target !== 0 && pos >= firstPos + target || target === 0 && pos <= 0) {
        cancelAnimationFrame(start);

        /*  if (element) {
           element.setAttribute("tabindex", -1);
           element.focus();
         } */

        pos = 0;
      } else {
        window.requestAnimationFrame(showAnimation);
      }
    }

    window.requestAnimationFrame(showAnimation);
  }

  handleInput(event) {

    const query = event.target.value.toLowerCase();
    if (query.length == 0) {
      this.menuStore.update({ searchedProducts: [], searchKeyword: "" });
      return;
    }

    requestAnimationFrame(() => {

      let prdEqual = this.filteredProducts().filter(p => p.name.toLowerCase() == query)
      let prdContains = this.filteredProducts().filter(p => p.name.toLowerCase().indexOf(query) > -1)

      let prdDesc = this.filteredProducts().filter(p => p.description?.toLowerCase().indexOf(query) > -1)

      let prd = []

      prd.unshift(...prdEqual, ...prdContains, ...prdDesc)




      //let prd = this.ss.store.products.filter(p => p.name.toLowerCase().indexOf(query) > -1 || p.description?.toLowerCase().indexOf(query) > -1 ) 

      let grp = this.ss.store.productGroups.filter(o => {
        return prd.find(x => x.group == o.id)
      })

      let fl = this.ss.store.productGroups.filter(o => {
        return grp.find(g => g.id == o.id) || grp.find(x => x.id == o.parentID)
      })

      this.menuStore.update({ searchedProducts: prd, searchKeyword: query })
      Keyboard.hide()
    });

  }

  key(e) {
    if (e.key == "Enter") {
      Keyboard.hide()
    }
  }

  clearSearch() {
    this.menuStore.update({ searchKeyword: "", searchedProducts: [] })
  }

  filteredProducts() : Product[] {
    if (this.ss.action == "delivery") {
      return this.ss.store.products.filter(o => o.isShowDelivery == true)
    } else {
      return this.ss.store.products.filter(o => o.isShowInStore == true)
    }
  }
}

