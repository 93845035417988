import { Injectable } from "@angular/core";
import {
  Attributes,
  CheckInInfo,
  mainActions,
  MyPlace,
  PaymentType,
  Store,
  StoreStatuses,
  Ticket,
  WorkingHours,
} from "../dto/orwi-store";
import { OrwiService } from "../orwi.service";
import { UserService } from "../user/user.service";
import * as moment from "moment";
import {
  Modifier,
  ModifierCover,
  ModifierProduct,
  Product,
  ProductGroup,
} from "../dto/orwi-product";
import { ServiceType } from "../dto/orwi-folio";
import { NavController, Platform } from "@ionic/angular";
import { GlobalService } from "../global.service";
import { StoreEventsService } from "../events/store-events.service";
import { TranslocoService } from "@ngneat/transloco";
import { OrwiPromptService } from "src/app/components/ui/orwi-prompt/orwi-prompt.service";
import { FbService } from "../events/fb.service";
import { IFeedBack, PointInfo, StaffInfo } from "../dto/orwi-user";
import { UserEventsService } from "../events/user-events.service";
import { MenuStore } from "src/app/state/menu.store";
import { TicketMaster } from "src/app/models/ticker-master";
@Injectable({
  providedIn: "root",
})
export class StoreService {
  attributes: Attributes[];

  tableId;
  tableName;
  schedulerTime;
  schedulerNote;
  addressId;
  minAmount = 0;
  mainStoreId = "61757166bcd20b2a905f910b";
  plajStoreId="6634aa84f859922d6729ce36";
  // Store Ana Sayfasından
  // Menü görüntüle
  // Delivery
  // takeaway
  // readytable
  // Qr Okutarak Gelebilirim
  // Deeplink ile gelebilirim

  action: mainActions = "only-menu";
  status: StoreStatuses = "open";

  activeProduct: Product;
  activeProductGroup: ProductGroup;
  activeStoreId = "";
  storeBanners: TicketMaster[] = [];
  store: Store;
  mainStoreBanners = [];
  mainStore: Store;
  myPlace: MyPlace[] = [];

  paymentTypes: PaymentType[] = [];

  constructor(
    private fb: FbService,
    private se: StoreEventsService,
    private glb: GlobalService,
    private nav: NavController,
    private transloco: TranslocoService,
    private op: OrwiPromptService,
    private plt: Platform,
    private ue: UserEventsService,
    private orwiService: OrwiService,
    private us: UserService,
    private menuStore: MenuStore
  ) {
    this.getAttributes();

    this.ue.userEvents.subscribe(async (o) => {
      console.log("user-event", o);
      this.calcStamps();
    });
  }

  async calcStamps() {
    await this.getMainStoreInfo(this.mainStoreId);
    this.us.initPoints();

    let c = this.mainStore.properties.stampConversionRate;
    let p = this.us.stampPoint.balance / c;
    let gift = Math.floor(p);
    let y = p - gift;
    let currentStamp = Math.round(c * y);
    let gval = (currentStamp / c) * 100;

    console.log({ currentStamp: currentStamp, gift: gift });

    this.us.stampPoint.stamp = currentStamp;
    this.us.stampPoint.gift = gift;
    this.us.stampPoint.percent = gval;
    this.us.stampPoint.conversionRate = c;

    console.log("ssssss----->", this.us.stampPoint);
  }

  async initMainStore() {
    if (this.mainStoreId !== "") {
      this.mainStore = await this.getMainStoreInfo(this.mainStoreId);
      console.log(this.mainStore);
    }
  }

  getMainStoreInfo(parentId): Promise<Store> {
    return new Promise(async (resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          "/api/store/storeInfo",
          { id: parentId },
          this.us.token
        )
        .then(async (o: any) => {
          this.mainStore = Object.assign({}, new Store(), o.response);
          resolve(this.mainStore);
        });
    });
  }

  fetchStoreInfo(id, menu = true): Promise<Store> {
    return new Promise(async (resolve, reject) => {
      if (this.store && this.store.id == id) {
        if (menu) {
          await this.fetchMenu(id, false);
          resolve(this.store);
        } else {
          resolve(this.store);
        }
      } else {
        this.orwiService
          .serviceRequestPromise(
            "/api/store/storeInfo",
            { id: id },
            this.us.token
          )
          .then(async (o: any) => {
            this.store = Object.assign({}, new Store(), o.response);
            if (menu) {
              await this.fetchMenu(id, false);
              resolve(this.store);
            } else {
              resolve(this.store);
            }
          });
      }
    });
  }

  async fetchMenu(id, reload = false) {
    return new Promise((resolve, reject) => {
      if (
        this.store &&
        this.store.id == id &&
        this.store.products?.length > 0
      ) {
        resolve(this.store);
      } else {
        this.orwiService
          .serviceRequest("/api/store/storeMenu", { id: id }, this.us.token)
          .subscribe((o: any) => {
            if (o.response) {
              //this.fetchGroupImages()
              let menu: any = Object.assign(o.response);
              
              this.store.products = menu.products;
              this.store.productGroups = menu.productGroups;
              this.store.productModifiers = menu.productModifiers;
              this.store.productGroupModifiers = menu.productGroupModifiers;
              this.store.advices = menu.advices;
              this.menuStore.update({
                products: menu.products,
                groups: menu.productGroups.filter(
                  (x: any) => x.parentID === "0"
                ),
                subGroups: menu.productGroups.filter(
                  (x: any) => x.parentID !== "0"
                ),
              });

              this.store.products.map((o) => {
                o["couponCode"] = "";
              });

              resolve(this.store);

              if (!reload) {
                this.store.products.map((o) => {
                  //o.image = this.glb.tempProductImage
                  //o.smallImage = this.glb.tempProductImage
                });
              }
            } else if (o.error) {
              this.glb.closeLoading();
              this.glb.toast(o.error, o.error.desc, "bottom", "warning");
            }
          });
      }
    });
  }

  fetchGroupImages() {
    this.orwiService
      .serviceRequest(
        "/api/image/productGroups",
        { id: this.mainStoreId },
        this.us.token
      )
      .subscribe((o: any) => {
        this.store.productGroupImages = Object.assign(o.response);
        this.menuStore.update({
          productGroupImages: Object.assign(o.response),
        });
      });
  }

  getStoreLogo(storeId) {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          "/api/image/imageStore",
          { id: storeId },
          this.us.token
        )
        .then((o: any) => {
          resolve(o.response);
        });
    });
  }

  getStoreBanners(storeId) {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequest("/api/image/storeBanners", { id: storeId })
        .subscribe((o: any) => {
          this.storeBanners = Object.assign(o.response);
          resolve(this.storeBanners);
        });
    });
  }

  // /api/myPlaces/getMyPlaces
  getMyPlace(): Promise<any[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequest("/api/myPlaces/getMyPlaces", {}, this.us.token)
        .subscribe((o: any) => {
          this.myPlace = Object.assign(o.response);
          console.log("myPlaces", this.myPlace);
          resolve(this.myPlace);
        });
    });
  }

  saveMyPlace(storeId) {
    return new Promise((resolve, reject) => {
      // debugger
      this.orwiService
        .serviceRequest(
          "/api/myPlaces/saveMyPlace",
          { storeId: storeId },
          this.us.token
        )
        .subscribe((o: any) => {
          resolve(Object.assign(o.response));
          this.getMyPlace();
        });
    });
  }

  deleteMyPlace(storeId) {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequest(
          "/api/myPlaces/deleteMyPlaces",
          { storeId: storeId },
          this.us.token
        )
        .subscribe((o: any) => {
          resolve(Object.assign(o.response));
          this.getMyPlace();
        });
    });
  }

  getMainStoreBanners() {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequest("/api/image/storeBanners", { id: this.mainStoreId })
        .subscribe((o: any) => {
          this.mainStoreBanners = Object.assign(o.response);
          resolve(this.mainStoreBanners);
        });
    });
  }

  _getOpenClose(ps: WorkingHours) {
    let hrO = parseInt(ps.open.split(":")[0]);
    let mnO = parseInt(ps.open.split(":")[1]);

    let open = moment().hour(hrO);
    open.minute(mnO);

    let hrC = parseInt(ps.close.split(":")[0]);
    let mnC = parseInt(ps.close.split(":")[1]);

    let close = moment().hour(hrC);
    close.minute(mnC);
    let status = moment().isBetween(open, close);

    return { text: ps.open + "-" + ps.close, status: status };
  }

  getOpenClose(store: Store) {
    if (!store || !store.workingHours || store.workingHours.length == 0)
      return { text: "00:00" + "-" + "00:00", status: false };
    let s = moment().weekday();
    let ps = store.workingHours[s];
    return this._getOpenClose(ps);
  }

  fetchProductImagePromise(itemId, size): Promise<any> {
    let prd: Product = this.store.products.find((z) => z.id == itemId);
    let image = prd.image;
    if (size == "small") {
      image = prd.smallImage;
    }

    if (
      (image == undefined || image == "" || image.indexOf("no-image") != -1) &&
      prd.vat != 99
    ) {
      prd.vat = 99;
      return new Promise((resolve, reject) => {
        return this.orwiService
          .serviceRequestPromise(
            "/api/image/product",
            { id: this.store.id, productId: itemId, size: size },
            this.us.token
          )
          .then(
            (o: any) => {
              prd.vat = 0;
              if (o.response == null || o.response == "") {
                image = "error";
                resolve("error");
              } else {
                image =
                  Object.assign(o.response).base64 == ""
                    ? "error"
                    : Object.assign(o.response).base64;
                if (image == "error") return resolve("error");
                return resolve(image);
              }

              if (size == "small") {
                prd.smallImage = image;
              } else {
                prd.image = image;
              }

              if (prd.smallImage == "error") {
                prd.smallImage = "/assets/images/home/400x250.png";
              }

              if (prd.image == "error") {
                prd.image = "/assets/images/home/400x250.png";
              }
              resolve(image);
            },
            (e) => {
              if (size == "small") {
                prd.smallImage = "error";
              } else {
                prd.image = "error";
              }

              if (prd.smallImage == "error") {
                prd.smallImage = "/assets/images/home/400x250.png";
              }

              if (prd.image == "error") {
                prd.image = "/assets/images/home/400x250.png";
              }

              return resolve(prd.image);
            }
          );
      });
    } else {
      return new Promise((resolve) => {
        return resolve(image);
      });
    }
  }

  fetchProductImage(itemId, size) {
    let prd: Product = this.store.products.find((z) => z.id == itemId);
    let image = prd.image;
    if (size == "small") {
      image = prd.smallImage;
    }

    if (
      (image == undefined || image == "" || image.indexOf("no-image") != -1) &&
      prd.vat != 99
    ) {
      prd.vat = 99;
      this.orwiService
        .serviceRequest(
          "/api/image/product",
          { id: this.store.id, productId: itemId, size: size },
          this.us.token
        )
        .subscribe(
          (o: any) => {
            prd.vat = 0;
            if (o.response == null || o.response == "") {
              image = "error";
            } else {
              image =
                Object.assign(o.response).base64 == ""
                  ? "error"
                  : Object.assign(o.response).base64;
            }
            if (size == "small") {
              prd.smallImage = image;
            } else {
              prd.image = image;
            }

            if (prd.smallImage == "error") {
              prd.smallImage = "/assets/images/home/400x250.png";
            }

            if (prd.image == "error") {
              prd.image = "/assets/images/home/400x250.png";
            }
          },
          (e) => {
            if (size == "small") {
              prd.smallImage = "error";
            } else {
              prd.image = "error";
            }

            if (prd.smallImage == "error") {
              prd.smallImage = "/assets/images/home/400x250.png";
            }

            if (prd.image == "error") {
              prd.image = "/assets/images/home/400x250.png";
            }
          }
        );
    }
    return image;
  }

  getModifiers(
    productID,
    productGroupID,
    st: ServiceType = "table"
  ): Modifier[] {
    let groups: ModifierCover[];
    let products: ModifierCover[];
    let combine: Modifier[] = [];
    products = this.store.productModifiers.filter(
      (o) => o.productID == productID
    ); // await Object.assign(this.cs.readDocumentFromMongo('bigchefs/menu-product_modifiers', { productID: productID }).toPromise())
    groups =
      this.store.productGroupModifiers.filter(
        (o) => o.productGroupID == productGroupID
      ) || []; //await Object.assign(this.cs.readDocumentFromMongo('bigchefs/menu-menu-product_group_modifiers', { productGroupID: productGroupID }).toPromise())
    for (const iterator of products) {
      for (const it of iterator.modif) {
        combine.push(it);
      }
    }
    for (const iterator of groups) {
      for (const it of iterator.modif) {
        combine.push(it);
      }
    }

    let custom = combine.filter((o) => o.type == "custom");
    let product = combine.filter((o) => o.type == "product");
    let group = combine.filter((o) => o.type == "group");

    for (const iterator of product) {
      for (const it of iterator.products) {
        let prd = this.store.products.find((o) => o.id == it.productID);
        if (prd) {
          it.productName = prd.name;
        }
      }
    }

    for (const iterator of group) {
      iterator.products = [];

      let prd = this.store.products.filter(
        (o) => o.group == iterator.productGroup
      );

      for (const it of prd) {
        let mp: ModifierProduct = new ModifierProduct();
        mp.productName = it.name;
        mp.productID = it.id;
        mp.canBeAdded = iterator.canBeAdded;
        mp.detachable = iterator.detachable;
        mp.price = iterator.priceless ? 0 : it.price; //! implement service type?
        mp.priceless = iterator.priceless;
        mp.selected = false;
        iterator.products.push(mp);
      }
    }

    combine = [];
    for (const iterator of custom) {
      iterator.products.map((o) => {
        o.selected = false;
        o.temp_rowID = "";
        o.subModifiers = [];
      });
      combine.push(iterator);
    }
    for (const iterator of product) {
      iterator.products.map((o) => {
        o.selected = false;
        o.temp_rowID = "";
        o.subModifiers = [];
      });
      combine.push(iterator);
    }
    for (const iterator of group) {
      iterator.products.map((o) => {
        o.selected = false;
        o.temp_rowID = "";
        o.subModifiers = [];
      });
      combine.push(iterator);
    }

    return combine;
  }

  storeCheckIn(
    code: string,
    time = undefined,
    note = undefined,
    serviceType = undefined,
    addressId = undefined,
    minAmount = 0
  ): Promise<Store> {
    return new Promise((resolve, reject) => {
      if (code.indexOf("/d/") > -1 && this.plt.is("capacitor")) {
        let msg = this.transloco.translate(
          "This is orwi install Qr Code, orwi is already installed. Please read the order Qr Code."
        );

        this.glb.toast("Warning", msg, "middle", "success", 4000);
        reject("no-store");
        return;
      }

      this.glb.showLoading();
      this.orwiService
        .serviceRequestPromise(
          "/api/store/storeCheckIn",
          { qrCode: code, serviceType: serviceType },
          this.us.token
        )
        .then(
          async (o: any) => {
            if (o.response) {
              this.addressId = addressId;
              let response: CheckInInfo = o.response;
              this.activeStoreId = response.store.id;
              this.action = response.startAction;
              this.schedulerNote = note;
              this.schedulerTime = time;
              this.minAmount = minAmount;

              this.fb.store_checkin(
                response.store.id,
                response.store.name,
                response.startAction
              );

              if (response.startAction == "coupon") {
                this.initCoupon(response);
              } else if (response.startAction == "select-address") {
                this.initSelectAddress(response);
              } else if (response.startAction == "delivery") {
                this.initDelivery(response);
              } else if (response.startAction == "in-store") {
                this.initInStore(response);
              } else if (response.startAction == "loyalty") {
              } else if (response.startAction == "only-menu") {
                this.initInStore(response);
              } else if (response.startAction == "payment") {
              } else if (response.startAction == "pre-paid") {
                this.us.savelog("PrePaid !!");
                this.glb.closeLoading();
              } else if (response.startAction == "self-ordering") {
              } else if (response.startAction == "take-away") {
                this.initTakeaway(response);
              } else if (response.startAction == "store-page") {
                this.initStorePage(response);
              }
            }

            if (o.error) {
              this.glb.closeLoading();
              if (o.error.code == "table_not_found") {
                this.showNoOrwiQr(code);
              } else {
                this.glb.toast("Opps!", o.error.desc, "middle", "warning");
              }
            }
          },
          (e) => {
            reject(e);
          }
        );
    });
  }
  initStorePage(ci: CheckInInfo) {
    this.action = ci.startAction;
    this.activeStoreId = ci.store.id;
    this.glb.closeLoading();
    this.nav.navigateForward("store-home").then((o) => {
      this.glb.closeLoading();
    });
  }

  showNoOrwiQr(code: string) {
    this.fb.undefined_qr(code);
    let title = this.transloco.translate("Unidentified Qr Code");
    //bu qr eşleme hatası verdi, düzelmemiz için nerede okuttuğunu yazar mısın.
    let msg = this.transloco.translate(
      "this gave a qr matching error, can you write where you read it so we can fix it."
    );
    //işletme adı / ilçe / il
    let placeholder = this.transloco.translate(
      "business name / town / province"
    );

    if (code.indexOf("orwi.app")) {
      let ps = this.op.showComponent({
        title: title,
        message: msg,
        inputs: [{ placeholder: placeholder, id: "message", type: "textarea" }],
      });

      ps.click.subscribe((o) => {
        if (o.inputs) {
          let val = o.inputs.find((o) => o.id == "message");
          if (val?.value) {
            this.us.savelog("Tanımsız Qr :" + code + " Mekan :" + val.value);

            let fb: IFeedBack = {
              name: "orwi-bot",
              phone: "",
              status: "",
              email: "",
              message: "Qr :" + code + "<br>Mekan :" + val.value,
              userId: "",
              feedbackCats: [],
              storeId: "none",
              id: "",
            };

            this.us.saveFeedBack(fb).then((o) => {});
          }
        }

        ps.closeClick.emit();
      });
    } else {
      this.glb.toast(code, title, "middle", "warning");
    }

    //Tanımlanamayan Qr Code

    //this.glb.toast("", title, "middle", "warning")

    //
    //let message = this.transloco.translate("")
  }

  initCoupon(ci: CheckInInfo) {
    this.glb.closeLoading();

    if (ci.coupon.couponType == "store-point") {
      let header =
        +this.transloco.translate("Point Loded ") + " " + ci.store.name;
      let msg =
        ci.coupon.amount +
        this.transloco.translate(
          " point loaded, you can use the payment page, enjoy!"
        );
      this.glb.toastButton(
        header,
        msg,
        "middle",
        "success",
        false,
        false,
        true
      );
      this.us.savelog(ci.coupon.amount + " point loaded at " + this.store.name);
    }
  }

  initSelectAddress(ci: CheckInInfo) {
    this.glb.closeLoading();
    this.us.checkActiveUser((allreadyLoggedIn) => {
      this.action = ci.startAction;
      this.store = ci.store;
      this.nav.navigateForward("address-list", {
        replaceUrl: !allreadyLoggedIn,
      });
    });
  }

  initDelivery(ci: CheckInInfo) {
    this.action = ci.startAction;
    this.store = ci.store;
    this.tableId = ci.id;
    this.se.storeChecked.next("");
    this.fetchMenu(ci.store.id).then((o) => {
      this.nav.navigateForward("menu-home", {
        replaceUrl: !this.us.user.openCheckIn,
      });
      this.glb.closeLoading();
    });
  }

  initTakeaway(ci: CheckInInfo) {
    this.action = ci.startAction;
    this.store = ci.store;
    this.tableId = ci.id;
    this.se.storeChecked.next("");
    this.fetchMenu(ci.store.id).then((o) => {
      this.nav.navigateForward("menu-home");
      this.glb.closeLoading();
    });
  }

  initInStore(ci: CheckInInfo) {
    this.action = ci.startAction;
    this.store = ci.store;
    this.tableId = ci.id;
    this.tableName = ci.tableName;
    this.se.storeChecked.next("");
    this.fetchMenu(ci.store.id).then((o) => {
      this.glb.closeLoading();
      this.nav
        .navigateForward([
          "in-store-home",
          { tableId: ci.id, tableName: ci.tableName },
        ])
        .then((o) => {
          this.glb.closeLoading();
        });
    });
  }

  getAttributes() {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise("/api/category/getAttributes", {}, this.us.token)
        .then((o: any) => {
          if (o.response) {
            this.attributes = o.response;
          }
        });
    });
  }

  getPointTitle() {
    return this.store?.pointTitle || this.transloco.translate("Store Point");
  }

  fetchPaymentTypes(id) {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          "/api/pos/payment/getPaymentTypes",
          { id: id },
          this.us.token
        )
        .then((o: any) => {
          if (o.response) {
            this.paymentTypes = o.response;
            resolve(this.paymentTypes);
          }
        });
    });
  }

  getStoreStatus(id): Promise<StoreStatuses> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          "/api/store/getStoreStatus",
          { id: id },
          this.us.token
        )
        .then((o: any) => {
          if (o.response) {
            this.status = o.response.status;
            if (this.store && this.store.id == id) {
              this.store.status = this.status;
            }
            resolve(this.status);
          }
        });
    });
  }

  becomeOrwiStore(id): Promise<any> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          "/api/helper/becomeOrwiFeedback",
          { id: id },
          this.us.token
        )
        .then((o: any) => {
          resolve(true);
        });
    });
  }

  getStaffInfo(storeId, userId): Promise<StaffInfo> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          "/api/store/getStaffInfo",
          { id: storeId, userId: userId },
          this.us.token
        )
        .then((o: any) => {
          if (o.response) {
            resolve(o.response);
          }
        });
    });
  }

  saveReservation(reservation: Partial<Ticket>): Promise<Ticket> {
    this.glb.showLoading();
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          "/api/reservation/saveReservation",
          reservation,
          this.us.token
        )
        .then((o: any) => {
          this.glb.closeLoading();
          this.glb.toast(
            "",
            this.transloco.translate("Reservation Successfully Saved"),
            "bottom",
            "success"
          );
          if (o.response) {
            // this.modalService.openModal({
            //   component: ReservationResultComponent,
            //   cssClass: "reservation-modal",
            // });
            resolve(o.response);

            return;
          }

          if (o.error?.code) {
            return reject(o.error?.code);
          }
        });
    });
  }

  getReservationsOfUser(): Promise<Ticket[]> {
    return new Promise((resolve, reject) => {
      this.orwiService
        .serviceRequestPromise(
          "/api/reservation/getReservations",
          {},
          this.us.token
        )
        .then((o: any) => {
          if (o.response) {
            resolve(o.response);
          }
        });
    });
  }
}
