import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { GlobalService } from 'src/app/services/global.service';

@Pipe({
  name: 'orwiCurrency'
})
export class OrwiCurrencyPipe implements PipeTransform {
  constructor(private glb: GlobalService) {

  }

  transform(value: number) {
   
    if (!value)  {
      value = 0;
    }
   
    return new CurrencyPipe('tr').transform(value, '₺')
  }

}
