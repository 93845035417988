// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orwi-back {
  z-index: 1000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: var(--ion-safe-area-top);
  position: fixed;
  top: calc(10px + var(--ion-safe-area-top));
  border-radius: 50%;
  -webkit-backdrop-filter: blur(1px);
  backdrop-filter: blur(1px);
  background-color: rgba(4, 4, 4, 0.368627451);
  left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ui/orwi-back/orwi-back.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,eAAA;EACA,0CAAA;EACA,kBAAA;EACA,kCAAA;EACA,0BAAA;EACA,4CAAA;EACA,UAAA;AACJ","sourcesContent":[".orwi-back {\n    z-index: 1000;\n    width: 40px;\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    top: var(--ion-safe-area-top);\n    position: fixed;\n    top: calc( 10px + var(--ion-safe-area-top) );\n    border-radius: 50%;\n    -webkit-backdrop-filter: blur(1px);\n    backdrop-filter: blur(1px);\n    background-color: #0404045e;\n    left: 10px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
