import { Injectable } from '@angular/core';
import "@capacitor-community/firebase-analytics";
// Must import the package once to make sure the web support initializes


import { Plugins } from "@capacitor/core";
import { Platform } from '@ionic/angular';
import * as moment from 'moment';
import { Folio } from '../dto/orwi-folio';
import { Feed } from '../dto/orwi-home';
import { Store } from '../dto/orwi-store';
import { User } from '../dto/orwi-user';

const { FirebaseAnalytics } = Plugins;

@Injectable({
  providedIn: 'root'
})
export class FbService {
  success_tip(user: User, store: Store, folio: Folio) {

    // FirebaseAnalytics.logEvent({
    //   name: "tip",
    //   params: {
    //     status: "success",
    //     store: store.name,
    //     user_id: user.id,
    //     folio_id: folio.id
    //   },
    // })

  }
  cancel_tip(user: User, store: Store, folio: Folio) {

    // FirebaseAnalytics.logEvent({
    //   name: "tip",
    //   params: {
    //     status: "cancel",
    //     store: store.name,
    //     user_id: user.id,
    //     folio_id: folio.id
    //   },
    // })

  }
  skipTip(folio: Folio, user: User, store: Store) {
   
    // FirebaseAnalytics.logEvent({
    //   name: "tip",
    //   params: {
    //     status: "skip",
    //     store: store.name,
    //     user_id: user.id,
    //     folio_id: folio.id
    //   },
    // })
   
  }


  set_masterpass(o: string, user: User) {

    // FirebaseAnalytics.logEvent({
    //   name: "masterpass_status",
    //   params: {
    //     status: o,
    //     userId: user.gsm
    //   },
    // })
    
  }




  init() {

    console.log("init fb")


    if (this.plt.is("capacitor")) {
      // FirebaseAnalytics.initializeFirebase({
      //   apiKey: "AIzaSyCdk2oUzKZL3JXZRICM72pSx2qDHgmHJ7M",
      //   authDomain: "orwi-f2569.firebaseapp.com",
      //   databaseURL: "https://orwi-f2569.firebaseio.com",
      //   projectId: "orwi-f2569",
      //   storageBucket: "orwi-f2569.appspot.com",
      //   messagingSenderId: "76332975717",
      //   appId: "1:76332975717:web:107359d3bd475a6074288c",
      //   measurementId: "G-4KB8D6V1XH"
      // })

      console.log("init fb -> capacitor")

    } else {


      // FirebaseAnalytics.initializeFirebase({
      //   apiKey: "AIzaSyD2hu1FKTmbQjhwOa8LPiH_3hWdrIDSf0A",
      //   authDomain: "orwi-web.firebaseapp.com",
      //   databaseURL: "https://orwi-web.firebaseio.com",
      //   projectId: "orwi-web",
      //   storageBucket: "orwi-web.appspot.com",
      //   messagingSenderId: "118356376255",
      //   appId: "1:118356376255:web:8fbb0ca345700e5a46640a",
      //   measurementId: "G-H4N7LEHKWZ"
      // })

      console.log("init fb -> web")

    }


    // FirebaseAnalytics.setCollectionEnabled({
    //   enabled: true,
    // });

    // FirebaseAnalytics.enable()


   


  }

  locationRequest(allow) {
    // FirebaseAnalytics.logEvent({
    //   name: "location_request",
    //   params: {
    //     allow_gps: allow
    //   },
    // })
  }

  undefined_qr(code: string) {
    // FirebaseAnalytics.logEvent({
    //   name: "undefined_qr",
    //   params: {
    //    code: code,
    //    platform: this.getPlatform()
    //   },
    // })
  }

  download_orwi(code: string) {
    // FirebaseAnalytics.logEvent({
    //   name: "download_orwi",
    //   params: {
    //    code: code,
    //    platform: this.getPlatform()
    //   },
    // })
  }

  customLocation(allow) {
    // FirebaseAnalytics.logEvent({
    //   name: "location_request_custom"
    // })
  }

  login(id, type) {

    // FirebaseAnalytics.setUserId({
    //   userId: id,
    // });

/*     FirebaseAnalytics.setUserProperty({
      name: "active_store",
      value: ,
    }); */

    // FirebaseAnalytics.logEvent({
    //   name: "login",
    //   params: {
    //     user_id: id,
    //     platform: this.getPlatform(),
    //     type: type,
    //     timestamp: moment().toISOString()
    //   },
    // })
  }

  signUp(id) {
    // FirebaseAnalytics.logEvent({
    //   name: "sign_up",
    //   params: {
    //     user_id: id,
    //     platform: this.getPlatform(),
    //     timestamp: moment().toISOString()
    //   },
    // })
  }

  categoryFilter(filter) {
    // FirebaseAnalytics.logEvent({
    //   name: "category_filter",
    //   params: {
    //     filter: filter
    //   },
    // })
  }

  up_tab(tab) {
    // FirebaseAnalytics.logEvent({
    //   name: "up_tab",
    //   params: {
    //     up_tab_pick: tab,
    //     platform: this.getPlatform()
    //   },
    // })
  }

  search_product(store, text, type) {
    // FirebaseAnalytics.logEvent({
    //   name: "search_product",
    //   params: {
    //     search_store: store,
    //     search_text: text
    //   },
    // })
  }

  search_store(text) {
    // FirebaseAnalytics.logEvent({
    //   name: "search_store",
    //   params: {
    //     search_text: text,
    //   },
    // })
  }

  select_feed(feed: Feed) {
    // FirebaseAnalytics.logEvent({
    //   name: "select_feed",
    //   params: {
    //     id: feed.id,
    //     title: feed.title,
    //     type: feed.type,
    //     platform: this.getPlatform()
    //   },

    // })
  }

  fab_button(button) {
    // FirebaseAnalytics.logEvent({
    //   name: "fab_button",
    //   params: {
    //     button: button,
    //     platform: this.getPlatform()
    //   },

    // })
  }

  category(name) {
    // FirebaseAnalytics.logEvent({
    //   name: "category",
    //   params: {
    //     category_pick: name,
    //   },

    // })
  }

/*   point(name) {
    FirebaseAnalytics.logEvent({
      name: "point",
      params: {
        store_pick: name,
        timestamp: moment().toISOString()
      },

    })
  } */

  scan_qr(userId, storeName, storeId, parentName) {
    // FirebaseAnalytics.logEvent({
    //   name: "scan_qr",
    //   params: {
    //     user_id: userId,
    //     store_name: storeName,
    //     store_id: storeId,
    //     parent_name: parentName,
    //     timestamp: moment().toISOString(),
    //     hour: moment().format('HH').toString()
    //   },
    // })
  }

  show_my_qr(userId) {
    // FirebaseAnalytics.logEvent({
    //   name: "scan_qr",
    //   params: {
    //     user_id: userId,
    //     timestamp: moment().toISOString(),
    //     hour: moment().format('HH').toString()
    //   },
    // })
  }

  scan_coupon(userId, storeName, storeId) {
    // FirebaseAnalytics.logEvent({
    //   name: "scan_coupon",
    //   params: {
    //     user_id: userId,
    //     store_name: storeName,
    //     storeId: storeId,
    //     timestamp: moment().toISOString(),
    //     hour: moment().format('HH').toString()
    //   },
    // })
  }

  my_area(userId, stores, gifts, stamps, coupon) {
    // FirebaseAnalytics.logEvent({
    //   name: "my_area",
    //   params: {
    //     user_id: userId,
    //     my_stores: stores,
    //     orwi_point: stores,
    //     my_gifts: gifts,
    //     my_stamps: stamps,
    //     add_coupon: coupon
    //   },
    // })
  }

  store_checkin(id, name, action) {
    // FirebaseAnalytics.logEvent({
    //   name: "store_check_in",
    //   params: {
    //     id: id,
    //     name: name,
    //     action: action,
    //     platform: this.getPlatform()
    //   },
    // })
  }

  address_connect(userId, addressCount) {
    // try {
    //   FirebaseAnalytics.logEvent({
    //     name: "address_connect",
    //     params: {
    //       user_id: userId,
    //       add_address: addressCount
    //     },
    //   })
    // } catch (err) {
    //   console.error(err)
    // }
  }

  mp_connect(userId) {
    // FirebaseAnalytics.logEvent({
    //   name: "mp_connect",
    //   params: {
    //     user_id: userId,
    //   },
    // })
  }

  call_waiter(userId, storeName, message) {

    // FirebaseAnalytics.logEvent({
    //   name: "call_waiter",
    //   params: {
    //     user_id: userId,
    //     store_name: storeName,
    //     waiter_message: message
    //   },
    // })
  }

  select_item(itemName, itemListName, itemListId, storeName) {
    // FirebaseAnalytics.logEvent({
    //   name: "select_item",
    //   params: {
    //     item_name: itemName,
    //     item_list_name: itemListName,
    //     item_list_id: itemListId,
    //     store_name: storeName
    //   },
    // })
  }

  add_to_cart(itemName, itemListName, itemListId, storeName, currency, value) {
    // FirebaseAnalytics.logEvent({
    //   name: "add_to_cart",
    //   params: {
    //     item_name: itemName,
    //     item_list_name: itemListName,
    //     item_list_id: itemListId,
    //     store_name: storeName,
    //     currency: currency,
    //     value: value
    //   },
    // })
  }

  setOrder(order: Folio, storeName) {
  
      // FirebaseAnalytics.logEvent({
      //   name: "order",
      //   params: {
      //     user_id: order.userId,
      //     order_type: order.type,
      //     store_name: storeName,
      //   },
      // })
  }


  modal(name) {
    // FirebaseAnalytics.logEvent({
    //   name: "modal",
    //   params: {
    //     modal_showup: name,
    //     platform: this.getPlatform()
    //   },

    // })
  }


  payment_done(f: Folio, store) {
    // FirebaseAnalytics.logEvent({
    //   name: "payment_done",
    //   params: {
    //     user_id: f.userId,
    //     store_name: store,
    //     platform: this.getPlatform()
    //   },

    // })
  }


  rejectFolio(name: string) {
    // FirebaseAnalytics.logEvent({
    //   name: "reject_folio",
    //   params: {
    //     store_name: name,
    //     platform: this.getPlatform()
    //   },
    // })
  }
  
  assignFolio(name: string) {
    // FirebaseAnalytics.logEvent({
    //   name: "assign_folio",
    //   params: {
    //     store_name: name,
    //     platform: this.getPlatform()
    //   },
    // })
  }




  set_screen_name(name) {
    // FirebaseAnalytics.setScreenName({
    //   screenName: name,
    // });
  }


  //Items 



  view_item(item_name, ms, userid, store_name, value) {
    /*     view_item	currency
      item_name
      value
      item_viewed_time_msec
      parent_name
      store_name
      user_id */


    // FirebaseAnalytics.logEvent({
    //   name: "view_item",
    //   params: {
    //     item_name: item_name,
    //     value: value,
    //     item_viewed_time_msec: ms,
    //     store_name: store_name,
    //     user_id: userid,
    //     platform: this.getPlatform(),
    //     currency: 'tl'
    //   },
    // })



  }

  getPlatform() {
    if (this.plt.is("ios")) {
      return "ios"
    } else if (this.plt.is("android")) {
      return "android"
    } else if (this.plt.is("desktop")) {
      return "desktop"
    } else if (this.plt.is("mobileweb")) {
      return "mobileweb"
    } else {
      return "other"
    }
  }









  denemet() {
    /**
 * Platform: Web/Android/iOS
 * Sets the user ID property.
 * @param userId - unique identifier of a user
 * @returns void
 * https://firebase.google.com/docs/analytics/userid
 */
    FirebaseAnalytics.setUserId({
      userId: "john_doe_123",
    });

    /**
     * Platform: Web/Android/iOS
     * Sets a user property to a given value.
     * @param options - property name and value to set
     * @returns void
     * https://firebase.google.com/docs/analytics/user-properties
     */
    FirebaseAnalytics.setUserProperty({
      name: "favorite_food",
      value: "pizza",
    });

    /**
     * Platform: Android/iOS
     * Retrieves the app instance id from the service.
     * @param none
     * @returns instanceId - individual instance id value
     * https://firebase.google.com/docs/analytics/user-properties
     */
    FirebaseAnalytics.getAppInstanceId();

    /**
     * Platform: Android/iOS
     * Sets the current screen name, which specifies the current visual context in your app.
     * @param screenName - name of the current screen to track
     *        nameOverride - name of the screen class to override
     * @returns instanceId - individual instance id value
     * https://firebase.google.com/docs/analytics/screenviews
     */
    FirebaseAnalytics.setScreenName({
      screenName: "login",
      nameOverride: "LoginScreen",
    });

    /**
     * Platform: Web/Android/iOS
     * Clears all analytics data for this app from the device and resets the app instance id.
     * @param none
     * @returns void
     */
    FirebaseAnalytics.reset();

    /**
     * Platform: Web/Android/iOS
     * Logs an app event.
     * @param name - name of the event to log
     *        params - key/value pairs of properties (25 maximum per event)
     * @returns void
     */
    FirebaseAnalytics.logEvent({
      name: "select_content",
      params: {
        content_type: "image",
        content_id: "P12453",
        items: [{ name: "Kittens" }],
      },
    });

    /**
     * Platform: Web/Android/iOS
     * Sets whether analytics collection is enabled for this app on this device.
     * @param name - enabled - boolean true/false
     * @returns void
     */
    FirebaseAnalytics.setCollectionEnabled({
      enabled: false,
    });

    /**
     * Platform: Web/Android/iOS
     * Deprecated - use setCollectionEnabled() instead
     * Enable analytics collection for this app on this device.
     * @param none
     * @returns void
     */
    FirebaseAnalytics.enable();

    /**
     * Platform: Web/Android/iOS
     * Deprecated - use setCollectionEnabled() instead
     * Disable analytics collection for this app on this device.
     * @param none
     * @returns void
     */
    FirebaseAnalytics.disable();

    /**
     * Platform: Web/Android/iOS
     * Sets the duration of inactivity that terminates the current session.
     * @param duration - duration in seconds (default - 18000)
     * @returns void
     */
    FirebaseAnalytics.setSessionTimeoutDuration({
      duration: 10000,
    });
  }




  constructor(private plt: Platform) { }



}
