import { Injectable } from '@angular/core';
import { Network, NetworkStatus } from '@capacitor/network';
import { KeyboardInfo, Keyboard } from '@capacitor/keyboard';
import { AppInfo, App, AppState } from '@capacitor/app';
import { NavController, Platform } from '@ionic/angular';
import { Subject, Subscription } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AppService {

  connected = false
  appVersion : string = ""
  stateChanged = new Subject<boolean>()
  deeplinkReceived = new Subject<any>()
  keyboardShow = new Subject<any>()
  keyboardHide = new Subject<any>()
  networkChanged = new Subject<any>()
  homePageLoaded: boolean = false;
  deeplinkQrCode = ""

  constructor(private plt: Platform, private nav: NavController) {

    Network.getStatus().then(o => {
      this.connected = o.connected
    })

 

    Network.addListener("networkStatusChange", (state: NetworkStatus) => {
      this.networkChanged.next(state)
      this.connected = state.connected
      if (!state.connected) {
        this.nav.navigateForward("no-internet")
      }
    })

    App.addListener('appStateChange', (state: AppState) => {
      //this.glb.consolelog('App state changed. Is active?', state.isActive);
      this.stateChanged.next(state.isActive)
    });

    App.addListener('appUrlOpen', (data: any) => {
      this.deeplinkReceived.next(data.url)
    })

    if (plt.is("capacitor")) {

      Keyboard.addListener('keyboardWillShow', (info: KeyboardInfo) => {
        this.keyboardShow.next("")

      });

      Keyboard.addListener('keyboardWillHide', () => {
        this.keyboardHide.next("")
      })

    }






  }


}
