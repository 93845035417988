import { Component, Input, OnInit } from '@angular/core';
import { WorkingHours } from 'src/app/services/dto/orwi-store';
import { StoreService } from 'src/app/services/store/store.service';

@Component({
  selector: 'orwi-store-open',
  templateUrl: './store-open.component.html',
  styleUrls: ['./store-open.component.scss'],
})
export class StoreOpenComponent implements OnInit {

  @Input() open : boolean = false
  @Input() time : string = "09:00 - 18:00"
  @Input() wh: WorkingHours
  constructor(private ss: StoreService) {

    
    

   }

  ngOnInit() {

    if (this.wh) {
      this.open = this.ss._getOpenClose(this.wh).status
      this.time = this.ss._getOpenClose(this.wh).text
    }
  }

}
