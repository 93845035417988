// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back {
  z-index: 1000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: var(--ion-safe-area-top);
  position: fixed;
  top: calc(10px + var(--ion-safe-area-top));
  border-radius: 50%;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  background-color: rgba(4, 4, 4, 0.368627451);
  left: 10px;
}

.img-width {
  width: 100%;
}

.contact {
  z-index: 1000;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  top: calc(10px + var(--ion-safe-area-top));
  position: fixed;
  right: 10px;
  border-radius: 50%;
  -webkit-backdrop-filter: blur(1px);
          backdrop-filter: blur(1px);
  background-color: rgba(4, 4, 4, 0.368627451);
}

.back-cover {
  position: fixed;
  top: var(--ion-safe-area-top);
  left: 0px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.4588235294);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/components/ui/slide-header/slide-header.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,6BAAA;EACA,eAAA;EACA,0CAAA;EACA,kBAAA;EACA,kCAAA;UAAA,0BAAA;EACA,4CAAA;EACA,UAAA;AACJ;;AACA;EACI,WAAA;AAEJ;;AACA;EACI,aAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,0CAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,kCAAA;UAAA,0BAAA;EACA,4CAAA;AAEJ;;AACA;EACI,eAAA;EACA,6BAAA;EACA,SAAA;EACA,aAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;EACA,6CAAA;EACA,kCAAA;UAAA,0BAAA;EACA,YAAA;AAEJ","sourcesContent":[".back {\n    z-index: 1000;\n    width: 40px;\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    top: var(--ion-safe-area-top);\n    position: fixed;\n    top: Calc( 10px + var(--ion-safe-area-top) );\n    border-radius: 50%;\n    backdrop-filter: blur(1px);\n    background-color: #0404045e;\n    left: 10px;\n}\n.img-width {\n    width: 100%;\n}\n\n.contact {\n    z-index: 1000;\n    width: 40px;\n    height: 40px;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    top: Calc( 10px + var(--ion-safe-area-top) );\n    position: fixed;\n    right: 10px;\n    border-radius: 50%;\n    backdrop-filter: blur(1px);\n    background-color: #0404045e;\n}\n\n.back-cover {\n    position: fixed;\n    top: var(--ion-safe-area-top);\n    left: 0px;\n    z-index: 1000;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n    background-color: #00000075;\n    backdrop-filter: blur(2px);\n    color: white;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
