// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (prefers-color-scheme: dark) {
  .open {
    color: limegreen;
    font-size: 16px;
  }
  .close {
    color: pink;
    font-size: 16px;
  }
}
.open {
  color: green;
  font-size: 16px;
}

.close {
  color: red;
  font-size: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home/store-open/store-open.component.scss"],"names":[],"mappings":"AAAA;EACI;IACE,gBAAA;IACA,eAAA;EACJ;EAEE;IACE,WAAA;IACA,eAAA;EAAJ;AACF;AAGE;EACE,YAAA;EACA,eAAA;AADJ;;AAIE;EACE,UAAA;EACA,eAAA;AADJ","sourcesContent":["@media (prefers-color-scheme: dark) {\n    .open {\n      color: limegreen;\n      font-size: 16px;\n    }\n  \n    .close {\n      color: pink;\n      font-size: 16px;\n    }\n  }\n  \n  .open {\n    color: green;\n    font-size: 16px;\n  }\n  \n  .close {\n    color: red;\n    font-size: 16px;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
