// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require("../../package.json").version + "-dev",
  production: false,
  baseUrl: "https://beltur.orwi.app",
  ibbConfig: {
    host: "https://ibb03.api.ibb-prod.istanbulsenin.kobil.com/digitanium/v2",
    client_id: "beltur",
    redirect_uri: "https://beltur.orwi.app/ibb-code-verify",
    website: "https://beltur.orwi.app",
    nonce: "384acbuyt85",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
