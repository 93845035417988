import { Pipe, PipeTransform } from "@angular/core";
import { Feed } from "src/app/services/dto/orwi-home";

@Pipe({
  name: "uniqueStore",
})
export class UniqueStorePipe implements PipeTransform {
  transform(feeds: Feed[]): Feed[] {
    return [...new Map(feeds.map((item) => [item["id"], item])).values()];
  }
}
