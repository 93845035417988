import { Component, OnInit } from '@angular/core';
import { UserEventsService } from 'src/app/services/events/user-events.service';
import { StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-beltur-coffee',
  templateUrl: './beltur-coffee.component.html',
  styleUrls: ['./beltur-coffee.component.scss'],
})
export class BelturCoffeeComponent implements OnInit {

  loggedIn = false
  constructor(public us: UserService, public ss: StoreService, private ue: UserEventsService) {

    this.ue.userEvents.subscribe(o => {
     
      setTimeout(() => {

        if (o == "logged-out") {
          this.loggedIn = false
        } else if (o == "logged-in") {
          this.loggedIn = true
        }

       
      }, 2000);
    })

    if (us.user && us.user.isVirtual == false) {
      this.loggedIn = true
    }

  }

  ngOnInit() {
    // setTimeout(() => {
    // this.us.stampPoint.stamp = 5;
    //   this.us.stampPoint.percent = 50;
    //   this.us.stampPoint.conversionRate = 10;
    // }, 2000);
  }

}
