// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rating-object {
  display: flex;
  font-size: 30px;
}

.selected-rating {
  color: var(--ion-color-danger);
}

.unselected-rating {
  color: var(--ion-color-danger-tint);
}`, "",{"version":3,"sources":["webpack://./src/app/components/store/rating/rating.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;AACJ;;AAEA;EACA,8BAAA;AACA;;AAEA;EACI,mCAAA;AACJ","sourcesContent":[".rating-object {\n    display: flex;\n    font-size: 30px;\n}\n\n.selected-rating {\ncolor: var(--ion-color-danger);\n}\n\n.unselected-rating {\n    color: var(--ion-color-danger-tint);\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
