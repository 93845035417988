import { Component } from "@angular/core";

import { MenuController, NavController, Platform } from "@ionic/angular";
//import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { UserService } from "./services/user/user.service";

import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { GlobalService } from "./services/global.service";
import { PushService } from "./services/helpers/push.service";
import { OrwiService } from "./services/orwi.service";
import { environment } from "../environments/environment";
import { UIStore } from "./state/ui/ui.store";
import { LocationStrategy } from "@angular/common";
import { MasterpassService } from "./payment/services/masterpass.service";
import { FbService } from "./services/events/fb.service";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { Device, DeviceInfo } from "@capacitor/device";
import { Keyboard, KeyboardInfo, KeyboardResize } from "@capacitor/keyboard";
import { Browser } from "@capacitor/browser";

import { TranslocoService } from "@ngneat/transloco";
import { ExternalLinks } from "./services/dto/orwi-store";
import { StoreService } from "./services/store/store.service";
import { FeedService } from "./services/home/feed.service";
import { App } from "@capacitor/app";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  links: ExternalLinks[] = [];
  theme = "auto";
  menus = [];
  download = false;
  isIstanbulSenin = localStorage.getItem("isIstanbulApp") ?? false;
  langs: string[] = ['tr', 'en']
  appVersion;
  constructor(
    private uiStore: UIStore,
    private os: OrwiService,
    private router: Router,
    private platform: Platform,
    //private splashScreen: SplashScreen,
    private storeService: StoreService,
    public us: UserService,
    private nav: NavController,
    private glb: GlobalService,
    private push: PushService,
    private ms: MasterpassService,
    private locationStrategy: LocationStrategy,
    private fb: FbService,
    private menu: MenuController,
    public translocoService: TranslocoService,
    private acRoute: ActivatedRoute,
    private feedService: FeedService
  ) {




    translocoService.langChanges$.subscribe((res) => {
      this.feedService.getCatagories();
    });

    this.translocoService.setActiveLang(localStorage.getItem('activeLang') || 'tr')

    this.acRoute.queryParams.subscribe((queries) => {
      localStorage.setItem(
        "url_Get",
        JSON.stringify(queries) + " " + location.href + navigator.userAgent
      );
      console.log(queries);
      if (!queries || Object.keys(queries).length == 0) return;

      const queryParam = queries?.app;
      const codeParam = queries?.code;
      if (!queryParam || !codeParam) return;

      if (codeParam) {
        this.us.getIstanbulSeninUserData(codeParam);
        console.log(queries);
      }

      if (
        queryParam.toLocaleLowerCase() == "istanbulsenin" ||
        !environment.production
      ) {
        localStorage.setItem("isIstanbulApp", "true");
      }
    });

    this.menu.swipeGesture(false, "first");
    this.appVersion = environment.appVersion;
    let download = false;
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        console.log("event", event);
        if (event.url.includes("istanbulsenin")) {
          this.us.signInWithIstanbulSenin();
          localStorage.setItem("isIstanbulApp", "true");
          this.isIstanbulSenin = true;
          return;
        }
        if (!router.navigated) {
          if (event.url.indexOf("check-in") > -1) {
            let ps = event.url.lastIndexOf("/");
            let code = event.url.substr(ps + 1, event.url.length);
            this.glb.startupQrCode = code;
          }
          if (event.url.indexOf("/d/") > -1) {
            this.download = true;
            setTimeout(() => {
              this.nav.navigateForward(["redirect", { url: event.url }]);
            }, 1000);
          }
        }
      }
    });
    this.initializeApp();
  }

  startNum = 0;
  openAppDev() {
    this.startNum++;
    console.log(this.startNum);
    if (this.startNum > 10) {
      // alert(JSON.stringify(localStorage));
    }
  }

  langChange(e) {
    console.log(e);
    localStorage.setItem("activeLang", e.detail.value);
    this.translocoService.setActiveLang(e.detail.value);
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.fb.init();

      this.getExternalLinks();

      //Initialize Dark Mode
      try {
        let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
        systemDark.addEventListener("change", (d) => {
          //this.colorTest(d.matches)
          //this.glb.darkTheme = d.matches
        });
      } catch (error) { }

      setTimeout(() => {
        this.theme = localStorage.getItem("theme") || "auto";
        //this.setTheme()
      }, 1000);
      this.us.autoLogin()
      let token = await this.push.initPush()
      this.glb.PushToken = token
      this.glb.DeviceInfo = await Device.getInfo();
      this.glb.DeviceInfo.uuid = await (await Device.getId()).identifier;
      App.getInfo().then(res => {
        this.glb.DeviceInfo.appVersion = res.version
        this.glb.DeviceInfo.appBuild = res.build
        this.glb.DeviceInfo.appName = res.name
      })

      if (this.platform.is("capacitor")) {
        Keyboard.setAccessoryBarVisible({ isVisible: true });
        Keyboard.setResizeMode({ mode: KeyboardResize.Native });
      }

      //this.splashScreen.hide();
      console.log(window.location.href);
      // if ((!this.us.user && !this.download ) || !window.location.href.includes("ibb")) {
      //   this.nav.navigateRoot("welcome");
      // }
    });
  }

  logout() {
    this.us.logout();
  }

  async getExternalLinks() {
    this.os
      .serviceRequestPromise("/api/helper/getAppLinks", {})
      .then((l: any) => {
        console.log(l);
        this.links = l.response;
      });
  }


  onClick(event) {
    document.body.classList.toggle("dark", event.detail.checked);
  }

  colorTest(systemInitiatedDark) {
    // this.glb.consolelog(systemInitiatedDark)
    document.body.classList.toggle("dark", systemInitiatedDark);
    this.glb.darkTheme = systemInitiatedDark;
  }

  themeChanged(e) {
    this.theme = e.detail.value;
    this.setTheme();
  }



  setTheme() {
    if (this.theme == "auto") {
      let systemDark = window.matchMedia("(prefers-color-scheme: dark)");
      if (systemDark.matches) {
        document.body.classList.add("dark");
        this.glb.darkTheme = true;
      } else {
        document.body.classList.remove("dark");
        this.glb.darkTheme = false;
      }
    } else if (this.theme == "dark") {
      document.body.classList.add("dark");
      this.glb.darkTheme = true;
    } else {
      document.body.classList.remove("dark");
      this.glb.darkTheme = false;
    }
    localStorage.setItem("theme", this.theme);
  }

  navigateToBeltur() {
    this.router.navigate(['about-beltur'])
  }

  menulink(item) {
    this.glb.orwiContent = item;
    this.nav.navigateForward("browser");
  }

  login() {
    this.us.afterLoginFunction = undefined;
    this.nav.navigateForward("otp-request");
  }

  writeUs() {
    this.uiStore.update({ orwiContactVisible: true });
  }

  goUrl(id) {
    Browser.open({
      url: this.links.find((e) => e.id == id).url,
    });
  }

  preventBackButton() {
    this.platform.backButton.subscribeWithPriority(9999, () => {
      document.addEventListener(
        "backbutton",
        function (event) {
          event.preventDefault();
          event.stopPropagation();
        },
        false
      );
    });
  }

  demo() {
    if (environment.appVersion.indexOf("dev")) {
      //this.nav.navigateForward("payment-result")
    }

    // var options = {
    //   message: 'share this', // not supported on some apps (Facebook, Instagram)
    //   subject: 'the subject', // fi. for email
    //   files: ['https://via.placeholder.com/400x250'], // an array of filenames either locally or remotely
    //   url: '',
    //   chooserTitle: 'Pick an app', // Android only, you can override the default share sheet title
    //   //appPackageName: 'com.apple.social.facebook', // Android only, you can provide id of the App you want to share with
    //   iPadCoordinates: '0,0,0,0' //IOS only iPadCoordinates for where the popover should be point.  Format with x,y,width,height
    // };
    // this.socialSharing.shareWithOptions(options)
  }

  async takePicture() {
    const image = await Camera.getPhoto({
      quality: 70,
      allowEditing: true,
      resultType: CameraResultType.Base64,
      presentationStyle: "popover",
      width: 600,
      height: 600,
      promptLabelHeader: this.translocoService.translate(
        "Camera Prompt Label Header"
      ),
      promptLabelPicture: this.translocoService.translate(
        "Camera Prompt Label Picture"
      ),
      promptLabelPhoto: this.translocoService.translate(
        "Camera Prompt Label Photo"
      ),
      source: CameraSource.Prompt,
    });

    this.glb.showLoading("");
    var imageUrl = image.base64String;
    let img: any = document.getElementById("imgElement");
    this.us.user.image = "data:image/png;base64," + imageUrl;
    await this.us.saveUser();
    img.src = this.us.user.image;
    this.glb.closeLoading();
  }
}
