import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-slide2',
  templateUrl: './slide2.page.html',
  styleUrls: ['./slide2.page.scss'],
})
export class Slide2Page implements OnInit {

  constructor(private nav: NavController) { }

  ngOnInit() {
  }

  skip() {
    this.nav.navigateForward("slide-3")
  }

  skipAll() {
    this.nav.navigateRoot("home")
  }
}
