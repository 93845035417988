// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.item-cover {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin-left: 15px;
}

.item {
  min-width: 50px;
  height: 40px;
  border-radius: 4px;
  background-color: #EAEAEB;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  margin: 3px;
  padding-left: 4px;
  padding-right: 4px;
}

.item-selected {
  background-color: #B70014;
  color: white;
}

.item-title {
  margin-left: 15px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/home/filters/filters.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,eAAA;EACA,mBAAA;EACA,2BAAA;EACA,iBAAA;AACJ;;AAEA;EACI,eAAA;EACA,YAAA;EACA,kBAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,eAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;EACA,YAAA;AACJ;;AAEA;EACI,iBAAA;EACA,mBAAA;EACA,iBAAA;EACA,eAAA;EACA,gBAAA;AACJ","sourcesContent":[".item-cover {\n    display: flex;\n    flex-wrap: wrap;\n    align-items: center;\n    justify-content: flex-start;\n    margin-left: 15px;\n}\n\n.item {\n    min-width: 50px;\n    height: 40px;\n    border-radius: 4px;\n    background-color: #EAEAEB;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    font-size: 12px;\n    margin: 3px;\n    padding-left: 4px;\n    padding-right: 4px;\n}\n\n.item-selected {\n    background-color:#B70014 ;\n    color: white\n}\n\n.item-title {\n    margin-left: 15px;\n    margin-bottom: 10px;\n    font-weight: bold;\n    font-size: 18px;\n    margin-top: 10px;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
