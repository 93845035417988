import { Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { NavigationBehaviorOptions, Router } from '@angular/router';
import { DomController, IonContent, NavController } from '@ionic/angular';
import { NavigationOptions } from '@ionic/angular/providers/nav-controller';
import { UserService } from 'src/app/services/user/user.service';
import { UIStore } from 'src/app/state/ui/ui.store';

@Component({
  selector: 'home-shrink-header',
  templateUrl: './shrink-header.component.html',
  styleUrls: ['./shrink-header.component.scss'],
})
export class ShrinkHeaderComponent implements OnInit {

  @Input() myContent: IonContent

  oldHeaderHeight = 0
  newHeaderHeight
  showSlider = false

  constructor(
    private uiStore: UIStore,
    private router: Router,
    private elementref: ElementRef,
    public us: UserService,
    private nav: NavController,
    private dom: DomController,
    private renderer: Renderer2) { }

  ngOnInit() {
    this.initHeader()
  }


  initHeader() {
  
    this.elementref.nativeElement.style.setProperty('--orwi-top', "400px")
   
    var style = getComputedStyle(document.body);
    this.myContent.scrollEvents = true
    let safeAreaStr = style.getPropertyValue('--ion-safe-area-top')
    safeAreaStr = safeAreaStr.replace("px", "")
    let safe = parseInt(safeAreaStr)

    let orwiUser = document.getElementById("orwi-user")
    let orwiHeader = document.getElementById("orwihead")

    let orwiHeaderMarginTop = safe + 5

    this.newHeaderHeight = 300 + safe

    this.renderer.setStyle(orwiUser, "margin-top", orwiHeaderMarginTop + "px")

    let wcl = document.getElementById("wcl")
    this.renderer.setStyle(wcl, "height", "200px")

    this.renderer.setStyle(orwiHeader, "height", this.newHeaderHeight + 'px')


    if (this.showSlider) {
      document.documentElement.style.setProperty('--orwi-top', (300 + safe) + "px");
    } else {
      document.documentElement.style.setProperty('--orwi-top', (100 + safe) + "px");
    }
    
   


    if (!this.showSlider) {
      this.closeSlider()
    }


    this.myContent.ionScroll.subscribe(o => {



      //this.newHeaderHeight = 300 - o.detail.scrollTop;

      if (o.detail.scrollTop > 60) {
        this.newHeaderHeight = 100 + safe;

      } else {
        this.newHeaderHeight = 300 + safe;

      }

      if (this.oldHeaderHeight !== this.newHeaderHeight) {

        window.requestAnimationFrame(() => {

          this.dom.write(() => {

            if (this.newHeaderHeight == (100 + safe)) {
              this.renderer.setStyle(wcl, "height", "0px")
              this.renderer.setStyle(orwiHeader, "border-radius", "00px 00px 30px 30px")

            } else {
              this.renderer.setStyle(wcl, "height", "200px")
              this.renderer.setStyle(orwiHeader, "border-radius", "00px 00px 100px 100px")
            }

            this.renderer.setStyle(orwiHeader, "height", this.newHeaderHeight + 'px')
          })

        })

        this.oldHeaderHeight = this.newHeaderHeight
      }

    })



  }

  login() {
    this.nav.navigateForward("/otp-request")
  }

  closeSlider() {
    this.showSlider = false


    this.myContent.scrollEvents = false
    var style = getComputedStyle(document.body);
    let safeAreaStr = style.getPropertyValue('--ion-safe-area-top')
    safeAreaStr = safeAreaStr.replace("px", "")
    let safe = parseInt(safeAreaStr)
    document.getElementById("x-slider").style.display = "none"

    let orwiHeader = document.getElementById("orwihead")

    let orwiHeaderMarginTop = safe + 25

    orwiHeader.style.height = (100 + safe) + "px"
    this.renderer.setStyle(orwiHeader, "border-radius", "00px 00px 30px 30px")

    let c1 = document.getElementById("main-content")
    let c2 = document.getElementById("denied-content")
    let c3 = document.getElementById("prompt-content")
    if (c1) {
      c1.style.marginTop = (110 + safe) + "px"
    }

    if (c2) {
      c2.style.marginTop = (110 + safe) + "px"
    }

    if (c3) {
      c3.style.marginTop = (110 + safe) + "px"
    }

  }


  myFolio() {
    this.us.checkActiveUser( (e) => {
      this.nav.navigateForward("basket-details", {replaceUrl: e})
    })

  }

  myGifts() {
    //this.nav.navigateForward("otp-verify")

   this.us.checkActiveUser( (e) => {
      this.nav.navigateForward("my-gifts", { replaceUrl: e })
    }) 
  }


  notification() {
    this.nav.navigateForward("notifications")
  }

  chat() {
    this.uiStore.update({orwiContactVisible: true})
  }

}
