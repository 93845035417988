import { Component, OnInit } from '@angular/core';
import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { Platform, NavController, GestureController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { QrScannerEventsService } from 'src/app/services/events/qr-scanner-events.service';
import { GlobalService } from 'src/app/services/global.service';
import { StoreService } from 'src/app/services/store/store.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'web-fab',
  templateUrl: './web-fab.component.html',
  styleUrls: ['./web-fab.component.scss'],
})
export class WebFabComponent implements OnInit {
  firstClick = true


  qrEventSubscription: Subscription
  faQrcode = faQrcode
  backDropEnabled = false
  toggle = false

  visible = false
  constructor(
    private glb: GlobalService,
    private qe: QrScannerEventsService,
    private plt: Platform,
    private us: UserService,
    private ss: StoreService,
    private gestureCtrl: GestureController,
    private nav: NavController) {


  }

  ngOnInit() {

    this.visible = (!this.plt.is("capacitor"))


  }

  ngAfterViewInit() {

    const gesture = this.gestureCtrl.create({

      el: document.getElementById("web-fab"),
      gestureName: 'my-gesture',

      onEnd: (detail) => {
        console.log(detail)
        if (detail.velocityX < 0) {
          this.hideButton()
        }

        if (detail.velocityY < 0) {
          this.hideButton()
        }


      }
    }, true)

    gesture.enable();
  }


  close() {
    this.buttonToggle()
  }



  hideButton() {

    let el = document.getElementById("web-fab")
    if (this.firstClick) {
      el.style.left = "-250px"
      //el.classList.add("animate__pulse")
    } else {
      this.firstClick = true
      el.style.left = "-110px"
      el.classList.add("animate__pulse")
    }

  }



  buttonToggle() {

    if (!this.firstClick) {
      console.log("downoad")
      this.redirectAppStore()
    }

    this.firstClick = false
    let el = document.getElementById("web-fab")
    el.style.left = "20px"
    el.classList.remove("animate__pulse")
    //this.toggle = this.toggle ? false : true
    //this.backDropEnabled = this.toggle
    //this.translate()

    setTimeout(() => {
      this.firstClick = true
      el.style.left = "-110px"
      el.classList.add("animate__pulse")
    }, 10000);
  }

  translate() {

    let texts = document.getElementsByClassName("web-text")

    if (this.toggle) {

      setTimeout(() => {
        texts[0].classList.add('web-text-open')
        texts[1].classList.add('web-text-open')
        texts[2].classList.add('web-text-open')
      }, 600);


    } else {
      texts[0].classList.remove('web-text-open')
      texts[1].classList.remove('web-text-open')
      texts[2].classList.remove('web-text-open')
    }


    let el = document.getElementById('web-gray1')
    if (this.toggle) {
      el.classList.add("web-tans1")
    } else {
      el.classList.remove("web-tans1")
    }



    setTimeout(() => {
      let el1 = document.getElementById('web-gray2')
      if (this.toggle) {
        el1.classList.add("web-trans2")
      } else {
        el1.classList.remove("web-trans2")
      }
    }, 200);


    setTimeout(() => {
      let el3 = document.getElementById('web-gray3')
      if (this.toggle) {
        el3.classList.add("web-trans3")
      } else {
        el3.classList.remove("web-trans3")
      }
    }, 400);


  }


  scanQr() {
    this.buttonToggle()
    setTimeout(() => {

      if (this.plt.is("capacitor")) {
        this.nav.navigateForward("scan-qr")
      } else {
        this.nav.navigateForward("scan-qr-web")
      }
    }, 600);

    if (this.qrEventSubscription) this.qrEventSubscription.unsubscribe
    this.qrEventSubscription = this.qe.qrScannerEvent.subscribe(o => {
      this.glb.consolelog("qrScannerEvent", "qr-button")
      if (o.status == "success") {
        this.ss.storeCheckIn(o.qrcode)
        this.qrEventSubscription.unsubscribe()
      } else {
        this.qrEventSubscription.unsubscribe()
      }
    })

  }

  showQr() {

    this.buttonToggle()
    this.us.checkActiveUser(() => { this.nav.navigateForward("my-code") })
  }

  haveCoupon() {

    this.buttonToggle()
    this.us.checkActiveUser(() => { this.nav.navigateForward("my-gifts") })

  }



  redirectAppStore() {


    if (this.plt.is("android")) {
      //this.store = "Google Play"
      //this.us.savelog("Google Play'e yönlendir ->" + this.ss.store.name)
      //this.glb.setEvent("redirect_android", { storeId: this.ss.store.id, storeName: this.ss.store.name })
      //this.glb.setEvent("web_app_checkin", { storeId: this.ss.store.id, storeName: this.ss.store.name, action: "native-app-redirect-android" })
      document.location.href = "https://play.google.com/store/apps/details?id=app.orwi.orwi"
    } else if (this.plt.is("ios")) {
      //this.store = "App Store"
      //this.us.savelog("App Store'a yönlendir." + this.ss.store.name)
      //this.glb.setEvent("redirect_ios", { storeId: this.ss.store.id, storeName: this.ss.store.name })
      //this.glb.setEvent("web_app_checkin", { storeId: this.ss.store.id, storeName: this.ss.store.name, action: "native-app-redirect-ios" })
      document.location.href = "https://apps.apple.com/us/app/orwi/id1505302046?ls=1"
    } else {

      this.us.savelog("Belirsiz Yönlendirme..")
      // this.glb.setEvent("web_app_checkin", { storeId: this.ss.store.id, storeName: this.ss.store.name, action: "native-app-redirect-?" })
    }



  }

}
