// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cover {
  margin: 10px;
  height: 130px;
  background-color: #1f2636;
  padding: 10px;
  color: white;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
}

#icon-cover {
  position: absolute;
  bottom: 38px;
  right: 29px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

#cofee-bar-title {
  font-family: "Inter";
  font-size: 13px;
  font-weight: bold;
  margin-top: 5px;
}

#cofee-bar-message {
  font-family: "Inter";
  font-size: 11px;
  margin-top: 7px;
}

#cofee-bar-gift {
  font-family: "Inter";
  font-size: 11px;
  margin-top: 10px;
  font-weight: 600;
  color: #ff9a00;
}

#gift-badge {
  position: absolute;
  top: 0px;
  left: -6px;
  background-color: #d4841d;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-family: "Inter";
}`, "",{"version":3,"sources":["webpack://./src/app/modules/beltur-intro/components/beltur-coffee/beltur-coffee.component.scss"],"names":[],"mappings":"AAAA;EAEE,YAAA;EACA,aAAA;EACA,yBAAA;EACA,aAAA;EACA,YAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;AAAF;;AAGA;EACE,kBAAA;EACA,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;EACA,eAAA;EACA,sBAAA;EACA,6BAAA;EACA,mBAAA;AAAF;;AAGA;EACE,oBAAA;EACA,eAAA;EACA,iBAAA;EACA,eAAA;AAAF;;AAGA;EACE,oBAAA;EACA,eAAA;EACA,eAAA;AAAF;;AAGA;EACE,oBAAA;EACA,eAAA;EACA,gBAAA;EACA,gBAAA;EACA,cAAA;AAAF;;AAGA;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,oBAAA;AAAF","sourcesContent":[".cover {\n  //filter: drop-shadow(2px 4px 6px black);\n  margin: 10px;\n  height: 130px;\n  background-color: #1f2636;\n  padding: 10px;\n  color: white;\n  border-radius: 10px;\n  display: flex;\n  justify-content: space-between;\n}\n\n#icon-cover {\n  position: absolute;\n  bottom: 38px;\n  right: 29px;\n  display: flex;\n  flex-direction: column;\n  font-size: 12px;\n  flex-direction: column;\n  justify-content: space-evenly;\n  align-items: center;\n}\n\n#cofee-bar-title {\n  font-family: \"Inter\";\n  font-size: 13px;\n  font-weight: bold;\n  margin-top: 5px;\n}\n\n#cofee-bar-message {\n  font-family: \"Inter\";\n  font-size: 11px;\n  margin-top: 7px;\n}\n\n#cofee-bar-gift {\n  font-family: \"Inter\";\n  font-size: 11px;\n  margin-top: 10px;\n  font-weight: 600;\n  color: #ff9a00;\n}\n\n#gift-badge {\n  position: absolute;\n  top: 0px;\n  left: -6px;\n  background-color: #d4841d;\n  width: 15px;\n  height: 15px;\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  font-weight: bold;\n  font-family: \"Inter\";\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
