import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { StoreService } from '../services/store/store.service';
import { MenuState, MenuStore } from './menu.store';

@Injectable({ providedIn: 'root' })
export class MenuQuery extends Query<MenuState> {


    infiniteProducts$ = this.select("infiniteProducts")
    products$ = this.select('products')
    groups$ = this.select('groups')
    subGroups$ = this.select("subGroups")
    selectedGroup$ = this.select("selectedGroup")
    selectedSubGroup = this.select("selectedSubGroup")
    giftSelected$ = this.select("giftSelected")
    searchedProducts$ = this.select("searchedProducts")
    searchKeyword$ = this.select("searchKeyword")
    allGroups$ = this.select('allGroups')
    intersectingGroup$ = this.select("intersectingGroup")
    productGroupImage$ = this.select('productGroupImages')



    constructor(protected store: MenuStore, private ss: StoreService) {
        super(store);
    }

 
    ps(p) {
        return this.store.getValue().infiniteProducts.filter(o => o.group == p)
    }

    sg(p) {
        return this.store.getValue().allGroups.filter(o => o.parentID == p)
    }
     
    

}

