import { Component, Input, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { IdGeneratorService } from 'src/app/services/helpers/id-generator.service';
import { FeedService } from 'src/app/services/home/feed.service';
import { AppService } from 'src/app/services/utils/app.service';

@Component({
  selector: 'orwi-modal',
  templateUrl: './orwi-modal.component.html',
  styleUrls: ['./orwi-modal.component.scss'],
})
export class OrwiModalComponent implements OnInit {

  @Input() height = "auto"
  @Input() backDropEnabled = false
  @Input() closeOnBackDropTap = false
  @Input() resizeable = false
  @Input() position: 'top' | 'bottom' = "top"
  @Input() initalState: 'open' | 'close' = "open"
  @Input() hideKeyboardShow = false


  kbShow: Subscription
  kbHide: Subscription
  actualHeight = "auto"

  resizeEdges = { bottom: false, right: false, top: false, left: false }
  componentId

  constructor(
    private plt: Platform,
    private ig: IdGeneratorService, private as: AppService, private fs: FeedService) {

    this.componentId = ig.generate()

  }



  ngOnInit() {



  }


  ngAfterViewInit() {

    this.initKeyboardEvens()

    let el = document.getElementById("orwi-action-" + this.componentId)


    if (this.resizeable) this.initResizer()



    if (this.initalState == "open") {
      if (this.position == "top") {

        el.classList.add("top")
        el.classList.add("animate__slideInDown")
        if (this.resizeable) this.resizeEdges = { bottom: true, right: false, top: false, left: false }


      } else {

        el.classList.add("bottom")
        el.classList.add("animate__slideInUp")
        if (this.resizeable) this.resizeEdges = { bottom: false, right: false, top: true, left: false }

      }


      el.style.height = this.height
      setTimeout(() => {
        el.classList.remove("animate__slideInUp")
        el.classList.remove("animate__slideInDown")
      }, 1000);


    } else {
      el.style.height = "0px"
    }



  }

  initKeyboardEvens() {

    if (!this.plt.is("capacitor")) return

    let el = document.getElementById("orwi-action-" + this.componentId)

    if (this.hideKeyboardShow) {


      if (this.kbHide) {
        this.kbHide.unsubscribe()
        this.kbShow.unsubscribe()

      }

      this.kbHide = this.as.keyboardHide.subscribe(o => {
        el.style.display = "flex"


      })

      this.kbShow = this.as.keyboardShow.subscribe(o => {
        el.style.display = "none"
      })

    }
  }


  show() {
    let el = document.getElementById("orwi-action-" + this.componentId)



    el.classList.remove("animate__slideOutDown")
    el.classList.remove("animate__slideOutUp")

    if (this.position == "top") {

      el.classList.add("top")
      el.classList.add("animate__slideInDown")
      if (this.resizeable) this.resizeEdges = { bottom: true, right: false, top: false, left: false }


    } else {

      el.classList.add("bottom")
      el.classList.add("animate__slideInUp")
      if (this.resizeable) this.resizeEdges = { bottom: false, right: false, top: true, left: false }

    }



    el.style.height = this.height
    setTimeout(() => {
      el.classList.remove("animate__slideInUp")
      el.classList.remove("animate__slideInDown")
    }, 1000);

  }



  onClose() {

    let ps = document.getElementById("orwi-action-" + this.componentId)
    ps.classList.remove("animate__slideInUp")
  }

  initResizer() {
    let closer = document.getElementById("resizer-" + this.componentId)
    if (this.position == "top") {
      closer.style.top = "unset"; closer.style.bottom = "8px"
    } else {
      closer.style.bottom = "unset"; closer.style.top = "8px"
    }
  }

  /*   xclose() {
      if (this.closeOnBackDropTap) {
        this.backDropEnabled = false
        let ps = document.getElementById("orwi-action-" + this.componentId)
        ps.classList.remove("animate__slideInUp")
        ps.classList.add("animate__slideOutDown")
  
      }
    } */

  close() {
    return new Promise((resolve, reject) => {
      let comp = document.getElementById('orwi-action-' + this.componentId)
      if (this.position == "bottom") {
        comp.classList.add("animate__slideOutDown")
      } else {
        comp.classList.add("animate__slideOutUp")
      }
      setTimeout(() => {
        this.fs.filterModalStatus = 'close'
        resolve(true)
      }, 500);
    })

  }

  onResizeStart(e) {
    this.actualHeight = e.rectangle.height
  }

  onResizeEnd(e) {

    let el = document.getElementById("orwi-action-" + this.componentId)
    if (e.rectangle.height > this.actualHeight) {
      el.style.height = "90%"
    } else {
      el.style.height = this.height
    }

  }

}
