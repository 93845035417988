import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FbService } from 'src/app/services/events/fb.service';
import { FeedService, modeValues } from 'src/app/services/home/feed.service';

@Component({
  selector: 'home-mode-select',
  templateUrl: './mode-select.component.html',
  styleUrls: ['./mode-select.component.scss'],
})
export class ModeSelectComponent implements OnInit {

  modeValue: modeValues = "avrupa"

  @Output() modeChanged = new EventEmitter<modeValues>()
  constructor(private feedService : FeedService, private fb: FbService) { }

  ngOnInit() {
    this.feedService.activeMode = this.modeValue
    
  }


  modChanged(e) {
    this.fb.up_tab(e.detail.value)
    this.feedService.activeMode = e.detail.value
    this.modeChanged.emit(e.detail.value)
  }

}
