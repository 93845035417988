
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';



import { registerLocaleData } from '@angular/common';

import localeTr from '@angular/common/locales/tr';
import localeDe from '@angular/common/locales/de';

import { SharedModule } from '../helpers/shared.module';
import { OrwiModalComponent } from './ui/orwi-modal/orwi-modal.component';

import { ResizableDirective, ResizableModule } from 'angular-resizable-element';
import { GoogleMapComponent } from './location/google-map/google-map.component';
import { GoogleMapModalComponent } from './location/google-map-modal/google-map-modal.component';
import { GoogleMapSelectAddressComponent } from './location/google-map-select-address/google-map-select-address.component';
import { OrwiPromptComponent } from './ui/orwi-prompt/orwi-prompt.component';
import { OrwiScratchComponent } from './gamification/orwi-scratch/orwi-scratch.component';
import { ModeSelectComponent } from './home/mode-select/mode-select.component';
import { LocationChangerComponent } from './location/location-changer/location-changer.component';
import { PlaceSearchComponent } from './home/place-search/place-search.component';
import { FeedCardComponent } from './home/feed-card/feed-card.component';
import { FeedCategoriesComponent } from './home/feed-categories/feed-categories.component';
import { QrButtonComponent } from './home/qr-button/qr-button.component';
import { PhoneMaskDirective } from '../helpers/directives/phone-mask.directive';
import { ShrinkHeaderComponent } from './home/shrink-header/shrink-header.component';
import { ShrinkHeaderSliderComponent } from './home/shrink-header-slider/shrink-header-slider.component';
import { CloserComponent } from './ui/closer/closer.component';
import { HeaderComponent } from './home/header/header.component';
import { GroupSelectorComponent } from './menu/group-selector/group-selector.component';
import { ProductGalleryComponent } from './menu/product-gallery/product-gallery.component';
import { HighligthDirective } from '../helpers/directives/highlight.directive';
import { OrwiCurrencyPipe } from '../helpers/pipes/orwi-currency.pipe';
import { AddressFormComponent } from './delivery/address-form/address-form.component';
import { ParallaxHeaderDirective } from '../helpers/directives/parallax-header.directive';
import { FolioRowsComponent } from './menu/folio-rows/folio-rows.component';
import { NameFormComponent } from './login/name-form/name-form.component';
import { CouponFieldComponent } from './gamification/coupon-field/coupon-field.component';
import { StoreTitleComponent } from './home/store-title/store-title.component';
import { StoreStatusComponent } from './home/store-status/store-status.component';
import { OrwiPointFieldComponent } from './gamification/orwi-point-field/orwi-point-field.component';
import { SuperOrwiPointFieldComponent } from './gamification/super-orwi-point-field/super-orwi-point-field.component';
import { MasterpassPurchaseFormComponent } from '../payment/components/masterpass-purchase-form/masterpass-purchase-form.component';
import { OtpValidationComponent } from '../payment/components/otp-validation/otp-validation.component';
import { MasterpassDeleteCardFormComponent } from '../payment/components/masterpass-delete-card-form/masterpass-delete-card-form.component';
import { MasterpassLinkCardFormComponent } from '../payment/components/masterpass-link-card-form/masterpass-link-card-form.component';
import { LocalizedDatePipe } from '../helpers/pipes/localized-date.pipe';
import { AttachFolioComponent } from './menu/attach-folio/attach-folio.component';
import { SlideHeaderComponent } from './ui/slide-header/slide-header.component';
import { OrwiBackComponent } from './ui/orwi-back/orwi-back.component';
import { OrwiContactComponent } from './home/orwi-contact/orwi-contact.component';
import { OrwiContactButtonComponent } from './ui/orwi-contact-button/orwi-contact-button.component';
import { StoreOpenComponent } from './home/store-open/store-open.component';
import { StoreBonusComponent } from './home/store-bonus/store-bonus.component';
import { StoreRangeComponent } from './home/store-range/store-range.component';
import { RatingComponent } from './store/rating/rating.component';
import { WebFabComponent } from './ui/web-fab/web-fab.component';
import { FiltersComponent } from './home/filters/filters.component';
import { ProductGalleryInfiniteComponent } from './menu/product-gallery-infinite/product-gallery-infinite.component';
import { BrowserModule } from '@angular/platform-browser';
import { WhatsappButtonComponent } from './ui/whatsapp-button/whatsapp-button.component';


registerLocaleData(localeTr)
registerLocaleData(localeDe)

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        ResizableModule,
        IonicModule.forRoot({
            backButtonText: "Geri"
        }),
    ],
    declarations: [
        WebFabComponent,
        OrwiModalComponent,
        OrwiPromptComponent,
        //Google Maps / Address Components
        GoogleMapComponent,
        GoogleMapModalComponent,
        GoogleMapSelectAddressComponent,
        AddressFormComponent,
        //Gamification
        OrwiScratchComponent,
        CouponFieldComponent,
        OrwiPointFieldComponent,
        SuperOrwiPointFieldComponent,
        //Home Page Components
        ModeSelectComponent,
        LocationChangerComponent,
        PlaceSearchComponent,
        FeedCardComponent,
        FeedCategoriesComponent,
        QrButtonComponent,
        ShrinkHeaderComponent,
        ShrinkHeaderSliderComponent,
        HeaderComponent,
        StoreTitleComponent,
        StoreStatusComponent,
        StoreOpenComponent,
        StoreBonusComponent,
        StoreRangeComponent,
        RatingComponent,
        FiltersComponent,
        //Directives
        PhoneMaskDirective,
        HighligthDirective,
        ParallaxHeaderDirective,
        //PIPES
        OrwiCurrencyPipe,
        LocalizedDatePipe,
        //UI
        CloserComponent,
        SlideHeaderComponent,
        OrwiBackComponent,
        OrwiContactComponent,
        OrwiContactButtonComponent,
        WhatsappButtonComponent,
        //MENU
        GroupSelectorComponent,
        ProductGalleryComponent,
        FolioRowsComponent,
        AttachFolioComponent,
        ProductGalleryInfiniteComponent,
        //LOGIN
        NameFormComponent,
        //MASTERPASS
        MasterpassPurchaseFormComponent,
        MasterpassDeleteCardFormComponent,
        MasterpassLinkCardFormComponent,
        OtpValidationComponent,
    ],
    exports: [
        WebFabComponent,
        OrwiModalComponent,
        OrwiPromptComponent,
        //Google Maps / Address Components
        GoogleMapComponent,
        GoogleMapModalComponent,
        GoogleMapSelectAddressComponent,
        AddressFormComponent,
        //Gamification
        OrwiScratchComponent,
        CouponFieldComponent,
        OrwiPointFieldComponent,
        SuperOrwiPointFieldComponent,
        //Home Page Components
        OrwiScratchComponent,
        ModeSelectComponent,
        LocationChangerComponent,
        PlaceSearchComponent,
        FeedCardComponent,
        FeedCategoriesComponent,
        QrButtonComponent,
        ShrinkHeaderComponent,
        ShrinkHeaderSliderComponent,
        HeaderComponent,
        StoreTitleComponent,
        StoreStatusComponent,
        StoreOpenComponent,
        StoreOpenComponent,
        StoreRangeComponent,
        RatingComponent,
        FiltersComponent,
        //Directives
        PhoneMaskDirective,
        HighligthDirective,
        ParallaxHeaderDirective,
        //PIPES
        OrwiCurrencyPipe,
        LocalizedDatePipe,
        //UI
        CloserComponent,
        SlideHeaderComponent,
        OrwiBackComponent,
        OrwiContactComponent,
        OrwiContactButtonComponent,
        WhatsappButtonComponent,
        //MENU
        GroupSelectorComponent,
        ProductGalleryComponent,
        FolioRowsComponent,
        AttachFolioComponent,
        ProductGalleryInfiniteComponent,
        //LOGIN
        NameFormComponent,
        //MASTERPASS
        MasterpassPurchaseFormComponent,
        MasterpassDeleteCardFormComponent,
        MasterpassLinkCardFormComponent,
        OtpValidationComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }